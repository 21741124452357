<template>
	<div class="stardand">
		<div class="mainSender">
			<div class="container">

        <div class="senderMain">
          <div class="title">{{$t('tokenPublic.token100')}}</div>
          <!-- stardand  1  holdReflection  2   LPwithInviter  3  LPBurn  4   LPMine  5  X314  6 -->
          <div class="introduction">{{$t('tokenPublic.token101')}}</div>
          <el-form label-width ="auto">
            <el-form-item :label="$t('tokenPublic.token11')" required>
              <el-select v-model="chainNum" class="chainNumBox" @change="setChainId">
                <el-option v-for="(item, index) in chainlist" :label="item.chainName"  :key="'chainNum' + index" :value="item.id">
                  <span class="itemChainLogo" style="float: left; color: #8492a6; font-size: 13px; margin-right: 5px;">
                      <img :src="item.chainLogo" style="width: 21px;margin-top: 6px;border-radius: 50%;">
                    </span>
                    <span class="itemChainName" style="float: left;">{{ item.chainName }}</span>
                </el-option>
              </el-select>
              <img class="chainLogo" :src="chainLogo">
            </el-form-item>
          </el-form>
          <template>
            <el-tabs v-model="activeTokenList" >
              <el-tab-pane :label="$t('tokenPublic.token103')" name="first">
                <template>
                  <el-table 
                    v-loading="isLoading"
                    :data="sortAllTokensList"
                    border
                    class="tableMain">
                    <el-table-column label="ID" type="index" width="50"> </el-table-column>
                    <el-table-column prop="coinAddress" :label="$t('tokenPublic.token104')" width="150">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.coinAddress) }} <i class="el-icon-copy-document" @click="copy(scope.row.coinAddress)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('tokenPublic.token105')" width="180"></el-table-column>
                    <el-table-column prop="symbol" :label="$t('tokenPublic.token106')" width="100"></el-table-column>
                    <el-table-column prop="mode" :label="$t('tokenPublic.token107')" width="140"></el-table-column>
                    <el-table-column prop="owner" :label="$t('tokenPublic.token108')" width="150">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.owner) }} <i class="el-icon-copy-document" @click="copy(scope.row.owner)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="createdTime" :label="$t('tokenPublic.token109')"></el-table-column>
                    <el-table-column :label="$t('tokenPublic.token112')" width="90">
                      <template slot-scope="scope">  
                        <el-button size="mini" type="info" round @click="handleView(scope.row)">{{$t('tokenPublic.token110')}}</el-button>  
                      </template> 
                    </el-table-column>
                    <div slot="empty"> <p> {{$t('pubilc.noData')}} </p> </div>
                  </el-table>
                </template>
              </el-tab-pane>
              <el-tab-pane :label="$t('tokenPublic.token111')" name="second">
                <template>
                  <el-table 
                    v-loading="isLoading"
                    :data="sortMyTokensList"
                    border
                    class="tableMain">
                    <el-table-column label="ID" type="index" width="50"> </el-table-column>
                    <el-table-column prop="coinAddress" :label="$t('tokenPublic.token104')" width="150">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.coinAddress) }} <i class="el-icon-copy-document" @click="copy(scope.row.coinAddress)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="name" :label="$t('tokenPublic.token105')" width="180"></el-table-column>
                    <el-table-column prop="symbol" :label="$t('tokenPublic.token106')" width="100"></el-table-column>
                    <el-table-column prop="mode" :label="$t('tokenPublic.token107')" width="140"></el-table-column>
                    <el-table-column prop="owner" :label="$t('tokenPublic.token108')" width="150">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.owner) }} <i class="el-icon-copy-document" @click="copy(scope.row.owner)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="createdTime" :label="$t('tokenPublic.token109')"></el-table-column>
                    <el-table-column :label="$t('tokenPublic.token112')" width="90">
                      <template slot-scope="scope">  
                        <el-button size="mini" type="info" round @click="handleView(scope.row)">{{$t('tokenPublic.token110')}}</el-button>  
                      </template> 
                    </el-table-column>
                    <div slot="empty"> <p> {{$t('pubilc.noData')}} </p> </div>
                  </el-table>
                </template>
              </el-tab-pane>
            </el-tabs>
          </template>

        </div>

			</div>
		</div>
	</div>

</template>

<script>
import { ethers } from 'ethers'
import Navigation from '../../components/Navigation'
import { BigNumber , formatUnits, MAX_INT, parseUnits, decToHex, showAccount} from '../../utils/utils.js'
import factoryABI from '../../abi/token/WhxTokenFactory.json'
import chainlistData from '../../abi/tokenChainList.json'
import Clipboard from "clipboard"

export default {
	name: 'stardand',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '',
				},
			],
		}
	},

	data() {
		return {
      isSelectChains:false,
      chainLogo:'',
      chainName:'',
      //链
      chainNum:JSON.parse(localStorage.getItem('chainNum')) || '0',
      chainlist: JSON.parse(localStorage.getItem('chainlist')) || chainlistData,

      provider:{},
      signer:{},
      account:'',
      isLoading:false,

      allTokensList:[],
      myTokensList:[],

      stardandFactory:'',
      holdReflectionFactory:'',
      LPwithInviterFactory:'',
      LPBurnFactory:'',
      LPMineFactory:'',

      activeTokenList: 'first',
		}
	},

	async created() {
    // console.log(decToHex(97))
	},
  beforeDestroy() {  
    if (window.ethereum) {  
      window.ethereum.off('chainChanged', this.handleChainChanged)
      window.ethereum.off('accountsChanged', this.accountsChanged)
    } 
  },
	computed: {
		title() {
			return this.$t('title.tokenList')
		},
    sortAllTokensList(){
      return this.allTokensList.slice().sort((a, b) => {
        const dateA = new Date(a.createdTime);
        const dateB = new Date(b.createdTime);
        return dateB - dateA;
      });
    },
    sortMyTokensList(){
      return this.myTokensList.slice().sort((a, b) => {
        const dateA = new Date(a.createdTime);
        const dateB = new Date(b.createdTime);
        return dateB - dateA;
      });
    },
	},
  async mounted() {
    if (window.ethereum) {  
      window.ethereum.on('chainChanged', (chainId) => this.handleChainChanged(chainId))
      window.ethereum.on('accountsChanged', (accounts) => this.accountsChanged(accounts))
    }
    await this.initAccount()
    if (this.chainNum === `${this.$t("tokenPublic.token134")}`) {
      this.chainLogo = './img/chainIcon/not.png'
      return
    }
    await this.initData()
  },
	methods: {
    showAccount,
    async handleChainChanged(chainId) {  
      // 当链变化时，更新组件的chainNumber数据
      const chainIdB = Number(BigNumber(chainId).toString())
      const selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
      if (!selectedChain) {
        this.chainNum = `${this.$t("tokenPublic.token134")}`
        this.chainLogo = './img/chainIcon/not.png'
        return;
      }
      this.chainNum = selectedChain.id;
      await this.initAccount()
      await this.initData()
    },
    async accountsChanged() {  
      await this.initAccount()
      await this.initData()
    },
    //初始化数据
    async initData() {
      this.chainLogo = this.chainlist[this.chainNum].chainLogo
      this.tokenArr = this.chainlist[this.chainNum].tokenArr
      this.stardandFactory = this.chainlist[this.chainNum].stardandFactory
      this.holdReflectionFactory = this.chainlist[this.chainNum].holdReflectionFactory
      this.LPwithInviterFactory = this.chainlist[this.chainNum].LPwithInviterFactory
      this.LPBurnFactory = this.chainlist[this.chainNum].LPBurnFactory
      this.LPMineFactory = this.chainlist[this.chainNum].LPMineFactory
      this.X314Factory = this.chainlist[this.chainNum].X314Factory
      this.allTokensList = []
      this.myTokensList = []
      await this.getAllStardand()
      await this.getAllHoldReflection()
      await this.getAllLPwithInviter()
      await this.getAllLPBurn()
      await this.getAllLPMine()
      this.getMyTokenList()
    },
    //获取 stardand 所有代币
    async getAllStardand() {
      const factoryContract = new ethers.Contract(this.stardandFactory, factoryABI, this.provider)
      try {
        let tokensArr  = await factoryContract.getAllTokens()
        for (let i = 0; i < tokensArr.length; i++) {
          let coinAddressToken = tokensArr[i].coinAddress
          let nameToken = tokensArr[i].name
          let symbolToken = tokensArr[i].symbol
          // let modeToken = tokensArr[i].mode
          let ownerToken = tokensArr[i].owner
          let timestamp = Number(BigNumber(tokensArr[i].createdTime).mul('1000'))
          let createdTimeToken = this.formattedTime(timestamp)
          let tokenInfo = {
            'coinAddress': coinAddressToken,
            'name': nameToken,
            'symbol': symbolToken,
            'mode': this.$t('title.stardand'),
            'owner': ownerToken,
            'createdTime': createdTimeToken
          }
          this.allTokensList.push(tokenInfo)
        }
      } catch (error) {
        console.log("tokenInfo"+error.code);
      }
    },
    //获取 holdReflection 所有代币
    async getAllHoldReflection() {
      const factoryContract = new ethers.Contract(this.holdReflectionFactory, factoryABI, this.provider)
      try {
        let tokensArr  = await factoryContract.getAllTokens()
        for (let i = 0; i < tokensArr.length; i++) {
          let coinAddressToken = tokensArr[i].coinAddress
          let nameToken = tokensArr[i].name
          let symbolToken = tokensArr[i].symbol
          // let modeToken = tokensArr[i].mode
          let ownerToken = tokensArr[i].owner
          let timestamp = Number(BigNumber(tokensArr[i].createdTime).mul('1000'))
          let createdTimeToken = this.formattedTime(timestamp)
          let tokenInfo = {
            'coinAddress': coinAddressToken,
            'name': nameToken,
            'symbol': symbolToken,
            'mode': this.$t('title.holdReflection'),
            'owner': ownerToken,
            'createdTime': createdTimeToken
          }
          this.allTokensList.push(tokenInfo)
        }
      } catch (error) {
        console.log("tokenInfo"+error.code);
      }
    },
    //获取 LPwithInviter 所有代币
    async getAllLPwithInviter() {
      const factoryContract = new ethers.Contract(this.LPwithInviterFactory, factoryABI, this.provider)
      try {
        let tokensArr  = await factoryContract.getAllTokens()
        for (let i = 0; i < tokensArr.length; i++) {
          let coinAddressToken = tokensArr[i].coinAddress
          let nameToken = tokensArr[i].name
          let symbolToken = tokensArr[i].symbol
          // let modeToken = tokensArr[i].mode
          let ownerToken = tokensArr[i].owner
          let timestamp = Number(BigNumber(tokensArr[i].createdTime).mul('1000'))
          let createdTimeToken = this.formattedTime(timestamp)
          let tokenInfo = {
            'coinAddress': coinAddressToken,
            'name': nameToken,
            'symbol': symbolToken,
            'mode': this.$t('title.lpWithInviter'),
            'owner': ownerToken,
            'createdTime': createdTimeToken
          }
          this.allTokensList.push(tokenInfo)
        }
      } catch (error) {
        console.log("tokenInfo"+error.code);
      }
    },
    //获取 LPBurn 所有代币
    async getAllLPBurn() {
      const factoryContract = new ethers.Contract(this.LPBurnFactory, factoryABI, this.provider)
      try {
        let tokensArr  = await factoryContract.getAllTokens()
        for (let i = 0; i < tokensArr.length; i++) {
          let coinAddressToken = tokensArr[i].coinAddress
          let nameToken = tokensArr[i].name
          let symbolToken = tokensArr[i].symbol
          // let modeToken = tokensArr[i].mode
          let ownerToken = tokensArr[i].owner
          let timestamp = Number(BigNumber(tokensArr[i].createdTime).mul('1000'))
          let createdTimeToken = this.formattedTime(timestamp)
          let tokenInfo = {
            'coinAddress': coinAddressToken,
            'name': nameToken,
            'symbol': symbolToken,
            'mode': this.$t('title.lpBurn'),
            'owner': ownerToken,
            'createdTime': createdTimeToken
          }
          this.allTokensList.push(tokenInfo)
        }
      } catch (error) {
        console.log("tokenInfo"+error.code);
      }
    },
    //获取 LPMine 所有代币
    async getAllLPMine() {
      const factoryContract = new ethers.Contract(this.LPMineFactory, factoryABI, this.provider)
      try {
        let tokensArr  = await factoryContract.getAllTokens()
        for (let i = 0; i < tokensArr.length; i++) {
          let coinAddressToken = tokensArr[i].coinAddress
          let nameToken = tokensArr[i].name
          let symbolToken = tokensArr[i].symbol
          // let modeToken = tokensArr[i].mode
          let ownerToken = tokensArr[i].owner
          let timestamp = Number(BigNumber(tokensArr[i].createdTime).mul('1000'))
          let createdTimeToken = this.formattedTime(timestamp)
          let tokenInfo = {
            'coinAddress': coinAddressToken,
            'name': nameToken,
            'symbol': symbolToken,
            'mode': this.$t('title.lpMine'),
            'owner': ownerToken,
            'createdTime': createdTimeToken
          }
          this.allTokensList.push(tokenInfo)
        }
      } catch (error) {
        console.log("tokenInfo"+error.code);
      }
    },
    //获取 X314 所有代币
    async getAllLPMine() {
      const factoryContract = new ethers.Contract(this.X314Factory, factoryABI, this.provider)
      try {
        let tokensArr  = await factoryContract.getAllTokens()
        for (let i = 0; i < tokensArr.length; i++) {
          let coinAddressToken = tokensArr[i].coinAddress
          let nameToken = tokensArr[i].name
          let symbolToken = tokensArr[i].symbol
          // let modeToken = tokensArr[i].mode
          let ownerToken = tokensArr[i].owner
          let timestamp = Number(BigNumber(tokensArr[i].createdTime).mul('1000'))
          let createdTimeToken = this.formattedTime(timestamp)
          let tokenInfo = {
            'coinAddress': coinAddressToken,
            'name': nameToken,
            'symbol': symbolToken,
            'mode': this.$t('title.X314'),
            'owner': ownerToken,
            'createdTime': createdTimeToken
          }
          this.allTokensList.push(tokenInfo)
        }
      } catch (error) {
        console.log("tokenInfo"+error.code);
      }
    },

    //我的TOKEN列表
    getMyTokenList() {
      this.myTokensList = this.allTokensList.filter((item) => item.owner === this.account)
    },
    //带参数跳转
    handleView(row) {  
      const { coinAddress, mode } = row
      console.log(mode);
      if (mode===`${this.$t('title.stardand')}`) {
        this.$router.push({ name: 'StardandDetail', params: { coinAddress } })
      }else if(mode===`${this.$t('title.holdReflection')}`){
        this.$router.push({ name: 'HoldReflectionDetail', params: { coinAddress } })
      }else if(mode===`${this.$t('title.lpWithInviter')}`){
        this.$router.push({ name: 'LpWithInviterDetail', params: { coinAddress } })
      }else if(mode===`${this.$t('title.lpBurn')}`){
        this.$router.push({ name: 'LpBurnDetail', params: { coinAddress } })
      }else if(mode===`${this.$t('title.lpMine')}`){
        this.$router.push({ name: 'LpMineDetail', params: { coinAddress } })
      }else if(mode===`${this.$t('title.X314')}`){
        this.$router.push({ name: 'X314Detail', params: { coinAddress } })
      }else{
        this.$router.push({ name: 'StardandDetail', params: { coinAddress } })
      }
    },
    //切换链
    async setChainId() {
      this.switchChain()
    },
    // ----------切换链-------------
		async switchChain() {
			if (window.ethereum) {
        console.log('----------- switchChain -------------')
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: decToHex(Number(this.chainlist[this.chainNum].chainId)) }],
          })
				} catch (switchError) {
          if (switchError.code === 4902) {
            this.$message.error(switchError.message)
            try {
              await window.ethereum.request({
                "method": 'wallet_addEthereumChain',
                "params": [
                  {
                    "chainId": decToHex(Number(this.chainlist[this.chainNum].chainId)),
                    "chainName": this.chainlist[this.chainNum].chainName,
                    "rpcUrls": this.chainlist[this.chainNum].rpcUrls,
                    "nativeCurrency": {
                      "name": this.chainlist[this.chainNum].nativeCurrency.name,
                      "symbol": this.chainlist[this.chainNum].nativeCurrency.symbol,
                      "decimals": this.chainlist[this.chainNum].nativeCurrency.decimals
                    },
				            "blockExplorerUrls":this.chainlist[this.chainNum].blockExplorerUrls
                  },
                ],
              });
            } catch (addError) {
              this.$message.error(addError.code)
            }
          }
					// User denied account access
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
      
			// Verify Accounts!
		},
    // ----------初始化账户-------------
		async initAccount() {
			if (window.ethereum) {
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          //获取链ID
          let chainId = await window.ethereum.request({ method: 'eth_chainId' })
          let chainIdB = Number(BigNumber(chainId).toString())
          let selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
          if (!selectedChain) {
            this.chainNum = `${this.$t("tokenPublic.token134")}`
            return;
          }
          this.chainNum = selectedChain.id;
          console.log('chainNum: ' + this.chainNum)
				} catch (switchError) {
          this.$message.error(switchError.code)
				}
			} else {
        this.$message.error('Need install MetaMask')
        return
			}
			// Verify Accounts!
		},
    //复制
    copy(text) {
      const clipboard = new Clipboard('.copy', {
          text: () => {
              return text
          },
      })
      clipboard.on('success', () => {
          this.$message.success(this.$t('pubilc.copySauccessfully'))
          clipboard.destroy()
      })
      clipboard.on('error', () => {
          this.$message.error(this.$t('pubilc.copyFailed'))
          clipboard.destroy()
      })
    },
    //时间戳转换
    formattedTime(timestamp) {
      // 将时间戳转换为日期时间
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      // 构建日期时间字符串
      const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return time;
    },

	},
  watch:{
    //监听 chainNum 本地持久化
    chainNum:{
      deep:true,
      handler(newVal, oldVal){
        localStorage.setItem('chainNum',JSON.stringify(newVal))

      }
    },
    chainlist:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainlist',JSON.stringify(newVal))
      }
    }
  },
}
</script>

<style scoped>
.stardand {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 1200px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}

.senderMain {
  padding: 5px 30px 30px 30px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .title{
  font-size: 22px;
  margin: 20px 0;
  text-align: center;
}
.senderMain .introduction{
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
}
.senderMain .chainNumBox{
  width: 100%;
}
.senderMain .chainNumBox .itemChainName{
  float: left;
}
.senderMain .chainNumBox .itemChainLogo{
  float:right;
  color: #8492a6;
  font-size: 13px;
}
.senderMain .chainNumBox .itemChainLogo{
  width: 20px;
  height: 20px;
}
::v-deep .chainNumBox .el-input__inner{
  padding-left: 30px;
}
.senderMain .chainLogo{
  position: absolute;  
  width: 18px;  
  height: 18px;
  top: 11px;
  left: 2px;
  border-radius: 50%;  
  margin-left: 5px;
  z-index: 999;
}
.senderMain .fee{
  margin-left: 10px;
}
.senderMain .duanInput{
  margin: 5px 0;
  width: 200px;
}
.buttonBn button{
  margin-bottom: 15px;
}
.buttonBn a{
  margin-bottom: 15px;
  margin-right: 15px;
}

::v-deep .el-form-item__content{
  margin-bottom: 10px;

}

@media (max-width:768px){
  ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
  }
</style>