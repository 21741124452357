<template>
  <div class="addressView">
    <Navigation></Navigation>
    <div class="container">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-button size="small" type="success" @click="isAddPrivatekey = true" icon="el-icon-upload2">  {{$t('batchTools.pubilc.num2')}} </el-button>
          <el-button size="small" type="info" @click="deleteCache()" icon="el-icon-delete" plain> {{$t('batchTools.pubilc.num5')}} </el-button>
        </el-col>
      </el-row>

      <el-table :data="walletArr" key="table1" border ref="selectedTable" @selection-change="handleSelectionChange" :reserve-selection="true">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="ID"  width="60" type="index">
          <!-- <template type="index"></template> -->
        </el-table-column>
        <el-table-column :label="$t('batchTools.pubilc.num6')" width="500">
          <template slot-scope="scope">{{ scope.row.walletAddr }}</template>
        </el-table-column>
        <el-table-column :label="$t('batchTools.pubilc.num25')" width="150">
          <template slot-scope="scope">{{ scope.row.ethBalance }}</template>
        </el-table-column>

        <el-table-column :label="$t('batchTools.pubilc.num26')" width="150">
          <div slot-scope="scope" v-if="scope.row.txResult === '1'">
            <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num67')}}</el-button>
          </div>
          <div slot-scope="scope" v-else-if="scope.row.txResult === '2'">
            <a :href="scope.row.hashTx" target="_blank" rel="noopener noreferrer">
              <el-button size="mini" type="success" plain> {{$t('batchTools.pubilc.num68')}}</el-button>
            </a>
          </div>
          <div slot-scope="scope" v-else-if="scope.row.txResult === '3'">
            <el-button size="mini" type="danger" plain disabled> {{$t('batchTools.pubilc.num69')}}</el-button>
          </div>
          <div slot-scope="scope" v-else-if="scope.row.txResult === '4'">
            <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num70')}}</el-button>
          </div>
          <div v-else>
            <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num71')}}</el-button>
          </div>
        </el-table-column>

        <el-table-column :label="$t('batchTools.pubilc.num7')">
          <template slot-scope="{row}"><el-button size="mini" type="danger" icon="el-icon-delete" @click="setDelete(row.id)" circle></el-button></template>
        </el-table-column>
        <div slot="empty">
          <p>{{$t('batchTools.pubilc.num27')}}</p>
        </div>
      </el-table>

      <div class="selectDelete">
        <el-row :gutter="10">
          <el-button size="small" type="primary" @click="setSelectEHT0()" plain>{{$t('batchTools.pubilc.num8')}} {{ tokenArr[0].chainCoin }} 0 {{$t('batchTools.pubilc.num9')}}</el-button>
          <el-button size="small" type="primary" @click="setCheckError()" icon="el-icon-delete" plain>{{$t('batchTools.pubilc.num29')}}</el-button>
          <el-button size="small" type="danger" @click="setCheckArr()" icon="el-icon-delete" plain>{{$t('batchTools.pubilc.num10')}}</el-button>
          <el-button size="small" type="danger" @click="setDeleteArr()" icon="el-icon-delete" plain>{{$t('batchTools.pubilc.num11')}}</el-button>
        </el-row>
      </div>

      <el-divider></el-divider>

      <el-row>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="sub-title"> {{$t('batchTools.pubilc.num12')}} </div>
          <el-select :default-first-option="false" v-model="chainID" @change="selectChainID">
            <el-option v-for="(item, index) in chainArr" :key="index" :label="item.chainName" :value="item.id"></el-option>
          </el-select>
          <el-button type="warning" @click="isAddChain = true"  icon="el-icon-plus"> </el-button>
        </el-col>
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="sub-title"> {{$t('batchTools.pubilc.num13')}} </div>
          <el-input v-model="setRpcUrl" label="RPC" placeholder="https://"></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div class="sub-title"> {{$t('batchTools.pubilc.num14')}} </div>
          <el-select :default-first-option="false" v-model="tokenArr[0].tokenSymbol">
            <el-option :value="tokenArr[0].tokenSymbol"> {{ tokenArr[0].tokenSymbol }}</el-option>
          </el-select>
          <el-button type="primary" @click="batchQueryToken()" icon="el-icon-search"> </el-button>
        </el-col>
        <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
          <div class="sub-title"> {{$t('batchTools.pubilc.num18')}} 
            <span class="transferYourself">
              <el-switch
              v-model="transferYourself"
              :active-text="$t('batchTools.pubilc.num19')"
              active-color="#13ce66"
              inactive-color="#ff4949">
            </el-switch>
            </span>
          </div>
          <el-input :disabled="transferYourself" v-model="transferTo" label="RPC" placeholder="0x"></el-input>
        </el-col>
      </el-row>

      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <div class="sub-title"> {{$t('batchTools.pubilc.num20')}}  : 
            <el-radio-group v-model="formatSelection" class="sendText">
              <el-radio :label="1"> {{$t('batchTools.pubilc.num21')}} </el-radio>
              <el-radio :label="2"> {{$t('batchTools.pubilc.num22')}} </el-radio>
            </el-radio-group>
          </div>
          <el-input v-show="formatSelection == 1" v-model="sendTextData" label="RPC" placeholder="data:,{&quot;p&quot;: &quot;bsc-20&quot;,&quot;op&quot;: &quot;mint&quot;,&quot;tick&quot;: &quot;bnblong&quot;,&quot;amt&quot;: &quot;1000&quot;}"></el-input>
          <el-input v-show="formatSelection == 2" type="textarea" :rows="3" placeholder="0x" v-model="sendHexadecimalData"></el-input>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="sub-title">Gas Price</div>
          <el-input-number v-model="gasPriceNum" @change="gasPriceFn" :step="2" label="Price" placeholder="0"></el-input-number>
          <el-button style="margin-left: 15px;" type="primary" @click="getGasPriceFn()" plain> {{$t('batchTools.pubilc.num23')}} </el-button>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <div class="sub-title">Gas Limit</div>
          <el-input-number v-model="gasLimit" @change="gasLimitFn" :step="1000" label="Limit" placeholder="0"></el-input-number>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
          <div class="sub-title">Gas Fee</div>
          <el-input v-model="gasFee" label="Fee" placeholder="0" disabled></el-input>
        </el-col>
        
      </el-row>

      <el-row >
        <el-col :span="24">
          <el-button type="danger" @click="batchEngraving()" :loading="isLoading"> {{$t('batchTools.pubilc.num24')}} </el-button>
        </el-col>
      </el-row>

      <!-- 导入私钥弹窗 -->
      <el-dialog title="导入私钥" :visible.sync="isAddPrivatekey">
        <el-input type="textarea" :rows="10" placeholder="请输入内容" v-model="privateKeys"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button @click="isAddPrivatekey = false"> {{$t('pubilc.cancel')}} </el-button>
          <el-button type="primary" @click="inputKey"> {{$t('pubilc.sure')}} </el-button>
        </div>
      </el-dialog>

      <!-- 自定义链 弹窗 -->
      <el-dialog :title="$t('batchTools.pubilc.num4')" :visible.sync="isAddChain" width="30%">
        <el-row :gutter="20">
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num55')}} </div>
            <el-input placeholder="Ethereum Mainnet" v-model="addChainNmae"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num56')}} </div>
            <el-input placeholder="ETH" v-model="addChainSymbol"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num58')}} </div>
            <el-input placeholder="1" v-model="addChainId"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num59')}} </div>
            <el-input placeholder="https://eth.drpc.org" v-model="addChainRpc"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num60')}} </div>
            <el-input placeholder="https://etherscan.io" v-model="addchainUrl"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title">W{{ mainSymbol }} {{$t('batchTools.pubilc.num64')}} </div>
            <el-input placeholder="0x" v-model="addWETHAddr"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title">W{{ mainSymbol }} LOGO Url</div>
            <el-input placeholder="https://" v-model="addWETHUrl"></el-input>
          </el-col>
        </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isAddChain = false"> {{$t('pubilc.cancel')}} </el-button>
        <el-button type="primary" @click="addChainModal"> {{$t('pubilc.sure')}} </el-button>
      </div>
      </el-dialog>

    </div>
  </div>



</template>

<script>
import Navigation from "../components/Navigation"
import { ethers } from "ethers"
import chainArr from '../abi/swapToken.json'
import { BigNumber, formatUnits, parseUnits, stringToHex} from '../utils/utils.js'

export default {
  name: "addresAndEMS",
  components: {
    Navigation,
  },
  metaInfo() {
    return {
      title: "WuHaXi- " + this.title,

      meta: [
        {
          name: "keyword",
          content: "批量铭刻铭文",
        },
      ],
    };
  },
  data() {
    return {
      //加载
      load: false,
      address:'',
      isLoading: false,

      isAddPrivatekey: false,//私钥弹窗显示开关
      privateKeys:'',
      walletArr: JSON.parse(localStorage.getItem('walletArr')) || [],
      chainArr:JSON.parse(localStorage.getItem('chainArr')) || chainArr,
      mainSymbol:'',
      chainID:JSON.parse(localStorage.getItem('chainID')) || '0',
      tokenArr:[],
      tokenID:'0',
      setRpcUrl:'',
      formatSelection: 2,
      transferYourself:true,
      transferTo:'',
      sendTextData:'',
      sendHexadecimalData:'',
      gasPriceNum:'',
      gasLimit:'30000',
      gasFee:'',

      provider:{},
      selectedTable:[],

      isAddChain: false,//添加链弹窗显示开关
      addChainNmae:'',
      addChainSymbol:'',
      addChainId:'',
      addChainRpc:'',
      addchainUrl:'',
      addWETHAddr:'',
      addWETHUrl:'',
    };
  },
  
  computed:{
    title(){
      return this.$t("title.insertionTool")
    }
  },
  created() {
    this.tokenArr = this.chainArr[this.chainID].tokenData

    // this.myWeb = new Web3(this.setRpcUrl)
  },

  async mounted(){
    this.selectChainID()
    for (let i = 0; i < this.walletArr.length; i++) {
      this.walletArr[i].txResult = '1'
    }
  },
  methods: {
    gasPriceFn(value){
    this.gasFeeNum(value,this.gasLimit)
    },
    gasLimitFn(value){
      this.gasFeeNum(this.gasPriceNum,value)
    },
    //选择修改链
    selectChainID(){
      let rpcurl = this.chainArr[this.chainID].chainRpc
      this.setRpcUrl = rpcurl
      this.provider = new ethers.providers.JsonRpcProvider(rpcurl)
      this.tokenID = '0'
      this.tokenSymbol = ''
      this.tokenArr = this.chainArr[this.chainID].tokenData
      this.mainSymbol = this.chainArr[this.chainID].chainCoin
      this.tokenContractAddr = ''
      for (let i = 0; i < this.walletArr.length; i++) {
        this.walletArr[i].txResult = '1'
        this.walletArr[i].tokenBalance = '0'
        this.walletArr[i].ethBalance = '0'
      }
      this.getGasPriceFn()
      this.batchQueryToken()
    },
    async getGasPriceFn(){//返回到网页
      let gas = await this.provider.getGasPrice()
      gas = Math.ceil(formatUnits(gas, "gwei"))
      this.gasPriceNum = gas
      this.gasFeeNum(gas,this.gasLimit)
    },
    gasFeeNum(gas,gasLimit){//返回到网页
      let gasFee = new BigNumber(gas).mul(BigNumber(gasLimit)).toString()
      this.gasFee = formatUnits(gasFee, "gwei")
    },

    async selectchainCoin(){
      console.log('this.chainCoin')
    },
    //导入私钥
    async inputKey() {
      this.walletArr = []
      let newPrivateKey = this.privateKeys.split('\n')
      let id = 1
      for (let i = 0; i < newPrivateKey.length; i++) {
        var keyStr = newPrivateKey[i]
        if (keyStr.slice(0,2) !== '0x') {
          keyStr = '0x' + keyStr
        }
        if (keyStr == '' || keyStr.length !== 66 ) {
            return
        }
        let accountArr = new ethers.Wallet(keyStr, this.provider)
            accountArr = accountArr.address
        // walletArr 数组增加对象
        this.walletArr.push({
            id:id+i,
            walletKey:keyStr,
            walletAddr:accountArr, 
            ethBalance: '0', 
            txResult: '1',
            hashTx:''
        });
      }
      this.isAddPrivatekey = false
      this.privateKeys = ''
    },
    //批量查询主币余额
    batchQueryToken(){ 
      let walletArr = this.walletArr
      for (let i = 0; i < walletArr.length; i++) {
        this.checkBalance(walletArr[i],i)
      }
    },
    async checkBalance(wallet,id){//查ETH余额
      try {
        let mainTokenNum = await this.provider.getBalance(wallet.walletAddr)
        this.walletArr[id].ethBalance = Math.floor(formatUnits(mainTokenNum, 18)  * 100000 ) / 100000
      } catch (error) {
        this.$message.error(`${this.$t('batchTools.pubilc.num57')}:${error.code}`)
      }
    },

    addChainModal(){
      const id = (this.chainArr.length).toString()
      const arr = {
        "id":id,
        "chainName":this.addChainNmae,
        "chainCoin":this.addChainSymbol,
        "chainId":this.addChainId,
        "chainRpc":this.addChainRpc,
        "chainUrl":this.addchainUrl,
        "exchanges":[],
        "tokenData":[
          {
            "tokenId":"0",
            "tokenContract":this.addWETHAddr,
            "tokenSymbol":this.addChainSymbol,
            "tokenName":'Wrapped ' + this.addChainSymbol,
            "tokenDecimals":'18',
            "tokenLogo":this.addWETHUrl
          }
        ],
      }
      this.chainArr.push(arr);
      this.isAddChain = false
    },

    //批量铭刻铭文
    async batchEngraving(){
      this.isLoading = true
      if (this.walletArr.length === 0) {
        this.$message.error(this.$t('batchTools.pubilc.num39'))
        this.isLoading = false
        return
      }
      var inscriptionData = ''
      if (this.formatSelection === 2) {
        inscriptionData = this.sendHexadecimalData
      }else{
        inscriptionData = stringToHex(this.sendTextData)
      }
      if (inscriptionData === '') {
        this.$message.error(this.$t('batchTools.pubilc.num88'))
        this.isLoading = false
        return
      }
      let gasHs = BigNumber(parseUnits((this.gasPriceNum).toString(), "gwei"))
      let walletArr = this.walletArr;
      let length = walletArr.length;
      for (let index = 0; index < length; index++) {
        this.engraving(walletArr[index],gasHs,inscriptionData);
      }
    },

    //铭刻铭文
    async engraving(wallet,gasHs,inscriptionData){
      wallet.txResult = '4'
      let toAddr = this.transferTo
      if (toAddr === '') {
        toAddr = wallet.walletAddr
      }
      let privateKey = wallet.walletKey;
      let signer = new ethers.Wallet(privateKey, this.provider)
      let nonce = await this.provider.getTransactionCount(wallet.walletAddr, "pending");
      let txParams = {
          nonce: nonce,
          gasPrice: gasHs,
          gasLimit: BigNumber(this.gasLimit),
          to: toAddr, 
          value: '',
          data: inscriptionData,
      }
      try {
        let receipt = await signer.sendTransaction(txParams)
        await receipt.wait()
        console.log(receipt.hash)
        wallet.txResult = '2';//成功了
        let hashUrl = this.chainArr[this.chainID].chainUrl+'/tx/'+receipt.hash
        wallet.hashTx = hashUrl;//哈希
        this.batchQueryToken()
        this.isLoading = false
      } catch (error) {
        wallet.txResult = '3';//失败了
        this.$message.error(`${this.$t('batchTools.pubilc.num72')}:${error.code}`)
        this.isLoading = false
      }
    },

    //操作 删除单个 walletArr 数据 
    setDelete(id) {
      this.walletArr = this.walletArr.filter((item) => item.id !== id)
    },

    //删除 所有数据 
    setDeleteArr(){
      this.walletArr = []
    },

    //删除选中 数据 
    setCheckArr(){
      let deleteids = this.selectedTable
      const newArr = this.walletArr.filter((a) => !deleteids.some((b) => a.id === b.id))
      this.walletArr = newArr;
    },

    //选中 所有ETH 0
    setSelectEHT0(){
      for (let i = 0; i <  this.walletArr.length; i++) {
        if ( this.walletArr[i].ethBalance === 0) {
          this.$refs.selectedTable.toggleRowSelection(this.walletArr[i],true)
        }
      }
    },

    //选中 所有错误
    setCheckError(){
      for (let i = 0; i <  this.walletArr.length; i++) {
        if ( this.walletArr[i].txResult === '3') {
          this.$refs.selectedTable.toggleRowSelection(this.walletArr[i],true)
        }
      }
    },

    //选择框
    handleSelectionChange(val) {
      this.selectedTable = val;
      console.log(this.selectedTable)
    },

    //清空本地粗存 walletArr 数据
    deleteCache(){
      this.walletArr = []
    }

  },
  watch:{
    //监听 walletArr 本地持久化
    walletArr:{
      deep:true,
      handler(newValue){
        localStorage.setItem('walletArr',JSON.stringify(newValue))
      }
    },
    chainArr:{
      deep:true,
      handler(newValue){
        localStorage.setItem('chainArr',JSON.stringify(newValue))
      }
    },
    chainID:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainID',JSON.stringify(newVal))
      }
    }
  },
};
</script>

<style scoped>
.el-row{
  margin-bottom: 20px;
}
::v-deep .el-table th > .cell {
  text-align: center;
}
::v-deep .el-table td > .cell {
  text-align: center;
}


.el-table td.el-table__cell{
  text-align: center;
}

.addressView {
  width: 100%;
  height: auto;
  min-height: 94%;
}

.container {
  max-width: 1200px;
  padding: 32px;
  height: min-content;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.selectDelete{
  margin-top: 15px;
}
.selectDelete button{
	margin-bottom: 10px;
  margin-left: 10px;
  }
.sub-title{
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.sendText{
  margin-left: 15px;
}
.transferYourself{
  float: right;
}

.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  position:relative;
}


.load {
  width: 30px;
  height: 30px;
  filter: invert(100%);
  vertical-align: middle;
}
@media (max-width:768px){
  ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
    .container .result{
      word-break: break-all;
    }
    
    .container div .unctionalArea{
        flex-wrap: wrap;
    }

    .unctionalArea .el-button{
        margin-top: 20px;
    }
}
</style>
