<template>
	<div class="addressView">
	  <Navigation></Navigation>
	  <div class="container">
		<el-row>
		  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
			<el-button size="small" type="success" @click="isAddPrivatekey = true" icon="el-icon-upload2"> {{$t('batchTools.pubilc.num2')}} </el-button>
			<el-button size="small" type="info" @click="deleteCache()" icon="el-icon-delete" plain>{{$t('batchTools.pubilc.num5')}}</el-button>
		  </el-col>
		</el-row>
  
		<el-table :data="walletArr" key="table1" border ref="selectedTable" @selection-change="handleSelectionChange" :reserve-selection="true">
		  <el-table-column type="selection" width="55"></el-table-column>
		  <el-table-column label="ID"  width="60" type="index">
			<!-- <template type="index"></template> -->
		  </el-table-column>
		  <el-table-column :label="$t('batchTools.pubilc.num6')" width="420">
			<template slot-scope="scope">{{ scope.row.walletAddr }}</template>
		  </el-table-column>
		  <el-table-column :label="mainSymbol" width="180">
			<template slot-scope="scope">{{ scope.row.ethBalance }}</template>
		  </el-table-column>
		  <el-table-column v-if="tokenSymbol !== mainSymbol && tokenSymbol !== '' " :label="tokenSymbol" width="180">
			<template slot-scope="scope">{{ scope.row.tokenBalance }}</template>
		  </el-table-column>
  
		  <el-table-column :label="$t('batchTools.pubilc.num7')">
			<template slot-scope="{row}"><el-button size="mini" type="danger" icon="el-icon-delete" @click="setDelete(row.id)" circle></el-button></template>
		  </el-table-column>
		  <div slot="empty">
			<p> {{$t('batchTools.pubilc.num39')}} </p>
		  </div>
		</el-table>
  
		<div class="selectDelete">
		  <el-row :gutter="10">
			<el-button size="small" type="primary" @click="setSelectEHT0()" plain> {{$t('batchTools.pubilc.num8')}}  {{ mainSymbol}} 0  {{$t('batchTools.pubilc.num9')}} </el-button>
			<el-button size="small" type="primary" @click="setSelectToken0()" v-if="tokenSymbol !== mainSymbol && tokenSymbol !== '' " plain> {{$t('batchTools.pubilc.num8')}}  {{ tokenSymbol }} 0  {{$t('batchTools.pubilc.num9')}} </el-button>
			<el-button size="small" type="danger" @click="setCheckArr()" icon="el-icon-delete" plain> {{$t('batchTools.pubilc.num10')}} </el-button>
			<el-button size="small" type="danger" @click="setDeleteArr()" icon="el-icon-delete" plain> {{$t('batchTools.pubilc.num11')}} </el-button>
		  </el-row>
		</div>
  
		<el-divider></el-divider>
  
		<el-row>
		  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
			<div class="sub-title"> {{$t('batchTools.pubilc.num12')}} </div>
			<el-select :default-first-option="false" v-model="chainID" @change="selectChainID">
			  <el-option v-for="(item, index) in chainArr" :key="index" :label="item.chainName" :value="item.id"></el-option>
			</el-select>
			<el-button type="warning" @click="isAddChain = true"  icon="el-icon-plus"></el-button>
		  </el-col>
		  <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
			<div class="sub-title"> {{$t('batchTools.pubilc.num13')}} </div>
			<el-input v-model="setRpcUrl" label="RPC" placeholder="https://"></el-input>
		  </el-col>
		</el-row>
  
		<el-row>
		  <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
			<div class="sub-title"> {{$t('batchTools.pubilc.num14')}} </div>
			<el-select :default-first-option="false" v-model="tokenID" @change="selectchainCoin">
			  <el-option v-for="(item, index) in tokenArr" :key="index" :label="item.tokenSymbol" :value="item.tokenId"></el-option>
			</el-select>
			<el-button type="warning" @click="isAddToken = true"  icon="el-icon-plus"></el-button>
		  </el-col>
		  <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="16">
			<div class="sub-title"> {{$t('batchTools.pubilc.num15')}} </div>
			<el-input v-model="tokenContractAddr" label="RPC" placeholder="0x" disabled></el-input>
		  </el-col>
		</el-row>
  
		<el-row >
		  <el-col :span="24">
			<el-button type="primary" @click="batchQueryToken()" :loading="isLoading"> {{$t('batchTools.pubilc.num17')}} </el-button>
		  </el-col>
		</el-row>
  
		<!-- 导入私钥弹窗 -->
		<el-dialog :title="$t('batchTools.pubilc.num1')" :visible.sync="isAddPrivatekey">
		  <el-input type="textarea" :rows="10" :placeholder="$t('batchTools.pubilc.num40')" v-model="privateKeys"></el-input>
		  <div slot="footer" class="dialog-footer">
			<el-button @click="isAddPrivatekey = false"> {{$t('pubilc.cancel')}} </el-button>
			<el-button type="primary" @click="inputKey"> {{$t('pubilc.sure')}} </el-button>
		  </div>
		</el-dialog>
  
		<!-- 自定义链 弹窗 -->
		<el-dialog :title="$t('batchTools.pubilc.num4')" :visible.sync="isAddChain" width="30%">
        <el-row :gutter="20">
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num55')}} </div>
            <el-input placeholder="Ethereum Mainnet" v-model="addChainNmae"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num56')}} </div>
            <el-input placeholder="ETH" v-model="addChainSymbol"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num58')}} </div>
            <el-input placeholder="1" v-model="addChainId"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num59')}} </div>
            <el-input placeholder="https://eth.drpc.org" v-model="addChainRpc"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title"> {{$t('batchTools.pubilc.num60')}} </div>
            <el-input placeholder="https://etherscan.io" v-model="addchainUrl"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title">W{{ mainSymbol }} {{$t('batchTools.pubilc.num64')}} </div>
            <el-input placeholder="0x" v-model="addWETHAddr"></el-input>
          </el-col>
          <el-col :xs="24">
            <div class="sub-title">W{{ mainSymbol }} LOGO Url</div>
            <el-input placeholder="https://" v-model="addWETHUrl"></el-input>
          </el-col>
        </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isAddChain = false"> {{$t('pubilc.cancel')}} </el-button>
        <el-button type="primary" @click="addChainModal"> {{$t('pubilc.sure')}} </el-button>
      </div>
      </el-dialog>
  
		 <!-- 自定代币 弹窗 -->
		 <el-dialog :title="$t('batchTools.pubilc.num3')" :visible.sync="isAddToken">
		  <el-row :gutter="20">
			<el-col :xs="24">
			  <div class="sub-title">{{$t('batchTools.pubilc.num34')}} </div>
			  <el-input placeholder="0x" v-model="tokenContractAddr"></el-input>
			</el-col>
		  </el-row>
		<div slot="footer" class="dialog-footer">
		  <el-button @click="isAddToken = false"> {{$t('pubilc.cancel')}} </el-button>
		  <el-button type="primary" @click="addTokenModal">{{$t('pubilc.sure')}} </el-button>
		</div>
		</el-dialog>
  
	  </div>
	</div>
  
  
  
  </template>
  
  <script>
  import Navigation from "../components/Navigation"
  import { ethers } from "ethers"
  import ercABI from '../abi/ERC20.json'
  import { BigNumber , formatUnits  , parseUnits} from '../utils/utils.js'
  import chainArr from '../abi/swapToken.json'
  
  export default {
	name: "addresAndEMS",
	components: {
	  Navigation,
	},
	metaInfo() {
	  return {
		title: "WuHaXi- " + this.title,
  
		meta: [
		  {
			name: "keyword",
			content: "批量查询钱包余额",
		  },
		],
	  };
	},
	data() {
	  return {
		//加载
		load: false,
		address:'',
		isLoading:false,
  
		isAddPrivatekey: false,//私钥弹窗显示开关
		privateKeys:'',
		walletArr: JSON.parse(localStorage.getItem('walletArr')) || [],
		chainArr:JSON.parse(localStorage.getItem('chainArr')) || chainArr,
		mainSymbol:'',
		chainID:JSON.parse(localStorage.getItem('chainID')) || '0',
		tokenArr:[],
		tokenID:'0',
		setRpcUrl:'',
  
		provider:{},
		selectedTable:[],
  
		isAddChain: false,//添加链弹窗显示开关
		addChainNmae:'',
		addChainSymbol:'',
		addChainId:'',
		addChainRpc:'',
		addchainUrl:'',
		addWETHAddr:'',
      	addWETHUrl:'',
  
		isAddToken: false,//添加Token 弹窗显示开关
		tokenContractAddr:'',
		tokenSymbol:'',
		tokenDecimals:'18',
		tokenBalance:'',
	  };
	},
	
	computed:{
	  title(){
		return this.$t("title.bulkQuery")
	  }
	},
	created() {
	  this.tokenArr = this.chainArr[this.chainID].tokenData
	},
  
	async mounted(){
	  this.selectChainID()
	},
	methods: {
	  //选择修改链
	  selectChainID(){
		let rpcurl = this.chainArr[this.chainID].chainRpc
		this.setRpcUrl = rpcurl
		this.provider = new ethers.providers.JsonRpcProvider(rpcurl)
		this.tokenID = '0'
		this.tokenSymbol = ''
		this.tokenArr = this.chainArr[this.chainID].tokenData
		this.mainSymbol = this.chainArr[this.chainID].chainCoin
		this.tokenContractAddr = ''
		for (let i = 0; i < this.walletArr.length; i++) {
		  this.walletArr[i].tokenBalance = '0'
		  this.walletArr[i].ethBalance = '0'
		}
		this.batchQueryToken()
	  },
	  //选择token
	  selectchainCoin(){
		let newTokenArr = this.tokenArr.filter((item) => item.tokenId === this.tokenID)
      	this.tokenSymbol = newTokenArr[0].tokenSymbol
      	this.tokenDecimals = newTokenArr[0].tokenDecimals
      	this.tokenContractAddr = newTokenArr[0].tokenContract
		for (let i = 0; i < this.walletArr.length; i++) {
		  this.walletArr[i].tokenBalance = '0'
		}
	  },
	  //导入私钥
	  async inputKey() {
		this.walletArr = []
		let newPrivateKey = this.privateKeys.split('\n')
		let id = 1
		for (let i = 0; i < newPrivateKey.length; i++) {
		  var keyStr = newPrivateKey[i]
		  if (keyStr.slice(0,2) !== '0x') {
			keyStr = '0x' + keyStr
		  }
		  if (keyStr == '' || keyStr.length !== 42 ) {
			  return
		  }
		  this.walletArr.push({
			  id:id+i,
			  walletAddr:keyStr, 
			  ethBalance: '0',
			  tokenBalance: '0'
		  });
		}
		this.isAddPrivatekey = false
		this.privateKeys = ''
		this.batchQueryToken()
	  },
	  //批量查询主币余额
	  batchQueryToken(){
		this.isLoading = true
		let walletArr = this.walletArr
		if (this.walletArr.length === 0) {
			this.$message.error(this.$t('batchTools.pubilc.num39'))
			this.isLoading = false
			return
		}
		if (this.tokenContractAddr === '') {
		  for (let i = 0; i < this.walletArr.length; i++) {
			this.checkBalanceETH(walletArr[i],i)
		  }
		}else{
		  let tokenCt = new ethers.Contract(this.tokenContractAddr, ercABI, this.provider)
		  for (let i = 0; i < this.walletArr.length; i++) {
			this.checkBalanceToken(walletArr[i],i,tokenCt)
		  }
		}
	  },
	  async checkBalanceETH(wallet,id){ //查ETH余额
		try {
		  let mainTokenNum = await this.provider.getBalance(wallet.walletAddr)
		  this.walletArr[id].ethBalance = Math.floor(formatUnits(mainTokenNum, 18)  * 100000 ) / 100000
		  this.isLoading = false
		} catch (error) {
		  this.isLoading = false
		  this.$message.error(`${this.$t('batchTools.pubilc.num57')}:${error.code}`)
		}
	  },
	  async checkBalanceToken(wallet,id,tokenCt){  //查ERC20余额
		try {
		  let tokenBa = await tokenCt.balanceOf(wallet.walletAddr)
		  this.walletArr[id].tokenBalance = Math.floor(formatUnits(tokenBa, this.tokenDecimals) * 100000 ) / 100000
		  this.isLoading = false
		} catch (error) {
	  	  this.isLoading = false
		  this.$message.error(`${this.$t('batchTools.pubilc.num57')}:${error.code}`)
		}
	  },
	  //添加链
	  addChainModal(){
      const id = (this.chainArr.length).toString()
      const arr = {
        "id":id,
        "chainName":this.addChainNmae,
        "chainCoin":this.addChainSymbol,
        "chainId":this.addChainId,
        "chainRpc":this.addChainRpc,
        "chainUrl":this.addchainUrl,
        "exchanges":[],
        "tokenData":[
          {
            "tokenId":"0",
            "tokenContract":this.addWETHAddr,
            "tokenSymbol":this.addChainSymbol,
            "tokenName":'Wrapped ' + this.addChainSymbol,
            "tokenDecimals":'18',
            "tokenLogo":this.addWETHUrl
          }
        ],
      }
      this.chainArr.push(arr);
      this.isAddChain = false
    },
  
	  //添加代币
	async addTokenModal(){
      if (this.tokenContractAddr.length ==='' || this.tokenContractAddr.length !== 42) {
        this.$message.error(this.$t('batchTools.pubilc.num65'))
        return
      }
      const id = (new Date().getTime()).toString()
      try {
        const tokenContract = new ethers.Contract(this.tokenContractAddr, ercABI, this.provider)
        const tokenSymbol = await tokenContract.symbol()
        const tokenDecimals = await tokenContract.decimals()
        const tokenName = await tokenContract.name()
        const addToken = {
          'tokenId':id,
          'tokenContract':this.tokenContractAddr,
          'tokenSymbol':tokenSymbol,
          'tokenDecimals':tokenDecimals.toString(),
          'tokenName':tokenName,
          'tokenLogo':'./img/tokenIcon/unknown-token.png'
        }
        this.chainArr[this.chainID].tokenData.push(addToken)
        this.isAddToken = false
      } catch (error) {
        this.$message.error(this.$t('batchTools.pubilc.num66'))
		return
      }
    },
	  //批量归集

	  //操作 删除单个 walletArr 数据 
	  setDelete(id) {
		this.walletArr = this.walletArr.filter((item) => item.id !== id)
	  },
  
	  //删除 所有数据 
	  setDeleteArr(){
		this.walletArr = []
	  },
  
	  //删除选中 数据 
	  setCheckArr(){
		let deleteids = this.selectedTable
		const newArr = this.walletArr.filter((a) => !deleteids.some((b) => a.id === b.id))
		this.walletArr = newArr;
	  },
  
	  //选中 所有ETH 0
	  setSelectEHT0(){
		this.$refs.selectedTable.clearSelection()
		for (let i = 0; i <  this.walletArr.length; i++) {
		  if ( this.walletArr[i].ethBalance === '0') {
			this.$refs.selectedTable.toggleRowSelection(this.walletArr[i],true)
		  }
		}
	  },
	  //选中 所有ETH 0
	  setSelectToken0(){
		this.$refs.selectedTable.clearSelection()
		for (let i = 0; i <  this.walletArr.length; i++) {
		  if ( this.walletArr[i].tokenBalance === '0') {
			this.$refs.selectedTable.toggleRowSelection(this.walletArr[i],true)
		  }
		}
	  },
  
	  //选择框
	  handleSelectionChange(val) {
		this.selectedTable = val;
	  },
  
	  //清空本地粗存 walletArr 数据
	  deleteCache(){
		this.walletArr = []
	  },
  
	},
	watch:{
	  //监听 walletArr 本地持久化
	  walletArr:{
		deep:true,
		handler(newValue){
		  localStorage.setItem('walletArr',JSON.stringify(newValue))
		}
	  },
	  chainArr:{
		deep:true,
		handler(newVal){
		  localStorage.setItem('chainArr',JSON.stringify(newVal))
		}
	  },
	  chainID:{
		deep:true,
		handler(newVal){
			localStorage.setItem('chainID',JSON.stringify(newVal))
		}
	  }
	},
  };
  </script>
  
  <style scoped>
  .el-row{
	margin-bottom: 20px;
  }
  ::v-deep .el-table th > .cell {
	text-align: center;
  }
  ::v-deep .el-table td > .cell {
	text-align: center;
  }
  
  
  .el-table td.el-table__cell{
	text-align: center;
  }
  
  .addressView {
	width: 100%;
	height: auto;
	min-height: 94%;
  }
  
  .container {
	max-width: 1200px;
	padding: 32px;
	height: min-content;
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	margin: 30px auto;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  
  .selectDelete{
	margin-top: 15px;
  }
  .selectDelete button{
	margin-bottom: 10px;
	margin-left: 10px;
  }
  
  .sub-title{
	font-size: 14px;
	margin-top: 10px;
	margin-bottom: 10px;
  }
  .sendText{
	margin-left: 15px;
  }
  .isTotalQuantity{
	float: right;
  }
  
  .title {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 15px;
	position:relative;
  }
  
  
  .load {
	width: 30px;
	height: 30px;
	filter: invert(100%);
	vertical-align: middle;
  }
  @media (max-width:768px){
	::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
	  .container .result{
		word-break: break-all;
	  }
	  
	  .container div .unctionalArea{
		  flex-wrap: wrap;
	  }
  
	  .unctionalArea .el-button{
		  margin-top: 20px;
	  }
  }
  </style>
  