<template>
	<div class="detailMode">
		<div class="mainSender">
			<div class="container">

        <div class="senderMain">
          <div class="title">{{$t('tokenPublic.token113')}}({{$t('title.stardand')}}) </div>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
              <el-descriptions class="bottomMargin"  :column="2" size="medium" border>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token114')}}</template>
                  {{ coinName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token115')}}</template>
                  {{ coinSymbol }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token116')}} </template>
                  {{ coinTotalSupply }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token117')}}</template>
                  {{ coinDecimals }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token118')}} </template>
                  {{$t('title.stardand')}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token119')}}</template>
                  <div class="copy">{{ showAccount(coinOwner) }} <i class="el-icon-copy-document" @click="copy(coinOwner,'.copy')"></i></div> 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token120')}}</template>
                  <div class="copy">{{ showAccount(coinAddress) }} <i class="el-icon-copy-document" @click="copy(coinAddress,'.copy')"></i></div> 
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
          </el-row>
        </div>

			</div>
		</div>
	</div>

</template>

<script>
import { ethers } from 'ethers'
import Navigation from '../../components/Navigation'
import { BigNumber , formatUnitsFive, MAX_INT, parseUnits, decToHex, showAccount} from '../../utils/utils.js'
import tokenABI from '../../abi/token/stardandABI.json'
import chainlistData from '../../abi/tokenChainList.json'
import Clipboard from "clipboard";

export default {
	name: 'stardand',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '',
				},
			],
		}
	},

	data() {
		return {
      //链
      chainNum:JSON.parse(localStorage.getItem('chainNum')) || '0',
      chainlist: JSON.parse(localStorage.getItem('chainlist')) || chainlistData,
      //代币
      mainSymbol:'',
      provider:{},
      signer:{},
      account:'',
      isLoading:false,

      stardandFactory:'',
      //代币基本信息
      coinAddress:null,
      coinName:'',
      coinSymbol:'',
      coinDecimals:'',
      coinTotalSupply:'',
      coinOwner:'',
      
      isShowControl:false,
		}
	},

	async created() {
    this.coinAddress = this.$route.params.coinAddress
    // this.coinAddress = this.$route.query.coinAddress
    this.mainSymbol =this.chainlist[this.chainNum].nativeCurrency.symbol
    this.stardandFactory = this.chainlist[this.chainNum].stardandFactory
    // console.log(decToHex(97))
	},
	computed: {
		title() {
			return this.$t('title.stardand')
		},

	},
  async mounted() {
    await this.initAccount()
    if (this.chainNum === `${this.$t("tokenPublic.token134")}`) {
      this.chainLogo = './img/chainIcon/not.png'
      return
    }
    await this.getTokenDetail()
  },
	methods: {
    async getTokenDetail() {
      if (!this.coinAddress) {
        console.log("无法获取到代币地址");
        return
      }
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        this.coinName = await tokenContract.name()
        this.coinSymbol = await tokenContract.symbol()
        const decimals = await tokenContract.decimals()
        this.coinDecimals = BigNumber(decimals).toString()
        const totalSupply = await tokenContract.totalSupply()
        this.coinTotalSupply = formatUnitsFive(totalSupply, this.coinDecimals)
        this.coinOwner = await tokenContract.owner()
        if (this.coinOwner == this.account) {
          this.isShowControl = true
        }
      } catch (error) {
        console.log("tokenDetail"+error.code);
      }
    },

    // ----------初始化账户-------------
		async initAccount() {
			if (window.ethereum) {
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          //获取链ID
          const chainId = await window.ethereum.request({ method: 'eth_chainId' })
          const chainIdB = Number(BigNumber(chainId).toString())
          const selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
          if (!selectedChain) {
            this.chainNum = `${this.$t("tokenPublic.token134")}`
            return;
          }
          this.chainNum = selectedChain.id;
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
          });
				} catch (switchError) {
          if (switchError.code === 4902) {
            this.$message.error(switchError.message)
            try {
              await window.ethereum.request({
                "method": 'wallet_addEthereumChain',
                "params": [
                  {
                    "chainId": decToHex(Number(this.chainlist[this.chainNum].chainId)),
                    "chainName": this.chainlist[this.chainNum].chainName,
                    "rpcUrls": this.chainlist[this.chainNum].rpcUrls,
                    "nativeCurrency": {
                      "name": this.chainlist[this.chainNum].nativeCurrency.name,
                      "symbol": this.chainlist[this.chainNum].nativeCurrency.symbol,
                      "decimals": this.chainlist[this.chainNum].nativeCurrency.decimals
                    },
				            "blockExplorerUrls":this.chainlist[this.chainNum].blockExplorerUrls
                  },
                ],
              });
            } catch (addError) {
              this.$message.error(addError.code)
            }
          }
					// User denied account access
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
			// Verify Accounts!
		},
    showAccount(str) {
      if (str) {
          const account = String(str);
          return account.substring(0, 4) + "..." + account.substring(account.length - 4, account.length);
      }
      return "";
    },
    //复制
    copy(text, className) {
      const clipboard = new Clipboard(className, {
          text: () => {
              return text
          },
      })
      clipboard.on('success', () => {
          this.$message.success(this.$t('pubilc.copySauccessfully'))
          clipboard.destroy()
      })
      clipboard.on('error', () => {
          this.$message.error(this.$t('pubilc.copyFailed'))
          clipboard.destroy()
      })
    },

	},
  watch:{
    //监听 chainNum 本地持久化
    chainNum:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainNum',JSON.stringify(newVal))
      }
    },
    chainlist:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainlist',JSON.stringify(newVal))
      }
    }
  },
}
</script>

<style scoped>
.detailMode {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 1000px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}

.senderMain {
  padding: 5px 30px 30px 30px;
  height: auto;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .title{
  font-size: 22px;
  margin: 20px;
  text-align: center;
}
.senderMain .bottomMargin{
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
.titleBottom{
  margin: 10px 0;
}
.senderMain .bottomMargin button{
  margin-bottom: 10px;
  margin-left: 0px;
  width: 100%;
}

::v-deep .el-form-item__content{
  margin-bottom: 10px;
}

@media (max-width:768px){
  ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
  }
</style>