<template>
	<div class="createToken">
		<Navigation></Navigation>
		<router-view></router-view>
	</div>
</template>

<script>
import Navigation from '../components/Navigation'

export default {
	name: 'createToken',
	components: {
		Navigation,
	},
	methods: {
	},
}
</script>

<style scoped>
.createToken {
	width: 100%;
	height: auto;
	min-height: 94%;
}
</style>