<template>
	<div class="detailMode">
		<div class="mainSender">
			<div class="container">

        <div class="senderMain">
          <div class="title">{{$t('tokenPublic.token113')}}({{$t('title.X314')}})</div>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="lgCols" :xl="xlCols">
              <el-descriptions class="bottomMargin"  :column="2" size="medium" border>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token114')}}</template>
                  {{ coinName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token115')}}</template>
                  {{ coinSymbol }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token116')}} </template>
                  {{ coinTotalSupply }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token117')}}</template>
                  {{ coinDecimals }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token118')}} </template>
                  {{$t('title.X314')}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token119')}}</template>
                  <div class="copy">{{ showAccount(coinOwner) }} <i class="el-icon-copy-document" @click="copy(coinOwner)"></i></div> 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token120')}}</template>
                  <div class="copy">{{ showAccount(coinAddress) }} <i class="el-icon-copy-document" @click="copy(coinAddress)"></i></div> 
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin"  :column="1" size="medium" border>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token125')}}</template>
                  {{ buyTaxTotal }}% ({{$t('tokenPublic.token127')}}{{ buyFundFee }}% + {{$t('tokenPublic.token128')}}{{ buy_burnFee }}%)
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token126')}} </template>
                  {{ sellTaxTotal }}% ({{$t('tokenPublic.token127')}}{{ sellFundFee }}% + {{$t('tokenPublic.token128')}}{{ sell_burnFee }}%)
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token137')}} </template>
                  <div class="copy">{{ showAccount(fundAddress) }} <i class="el-icon-copy-document" @click="copy(fundAddress)"></i></div> 
                </el-descriptions-item>
                
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token244')}} </template>
                  {{ cooldownSec }} 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token250')}}</template>
                  {{ blockToUnlockLiquidity }} 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token258')}}</template>
                  {{ currentBlockNumber }} 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token140')}}</template>
                  {{ maxWalletAmount }}
                </el-descriptions-item>
              </el-descriptions>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7"  v-if="isShowControl">
              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token155')">
                  <el-button size="mini" @click="renounceOwnership()" :loading="isRenounceOwnership"> {{$t('tokenPublic.token156')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token162')">
                  <el-button size="mini" :disabled="blockToUnlockLiquidity !== 0" @click="isAddLiquidity= true"> {{$t('tokenPublic.token249')}}</el-button>
                  <el-button size="mini" :disabled="currentBlockNumber < blockToUnlockLiquidity || blockToUnlockLiquidity === 0" @click="removeLiquidity()" :loading="isRemoveLiquidity"> {{$t('tokenPublic.token253')}}</el-button>
                  <el-button size="mini" @click="isExtendLiquidityLock = true"> {{$t('tokenPublic.token254')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token165')">
                  <el-button size="mini" :disabled="!enableWalletLimit" @click="isChangeWalletLimit = true"> {{$t('tokenPublic.token255')}}</el-button>
                  <el-button size="mini" :disabled="!enableWalletLimit" @click="disableWalletLimit()" :loading="isDisableWalletLimit"> {{$t('tokenPublic.token256')}}</el-button>
                  <el-button size="mini" @click="isChangeCooldownSec = true"> {{$t('tokenPublic.token257')}}</el-button>
                  <el-button size="mini" @click="isSetFundAddress = true"> {{$t('tokenPublic.token174')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>

            </el-col>
          </el-row>
        </div>

        <el-dialog :title="$t('tokenPublic.token249')" :visible.sync="isAddLiquidity" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token258')}}:{{ currentBlockNumber }}</div>
          <div class="titleBottom">{{$t('tokenPublic.token259')}}:</div>
          <el-input v-model="blockToUnlockLiquidity" placeholder="0"></el-input>
          <div class="titleBottom">{{$t('tokenPublic.token260')}} {{ mainSymbol }} {{$t('tokenPublic.token200')}}:</div>
          <el-input v-model="addEthValue" placeholder="0" @change="getInitialPrice()"></el-input>
          <div class="titleBottom"><small>{{$t('tokenPublic.token261')}}:{{ initialPrice }} </small></div>
          <small>{{$t('tokenPublic.token262')}}</small>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isAddLiquidity = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="addLiquidity()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token254')" :visible.sync="isExtendLiquidityLock" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token258')}}:{{ currentBlockNumber }}</div>
          <div class="titleBottom">{{$t('tokenPublic.token263')}}:</div>
          <el-input v-model="blockToUnlockLiquidity" placeholder="0"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isExtendLiquidityLock = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="extendLiquidityLock()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token255')" :visible.sync="isChangeWalletLimit" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token255')}}:</div>
          <el-input v-model="maxWalletAmount" placeholder="0"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isChangeWalletLimit = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="changeWalletLimit()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token257')" :visible.sync="isChangeCooldownSec" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token257')}}:</div>
          <el-input v-model="cooldownSec" placeholder="0"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isChangeCooldownSec = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="changeCooldownSec()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token174')" :visible.sync="isSetFundAddress" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token194')}}:</div>
          <el-input v-model="newFundAddress" placeholder="0x..."></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetFundAddress = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setFundAddress()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

			</div>
		</div>
	</div>

</template>

<script>
import { ethers } from 'ethers'
import Navigation from '../../components/Navigation'
import { BigNumber , formatUnitsFive, parseUnits, decToHex} from '../../utils/utils.js'
import tokenABI from '../../abi/token/X314ABI.json'
import chainlistData from '../../abi/tokenChainList.json'
import Clipboard from "clipboard";
import { number } from 'bitcoinjs-lib/src/script'

export default {
	name: 'stardand',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '',
				},
			],
		}
	},

	data() {
		return {
      //链
      chainNum:JSON.parse(localStorage.getItem('chainNum')) || '0',
      chainlist: JSON.parse(localStorage.getItem('chainlist')) || chainlistData,
      exchanges:'',
      //代币
      mainSymbol:'',
      provider:{},
      signer:{},
      account:'',
      isLoading:false,
      //是否显示左侧控制
      isShowControl:false,

      stardandFactory:'',
      //代币基本信息
      coinAddress:null,
      coinName:'',
      coinSymbol:'',
      coinDecimals:'',
      coinTotalSupply:'',
      coinOwner:'',

      //经济模型
      buyFundFee:'',
      buy_burnFee:'',
      sellFundFee:'',
      sell_burnFee:'',

      fundAddress:'',
      cooldownSec:'',
      blockToUnlockLiquidity:'',
      maxWalletAmount:'',
      enableWalletLimit:false,
      contractBalance:'',

      //左侧控制
      isRenounceOwnership:false,
      //修改营销钱包
      isSetFundAddress:false,
      newFundAddress:'',
      //添加流动性
      isAddLiquidity:false,
      currentBlockNumber:'',
      addEthValue:'0',
      isRemoveLiquidity:false,
      isExtendLiquidityLock:false,
      initialPrice:'0',

      //交易控制
      isChangeWalletLimit:false,
      isDisableWalletLimit:false,
      isChangeCooldownSec:false,

		}
	},

	async created() {
    this.coinAddress = this.$route.params.coinAddress
    this.exchanges = this.chainlist[this.chainNum].exchanges
    this.mainSymbol = this.chainlist[this.chainNum].nativeCurrency.symbol
    this.stardandFactory = this.chainlist[this.chainNum].stardandFactory
    // console.log(decToHex(97))
	},
	computed: {
		title() {
			return this.$t('title.X314')
		},
    lgCols() {  
      return this.isShowControl ? 17 : 24;  
    },  
    xlCols() {  
      return this.isShowControl ? 17 : 24;  
    },
    buyTaxTotal() {
      const buyTotal = Number(this.buyFundFee) + Number(this.buy_burnFee)
			return buyTotal
		},
    sellTaxTotal() {
      const sellTotal = Number(this.sellFundFee) + Number(this.sell_burnFee)
			return sellTotal
		},
	},
  async mounted() {
    await this.initAccount()
    if (this.chainNum === `${this.$t("tokenPublic.token134")}`) {
      this.chainLogo = './img/chainIcon/not.png'
      return
    }
    this.getCurrentBlockNumber()
    this.intervalId = setInterval(this.getCurrentBlockNumber, 5000); 
    this.allEnableSwitch()
    this.getTokenDetail()
    this.getEconomicDetail()
  },
  beforeDestroy() {  
    clearInterval(this.intervalId);  
  },
	methods: {
    //税率
    async allEnableSwitch() {
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        this.coinOwner = await tokenContract.owner()
        if (this.coinOwner === this.account) {
          this.isShowControl = true
        }
        this.cooldownSec = await tokenContract.cooldownSec()
        this.blockToUnlockLiquidity = await tokenContract.blockToUnlockLiquidity()
        const maxWalletAmountPe = await tokenContract.maxWalletAmount()
        this.maxWalletAmount = formatUnitsFive(maxWalletAmountPe, '18')
        this.enableWalletLimit = await tokenContract.enableWalletLimit()
        if (!this.enableWalletLimit) {
          this.maxWalletAmount =  `${this.$t("tokenPublic.token264")}`
        }
        const contractBalance = await tokenContract.balanceOf(this.coinAddress)
        this.contractBalance = contractBalance.toString()
      } catch (error) {
        console.log("EconomicDetail:"+error.code);
      }
    },
    //获取当前区块
    async getCurrentBlockNumber() {
      try {
        const blockNumber = await this.provider.getBlockNumber()
        this.currentBlockNumber = blockNumber.toString()
      } catch (error) {
        console.log("CurrentBlockNumber:" + error.code);
      }
    },
    //基本信息
    async getTokenDetail() {
      if (!this.coinAddress) {
        console.log("无法获取到代币地址");
        return
      }
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        this.coinName = await tokenContract.name()
        this.coinSymbol = await tokenContract.symbol()
        const decimals = await tokenContract.decimals()
        this.coinDecimals = BigNumber(decimals).toString()
        const totalSupply = await tokenContract.totalSupply()
        this.coinTotalSupply = formatUnitsFive(totalSupply, this.coinDecimals)

        this.fundAddress = await tokenContract.fundAddress()

      } catch (error) {
        console.log("TokenDetail:"+error.code);
      }
    },
    //税率
    async getEconomicDetail() {
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        let buyFundFeePe = await tokenContract._buyFundFee()
        this.buyFundFee = buyFundFeePe.div('100').toString()
        let buy_burnFeePe = await tokenContract.buy_burnFee()
        this.buy_burnFee = buy_burnFeePe.div('100').toString()
        let sellFundFeePe = await tokenContract._sellFundFee()
        this.sellFundFee = sellFundFeePe.div('100').toString()
        let sell_burnFeePe = await tokenContract.sell_burnFee()
        this.sell_burnFee = sell_burnFeePe.div('100').toString()
      } catch (error) {
        console.log("EconomicDetail:" + error.code);
      }
    },
    //获取预计价格
    getInitialPrice() {
      const contractBalance  = formatUnitsFive(this.contractBalance,"18")
      console.log(contractBalance);
      const initialPriceBig = Number(this.addEthValue) / Number(contractBalance)
      this.initialPrice = Number(initialPriceBig).toFixed(12)
		},
    //renounceOwnership
    async renounceOwnership() {
      try {
        this.isRenounceOwnership = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.renounceOwnership()
        await receipt.wait()
        this.$message.success('success')
        this.isRenounceOwnership = false
      } catch (error) {
        this.$message.error(error.code)
        this.isRenounceOwnership = false
        return
      }
    },

    //addLiquidity
    async addLiquidity() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const addEthValue = parseUnits(this.addEthValue.toString(),"18")
        const txParams = { value:BigNumber(addEthValue) }

        const receipt  = await tokenContract.addLiquidity(this.blockToUnlockLiquidity,txParams)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //removeLiquidity
    async removeLiquidity() {
      try {
        this.isRemoveLiquidity = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.removeLiquidity()
        await receipt.wait()
        this.$message.success('success')
        this.isRemoveLiquidity = false
      } catch (error) {
        this.$message.error(error.code)
        this.isRemoveLiquidity = false
        return
      }
    },
    //extendLiquidityLock
    async extendLiquidityLock() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.extendLiquidityLock(this.blockToUnlockLiquidity)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //changeWalletLimit
    async changeWalletLimit() {
      const maxWalletAmount = parseUnits(this.maxWalletAmount.toString(),"18")
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.changeWalletLimit(maxWalletAmount)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //disableWalletLimit
    async disableWalletLimit() {
      try {
        this.isDisableWalletLimit = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.disableWalletLimit()
        await receipt.wait()
        this.$message.success('success')
        this.isDisableWalletLimit = false
      } catch (error) {
        this.$message.error(error.code)
        this.isDisableWalletLimit = false
        return
      }
    },
    //changeCooldownSec
    async changeCooldownSec() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.changeCooldownSec(this.cooldownSec)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setFundAddress
    async setFundAddress() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setFundAddress(this.newFundAddress)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },

    // ----------初始化账户-------------
		async initAccount() {
			if (window.ethereum) {
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          //获取链ID
          const chainId = await window.ethereum.request({ method: 'eth_chainId' })
          const chainIdB = Number(BigNumber(chainId).toString())
          const selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
          if (!selectedChain) {
            this.chainNum = `${this.$t("tokenPublic.token134")}`
            return;
          }
          this.chainNum = selectedChain.id;
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
          });
				} catch (switchError) {
          if (switchError.code === 4902) {
            this.$message.error(switchError.message)
            try {
              await window.ethereum.request({
                "method": 'wallet_addEthereumChain',
                "params": [
                  {
                    "chainId": decToHex(Number(this.chainlist[this.chainNum].chainId)),
                    "chainName": this.chainlist[this.chainNum].chainName,
                    "rpcUrls": this.chainlist[this.chainNum].rpcUrls,
                    "nativeCurrency": {
                      "name": this.chainlist[this.chainNum].nativeCurrency.name,
                      "symbol": this.chainlist[this.chainNum].nativeCurrency.symbol,
                      "decimals": this.chainlist[this.chainNum].nativeCurrency.decimals
                    },
				            "blockExplorerUrls":this.chainlist[this.chainNum].blockExplorerUrls
                  },
                ],
              });
            } catch (addError) {
              this.$message.error(addError.code)
            }
          }
					// User denied account access
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
			// Verify Accounts!
		},
    showAccount(str) {
      if (str) {
          const account = String(str);
          return account.substring(0, 4) + "..." + account.substring(account.length - 4, account.length);
      }
      return "";
    },
    //复制
    copy(text) {
      const clipboard = new Clipboard('.copy', {
          text: () => {
              return text
          },
      })
      clipboard.on('success', () => {
          this.$message.success(this.$t('pubilc.copySauccessfully'))
          clipboard.destroy()
      })
      clipboard.on('error', () => {
          this.$message.error(this.$t('pubilc.copyFailed'))
          clipboard.destroy()
      })
    },

	},
  watch:{
    //监听 chainNum 本地持久化
    chainNum:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainNum',JSON.stringify(newVal))
      }
    },
    chainlist:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainlist',JSON.stringify(newVal))
      }
    }
  },
}
</script>

<style scoped>
.detailMode {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 1000px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}

.senderMain {
  padding: 5px 30px 30px 30px;
  height: auto;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .title{
  font-size: 22px;
  margin: 20px;
  text-align: center;
}
.senderMain .bottomMargin{
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
.titleBottom{
  margin: 10px 0;
}
.xBottom{
  margin: 3px 0;
}
.senderMain .bottomMargin button{
  margin-bottom: 10px;
  margin-left: 0px;
  width: 100%;
}

::v-deep .el-dialog__header{
  border-bottom: 1px solid #eee;
}
::v-deep .el-dialog__body{
  padding: 15px 20px !important;
}
::v-deep .el-form-item__content{
  margin-bottom: 10px;
}

@media (max-width:768px){
  ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
  }
</style>