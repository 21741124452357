<template>
  <div class="addressView">
    <Navigation></Navigation>

    <div class="swapMain">
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="8" :lg="7" :xl="6">
        <div class="swapContainer">
          <div class="swapContainerTop">
            <div class="setSwap">
              <div class="setSwapText"> {{$t('batchTools.pubilc.num41')}} </div>
              <div class="setSwapBn">
                <i v-show ="token0Amount !== ''" class="el-icon-refresh-right" @click="formInputAmount"></i>
                <!-- <i class="el-icon-setting" @click="isSettings = true"></i> -->
                <i class="el-icon-s-operation" @click="isSettings = true"></i>
              </div>
            </div>
          </div>
          <div class="swapSelect">
            <div class="sscLeft"> {{$t('batchTools.pubilc.num12')}}  : </div>
            <div class="sscRight">
              <el-select :default-first-option="false" v-model="chainID" @change="selectChainID">
                <el-option v-for="(item, index) in chainArr" :key="index" :label="item.chainName" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="swapSelect">
            <div class="sscLeft"> {{$t('batchTools.pubilc.num62')}}  : </div>
            <div class="sscRight">
              <el-select :default-first-option="false" v-model="exchangeID" @change="setExchange">
                <el-option v-for="(item, index) in exchangeArr" :key="index" :label="item.name" :value="item.id">
                  <span style="float: left; margin-right: 5px;">{{ item.name }}</span>
                  <span style="float: right;" v-if="item.name !== token1Symbol"><i class="el-icon-delete" @click="deleteExchange(item.id)"></i></span>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="swapContainerTokenOne">
            <div class="tokenAmount">
              <el-input :class="{isDisabled:isCustomQuantity}" v-model="token0Amount" placeholder="0.0" clearable  @input="formInputAmount()"></el-input>
            </div>
            <div class="tokenSelect" @click="isToken0 = true">
              <div class="tokenL">
                <div class="tokenlogoURI"><img :src="token0LogoURI"></div>
                <div class="tokenSymbol">{{token0Symbol}}</div>
                <div class="el-icon-caret-bottom"></div>
              </div>
            </div>
          </div>
          <div class="exchangeBn" @click="turnAround()">
            <div class="exchangeBn-bj" @mouseover="isActive = true" @mouseout="isActive = false">
              <i class="el-icon-sort" v-if="isActive" title="刷新价格"></i>
              <i class="el-icon-bottom" v-else></i>
            </div>
          </div>
          <div class="swapContainerTokenOne">
            <div class="tokenAmount">
              <el-input :class="{isDisabled:isCustomQuantity}" v-model="token1Amount" placeholder="0.0" clearable @input="toInputAmount()"></el-input>
            </div>
            <div class="tokenSelect" @click="isToken1 = true">
              <div class="tokenL">
                <div class="tokenlogoURI"><img :src="token1LogoURI"></div>
                <div class="tokenSymbol">{{token1Symbol}}</div>
                <div class="el-icon-caret-bottom"></div>
              </div>
            </div>
          </div>
          <div class="swapContainerBn">
            <div v-if="token0Symbol === mainSymbol || token0Symbol === 'W'+ mainSymbol">
              <el-button type="success" @click="batchSwap()" :loading="isLoading"> {{$t('batchTools.pubilc.num52')}} </el-button>
            </div>
            <div v-else-if="isAllApprove === false">
              <el-button type="warning" @click="batchApproveToken0()" :loading="isLoading"> {{$t('batchTools.pubilc.num74')}}  {{ token0Symbol }} </el-button>
            </div>
            <div v-else-if="isAllApprove === true">
              <el-button type="success" @click="batchSwap()" :loading="isLoading"> {{$t('batchTools.pubilc.num52')}}  {{ token1Symbol }} </el-button>
            </div>
          </div>
          <div class="swapContainerBottom">
            <div class="slippageText"> {{$t('batchTools.pubilc.num48')}} : <span>{{ gagSGH }} %</span></div>
            <div class="routeText"> {{$t('batchTools.pubilc.num53')}} : <span>{{ token0Symbol }} > {{ token1Symbol }}</span></div>
            <div class="obtainText"> {{$t('batchTools.pubilc.num54')}} : <span>{{ amountOutMin }} {{ token1Symbol }}</span></div>
          </div>
        </div>
        </el-col>
        
      
        <el-col :xs="24" :sm="24" :md="16" :lg="17" :xl="18">
          <!-- <div id="dexscreener-embed"><iframe src="https://dexscreener.com/bsc/0x63230caeFc0F8220536dB18136b83B5098B5ACBc?embed=1&theme=dark&trades=0&info=0"></iframe></div> -->

        <div class=" selectDelete">
          <el-button size="small" type="success" @click="isAddPrivatekey = true" icon="el-icon-upload2">  {{$t('batchTools.pubilc.num1')}} </el-button>
          <el-button size="small" type="primary" @click="isAddChain = true" icon="el-icon-plus"> {{$t('batchTools.pubilc.num4')}} </el-button>
          <el-button size="small" type="warning" @click="isAddExchange = true" icon="el-icon-plus"> {{$t('batchTools.pubilc.num61')}} </el-button>
          <el-button size="small" type="primary" @click="batchQueryETHBalance()"> {{$t('pubilc.btnInquire')}}  {{ mainSymbol }}  {{$t('batchTools.pubilc.num9')}} </el-button>
          <el-button size="small" type="primary" @click="batchQueryBalance()" v-if="token0Symbol !== mainSymbol"> {{$t('pubilc.btnInquire')}}  {{ token0Symbol }}  {{$t('batchTools.pubilc.num9')}} </el-button>
          <el-button size="small" type="info" @click="deleteCache()" icon="el-icon-delete" plain> {{$t('batchTools.pubilc.num5')}} </el-button>
        
        

          <el-table :data="walletArr" class="onTable" key="table1" border ref="selectedTable" @selection-change="handleSelectionChange" :reserve-selection="true">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="ID"  width="60" type="index">
              <!-- <template type="index"></template> -->
            </el-table-column>
            <el-table-column :label="$t('batchTools.pubilc.num6')" width="390">
              <template slot-scope="scope">{{ scope.row.walletAddr }}</template>
            </el-table-column>
            <el-table-column :label="mainSymbol">
              <template slot-scope="scope"> {{ formatUnitsFive(scope.row.ethBalance,'18') }}</template>
            </el-table-column>

            <div v-if="token0Symbol !== mainSymbol">
              <el-table-column :label="token0Symbol" width="150">
                <template slot-scope="scope"> {{ formatUnitsFive(scope.row.tokenBalance, token0Decimals) }}</template>
              </el-table-column>

              <el-table-column :label="$t('batchTools.transfer.name25')" width="135">
                <div slot-scope="scope" v-if="scope.row.isApprove === '1'">
                  <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num75')}} </el-button>
                </div>
                <div slot-scope="scope" v-else-if="scope.row.isApprove === '2'">
                  <a :href="scope.row.approveTx" target="_blank" rel="noopener noreferrer">
                    <el-button size="mini" type="success" plain> {{$t('batchTools.pubilc.num76')}} </el-button>
                  </a>
                </div>
                <div slot-scope="scope" v-else-if="scope.row.isApprove === '3'">
                  <el-button size="mini" type="danger" plain disabled> {{$t('batchTools.pubilc.num69')}} </el-button>
                </div>
                <div slot-scope="scope" v-else-if="scope.row.isApprove === '4'">
                  <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num70')}} </el-button>
                </div>
                <div v-else>
                  <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num71')}} </el-button>
                </div>
              </el-table-column>
            </div>

            <el-table-column :label="$t('batchTools.pubilc.num26')" width="135">
              <div slot-scope="scope" v-if="scope.row.txResult === '1'">
                <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num67')}} </el-button>
              </div>
              <div slot-scope="scope" v-else-if="scope.row.txResult === '2'">
                <a :href="scope.row.swapTx" target="_blank" rel="noopener noreferrer">
                  <el-button size="mini" type="success" plain> {{$t('batchTools.pubilc.num68')}} </el-button>
                </a>
              </div>
              <div slot-scope="scope" v-else-if="scope.row.txResult === '3'">
                <el-button size="mini" type="danger" plain disabled> {{$t('batchTools.pubilc.num69')}} </el-button>
              </div>
              <div slot-scope="scope" v-else-if="scope.row.txResult === '4'">
                <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num70')}} </el-button>
              </div>
              <div v-else>
                <el-button size="mini" type="info" plain disabled> {{$t('batchTools.pubilc.num71')}} </el-button>
              </div>
            </el-table-column>

            <el-table-column :label="$t('batchTools.pubilc.num7')">
              <template slot-scope="{row}"><el-button size="mini" type="danger" icon="el-icon-delete" @click="setDelete(row.id)" circle></el-button></template>
            </el-table-column>
            <div slot="empty">
              <p> {{$t('batchTools.pubilc.num27')}} </p>
            </div>
          </el-table>

          <div class="bnzu">
            <el-row :gutter="10">
              <el-button size="small" type="primary" @click="setSelectEHT0()" plain> {{$t('batchTools.pubilc.num8')}}  {{ mainSymbol}} 0  {{$t('batchTools.pubilc.num9')}} </el-button>
              <el-button size="small" type="primary" v-if="mainSymbol !== token0Symbol" @click="setSelectToken0()" plain> {{$t('batchTools.pubilc.num8')}}  {{ token0Symbol }} 0  {{$t('batchTools.pubilc.num9')}} </el-button>
              <el-button size="small" type="primary" @click="setCheckError()" icon="el-icon-delete" plain> {{$t('batchTools.pubilc.num29')}} </el-button>
              <el-button size="small" type="danger" @click="setCheckArr()" icon="el-icon-delete" plain> {{$t('batchTools.pubilc.num10')}} </el-button>
              <el-button size="small" type="danger" @click="setDeleteArr()" icon="el-icon-delete" plain> {{$t('batchTools.pubilc.num11')}} </el-button>
            </el-row>
          </div>
        </div>
        </el-col>
          <!-- 导入私钥弹窗 -->
          <el-dialog :title="$t('batchTools.pubilc.num1')" :visible.sync="isAddPrivatekey">
            <el-input type="textarea" :rows="10" :placeholder="$t('batchTools.pubilc.num40')" v-model="privateKeys"></el-input>
            <div slot="footer" class="dialog-footer">
              <el-button @click="isAddPrivatekey = false"> {{$t('pubilc.cancel')}} </el-button>
              <el-button type="primary" @click="inputKey"> {{$t('pubilc.sure')}} </el-button>
            </div>
          </el-dialog>

          <!-- 设置token0 弹窗 -->
          <el-dialog :title="$t('batchTools.pubilc.num14')" width="30%" :visible.sync="isToken0">
            <el-row :gutter="20">
              <el-col :xs="24">
                <el-input :placeholder="$t('batchTools.pubilc.num77')" @input="addTokenModal" prefix-icon="el-icon-search" v-model="tokenContractAddr" clearable></el-input>
                <el-divider></el-divider>
              </el-col>
              <el-col :xs="24" class="tokenListMain">
                <div v-if="tokenArr.length === 0">
                  <div class="noneTokenLeft" v-if="isLoading">
                    <i class="el-icon-loading"></i>
                  </div>
                  <div class="noneTokenLeft" v-else>
                    {{$t('batchTools.pubilc.num78')}} 
                  </div>
                </div>
                <div v-else v-for="( item,index ) in tokenArr" :key="index">
                  <div class="allTokenList" :class="{isDisabled: item.tokenSymbol === token1Symbol}" @click="setCommon0Data(index)">
                    <div class="allTokenLeft">
                      <div class="tokenL">
                        <img :src="item.tokenLogo">
                      </div>
                      <div class="tokenR">
                        <div class="tokenSymbol">{{item.tokenSymbol}}( <span> {{item.tokenName}} </span> )</div>
                        <div class="tokenContract">
                          {{item.tokenContract}}<i class="el-icon-copy-document" @click="copy(item.tokenContract,'复制成功','.tokenContract')"></i>
                          <i v-if="item.tokenSymbol !== token1Symbol && item.tokenSymbol !== token0Symbol && item.tokenSymbol !== mainSymbol" class="el-icon-delete" @click="deleteItem(item.tokenId)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-dialog>

          <!-- 设置token1 弹窗 -->
          <el-dialog :title="$t('batchTools.pubilc.num14')" width="30%" :visible.sync="isToken1">
            <el-row :gutter="20">
              <el-col :xs="24">
                <el-input :placeholder="$t('batchTools.pubilc.num77')" @input="addTokenModal" prefix-icon="el-icon-search" v-model="tokenContractAddr" clearable></el-input>
                <el-divider></el-divider>
              </el-col>
              <el-col :xs="24" class="tokenListMain">
                <div v-if="tokenArr.length === 0">
                  <div class="noneTokenLeft" v-if="isLoading">
                    <i class="el-icon-loading"></i>
                  </div>
                  <div class="noneTokenLeft" v-else>
                    {{$t('batchTools.pubilc.num78')}} 
                  </div>
                </div>
                <div v-else v-for="( item,index ) in tokenArr" :key="index">
                  <div class="allTokenList" :class="{isDisabled: item.tokenSymbol === token0Symbol}" @click="setCommon1Data(index)">
                    <div class="allTokenLeft">
                      <div class="tokenL">
                        <img :src="item.tokenLogo">
                      </div>
                      <div class="tokenR">
                        <div class="tokenSymbol">{{item.tokenSymbol}}( <span> {{item.tokenName}} </span> )</div>
                        <div class="tokenContract">
                          {{item.tokenContract}}<i class="el-icon-copy-document" @click="copy(item.tokenContract,'复制成功','.tokenContract')"></i>
                          <i v-if="item.tokenSymbol !== token1Symbol && item.tokenSymbol !== token0Symbol && item.tokenSymbol !== mainSymbol" class="el-icon-delete" @click="deleteItem(item.tokenId)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-dialog>
          <!-- TOKEN管理 弹窗 -->
          <el-dialog :title="$t('batchTools.pubilc.num42')" width="30%" :visible.sync="isTokenManage">
            <el-row :gutter="20">
              <el-col :xs="24" class="tokenListMain">
                <div v-for="( item,index ) in tokenArr" :key="index">
                  <div class="allTokenList" :class="{isDisabled: item.tokenSymbol === token1Symbol || item.tokenSymbol === token0Symbol}">
                    <div class="allTokenLeft">
                      <div class="tokenL">
                        <img :src="item.tokenLogo">
                      </div>
                      <div class="tokenR">
                        <div class="tokenSymbol">{{item.tokenSymbol}}( <span> {{item.tokenName}} </span> )</div>
                        <div class="tokenContract">
                          {{item.tokenContract}}
                          <i v-if="item.tokenSymbol !== token1Symbol && item.tokenSymbol !== token0Symbol && item.tokenSymbol !== mainSymbol" class="el-icon-delete" @click="deleteItem(item.tokenId)"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-dialog>
          <!-- 设置 弹窗 -->
          <el-dialog title="设置" width="30%" :visible.sync="isSettings">
            <el-row :gutter="20">
              <el-col :span="24">
                <div class="sub-title gagSGH">Gwei</div>
                <div class="demo-input-suffix">
                  <el-radio-group v-model="gasPriceNum">
                    <el-radio-button :label="gasPriceNumK"> {{$t('batchTools.pubilc.num44')}} ({{ gasPriceNumK }})</el-radio-button>
                    <el-radio-button :label="gasPriceNumB"> {{$t('batchTools.pubilc.num45')}} ({{ gasPriceNumB }})</el-radio-button>
                    <el-radio-button :label="gasPriceNumM"> {{$t('batchTools.pubilc.num46')}} ({{ gasPriceNumM }})</el-radio-button>
                    <div class="gagSGHInput">
                      <el-input v-model="gasPriceNum" label="Price" placeholder="0">
                        <template slot="append">Gwei</template>
                      </el-input>
                    </div>
                  </el-radio-group>
                </div>
              </el-col>
              <el-col :span="24">
                <div class="sub-title"><el-switch v-model="isGasLimit" :inactive-text="$t('batchTools.pubilc.num47')" active-color="#409EFF"></el-switch></div>
                <div v-if="!isGasLimit">
                  <el-input v-model="gasLimit" @change="gasLimitFn" :step="1000" label="Limit" placeholder="0">
                    <template slot="append">Gas Fee {{ gasFee }}</template>
                  </el-input>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="sub-title gagSGH"> {{$t('batchTools.pubilc.num48')}} </div>
                <div class="demo-input-suffix">
                  <el-radio-group v-model="gagSGH">
                    <el-radio-button label="1">1%</el-radio-button>
                    <el-radio-button label="10">10%</el-radio-button>
                    <el-radio-button label="20">20%</el-radio-button>
                    <div class="gagSGHInput">
                      <el-input v-model="gagSGH" label="Fee" placeholder="0">
                        <template slot="append">%</template>
                      </el-input>
                    </div>
                  </el-radio-group>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="sub-title"><el-switch v-model="isCustomQuantity" :inactive-text="$t('batchTools.pubilc.num49')" active-color="#409EFF"></el-switch></div>
                <div v-if="isCustomQuantity">
                  <div class="demo-input-suffix">
                  <el-radio-group v-model="customPercentage">
                    <el-radio-button label="25">25%</el-radio-button>
                    <el-radio-button label="50">50%</el-radio-button>
                    <el-radio-button label="75">75%</el-radio-button>
                    <el-radio-button label="100">100%</el-radio-button>
                    <div class="gagSGHInput">
                      <el-input v-model="customPercentage" label="Fee" placeholder="0">
                        <template slot="append">%</template>
                      </el-input>
                    </div>
                  </el-radio-group>
                </div>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="sub-title"><el-switch v-model="isCustomAcceptAddress" :inactive-text="$t('batchTools.pubilc.num50')" active-color="#409EFF"></el-switch></div>
                <div v-if="isCustomAcceptAddress">
                  <el-input v-model="customAcceptAddress" label="Address" placeholder="0x"></el-input>
                </div>
              </el-col>
              
              <el-col :span="24">
                <div class="sub-title"><el-switch v-model="isModifyRPC" :inactive-text="$t('batchTools.pubilc.num51')" active-color="#409EFF"></el-switch></div>
                <div v-if="isModifyRPC">
                  <el-input v-model="setRpcUrl" label="Address" @blur="setRpcUrlInp()" placeholder="https://"></el-input>
                </div>
              </el-col>

              <el-col :span="24">
                <div class="sub-title">
                  <el-switch
                    v-model="isParallel"
                    active-text="串行执行"
                    inactive-text="并行执行">
                  </el-switch>
                </div>
              </el-col>

            </el-row>
          </el-dialog>

          <!-- 自定义链 弹窗 -->
          <el-dialog :title="$t('batchTools.pubilc.num4')" :visible.sync="isAddChain" width="30%">
            <el-row :gutter="20">
              <el-col :xs="24">
                <div class="sub-title"> {{$t('batchTools.pubilc.num55')}} </div>
                <el-input placeholder="Ethereum Mainnet" v-model="addChainNmae"></el-input>
              </el-col>
              <el-col :xs="24">
                <div class="sub-title"> {{$t('batchTools.pubilc.num56')}} </div>
                <el-input placeholder="ETH" v-model="addChainSymbol"></el-input>
              </el-col>
              <el-col :xs="24">
                <div class="sub-title"> {{$t('batchTools.pubilc.num58')}} </div>
                <el-input placeholder="1" v-model="addChainId"></el-input>
              </el-col>
              <el-col :xs="24">
                <div class="sub-title"> {{$t('batchTools.pubilc.num59')}} </div>
                <el-input placeholder="https://eth.drpc.org" v-model="addChainRpc"></el-input>
              </el-col>
              <el-col :xs="24">
                <div class="sub-title"> {{$t('batchTools.pubilc.num60')}} </div>
                <el-input placeholder="https://etherscan.io" v-model="addchainUrl"></el-input>
              </el-col>
              <el-col :xs="24">
                <div class="sub-title">W{{ mainSymbol }} {{$t('batchTools.pubilc.num64')}} </div>
                <el-input placeholder="0x" v-model="addWETHAddr"></el-input>
              </el-col>
              <el-col :xs="24">
                <div class="sub-title">W{{ mainSymbol }} LOGO Url</div>
                <el-input placeholder="https://" v-model="addWETHUrl"></el-input>
              </el-col>
            </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button @click="isAddChain = false"> {{$t('pubilc.cancel')}} </el-button>
            <el-button type="primary" @click="addChainModal"> {{$t('pubilc.sure')}} </el-button>
          </div>
          </el-dialog>


          <!-- 添加交易所 弹窗 -->
          <el-dialog :title="$t('batchTools.pubilc.num61')" :visible.sync="isAddExchange" width="30%">
            <el-row :gutter="20">
              <el-col :xs="24">
                <div class="sub-title"> {{$t('batchTools.pubilc.num63')}} </div>
                <el-input placeholder="PancakeSwap" v-model="addExchangeName"></el-input>
              </el-col>
              <el-col :xs="24">
                <div class="sub-title"> {{$t('batchTools.pubilc.num79')}} </div>
                <el-input placeholder="0x" v-model="addExchangeRouterAddr"></el-input>
              </el-col>
            </el-row>
          <div slot="footer" class="dialog-footer">
            <el-button @click="isAddExchange = false"> {{$t('pubilc.cancel')}} </el-button>
            <el-button type="primary" @click="addExchangeModal"> {{$t('pubilc.sure')}} </el-button>
          </div>
          </el-dialog>

        
      </el-row>
    </div>

    
  </div>



</template>

<script>
import Navigation from "../components/Navigation"
import { ethers } from "ethers"
import ercABI from '../abi/ERC20.json'
import routerABI from '../abi/swapRouterABI.json'
import { BigNumber, formatUnits, parseUnits, MAX_INT, isNormalNonZeroNumber, formatUnitsFive} from '../utils/utils.js'
import chainArr from '../abi/swapToken.json'
import Clipboard from 'clipboard'

export default {
  name: "addresAndEMS",
  components: {
    Navigation,
  },
  metaInfo() {
    return {
      title: "WuHaXi- " + this.title,

      meta: [
        {
          name: "keyword",
          content: "科学家抢币",
        },
      ],
    };
  },
  data() {
    return {
      //加载
      load: false,
      address:'',
      isLoading:false,

      isAddPrivatekey: false,//私钥弹窗显示开关
      privateKeys:'',
      walletArr: JSON.parse(localStorage.getItem('walletArr')) || [],
      chainArr:JSON.parse(localStorage.getItem('chainArr')) || chainArr,
      mainSymbol:'',
      chainID:JSON.parse(localStorage.getItem('chainID')) || '0',
      tokenArr:[],
      tokenID:'0',
      setRpcUrl:'',

      gasPriceNumK:'',
      gasPriceNumB:'',
      gasPriceNum:'',
      gasPriceNumM:'',
      gasLimit:'700000',
      gasFee:'',

      provider:{},
      selectedTable:[],

      tokenContractAddr:'',

      dddId:1,

      tokens: [],
      token0Amount:'',
      token0LogoURI:'',
      token0Symbol:'',
      token0Name:'',
      token0Address:'',
      token0Decimals:'',
      isToken0: false,//添加Token0 弹窗显示开关

      token1Amount:'',
      token1LogoURI:'',
      token1Symbol:'',
      token1Name:'',
      token1Address:'',
      token1Decimals:'',
      isToken1: false,//添加Token1 弹窗显示开关
      isTokenManage: false,//TOKEN管理 弹窗显示开关
      amountOutMin:'',

      isSettings: false,//设置 弹窗显示开关
      gagSGH:'20',

      isAddExchange: false,//添加交易所 弹窗显示开关
      exchangeArr: [],
      exchangeID:'0',
      routerContractAddr:'',
      addExchangeRouterAddr:'',
      addExchangeName:'',

      isAddChain: false,//添加链 弹窗显示开关
      addChainNmae:'',
      addChainSymbol:'',
      addChainId:'',
      addChainRpc:'',
      addchainUrl:'',
      addWETHAddr:'',
      addWETHUrl:'',

      isAllApprove:false,

      currentPath: [],
      isGasLimit:true,
      isActive:false,
      isCustomQuantity:false,
      customPercentage:'50',
      isCustomAcceptAddress:false,
      customAcceptAddress:'',
      isModifyRPC:false,
      isParallel:false,
    };
  },
  
  computed:{
    title(){
      return this.$t("title.autoTrade")
    },
    //如果token0Amount 不等于 空  每10秒执行一次
    updateFormInputAmount() {  
      const intervalId = setInterval(() => {  
        if (this.token0Amount !== '') {  
          this.formInputAmount()
        }else{
          this.token1Amount = ''
        }
      }, 10000); // 每10秒执行一次  
      return intervalId
    }
  },
  created() {
    
  },

  async mounted(){
    this.selectChainID()
    this.updateFormInputAmount
  },
  beforeDestroy() {  
    // 在组件销毁前清除定时器，以避免内存泄漏
    clearInterval(this.updateFormInputAmount)
  },
  methods: {
    formatUnitsFive,
    gasLimitFn(value){
      this.gasFeeNum(this.gasPriceNum,value)
    },
    //选择修改链
    selectChainID(){
      let rpcurl = this.chainArr[this.chainID].chainRpc
      this.setRpcUrl = rpcurl
      this.provider = new ethers.providers.JsonRpcProvider(rpcurl)
      this.exchangeID = '0'
      this.tokenArr = this.chainArr[this.chainID].tokenData
      this.mainSymbol = this.chainArr[this.chainID].chainCoin
      this.tokenContractAddr = ''
      for (let i = 0; i < this.walletArr.length; i++) {
        this.walletArr[i].txResult = '1'
        this.walletArr[i].isApprove = '1'
        this.walletArr[i].tokenBalance = '0'
        this.walletArr[i].ethBalance = '0'
      }
      this.getGasPriceFn()
      this.setExchange()

      this.tokenArr = this.chainArr[this.chainID].tokenData

      // let ddfdsf =BigNumber('0x0a3199').toString()
    },
    setExchange(){//选择交易所
      this.exchangeArr = this.chainArr[this.chainID].exchanges
      if (this.chainArr[this.chainID].exchanges.length === 0) {
        this.isAddExchange = true
        return
      }else{
        this.setCommon0Data(0)
        if (this.tokenArr.length  <= 1) {
          this.setCommon1Data(0)
        }else{
          this.setCommon1Data(1)
        }
        
      }
      this.batchQueryBalance()
      this.routerContractAddr = this.chainArr[this.chainID].exchanges[this.exchangeID].routerContractAddr
    },
    setRpcUrlInp(){//修改rpc
      // this.setRpcUrl = this.chainArr[this.chainID].chainRpc
      this.chainArr[this.chainID].chainRpc = this.setRpcUrl
      // this.setRpcUrl = this.chainArr[this.chainID].chainRpc
      console.log(this.setRpcUrl);
      this.selectChainID()
    },
    async getGasPriceFn(){//返回到网页
      let gas = await this.provider.getGasPrice()
      gas = Math.ceil(formatUnits(gas, "gwei"))
      this.gasPriceNumK = new BigNumber(gas).add('1').toString()
      this.gasPriceNumB = gas
      this.gasPriceNum = gas
      this.gasPriceNumM = new BigNumber(gas).sub('1').toString()
      this.gasFeeNum(gas,this.gasLimit)
    },
    gasFeeNum(gas,gasLimit){//返回到网页
      let gasFee = new BigNumber(gas).mul(BigNumber(gasLimit)).toString()
      this.gasFee = formatUnits(gasFee, "gwei")
    },
    //导入私钥
    async inputKey() {
      this.walletArr = []
      let newPrivateKey = this.privateKeys.split('\n')
      let id = 1
      for (let i = 0; i < newPrivateKey.length; i++) {
        var keyStr = newPrivateKey[i]
        if (keyStr.slice(0,2) !== '0x') {
          keyStr = '0x' + keyStr
        }
        if (keyStr == '' || keyStr.length !== 66 ) {
            return
        }
        let accountArr = new ethers.Wallet(keyStr, this.provider)
            accountArr = accountArr.address
        // walletArr 数组增加对象
        this.walletArr.push({
            id:id+i,
            walletKey:keyStr,
            walletAddr:accountArr, 
            ethBalance: '0',
            tokenBalance: '0', 
            txResult: '1',
            swapTx:'',
            isApprove:'1',
            approveTx:'',
            sendAmount:'0',
        });
      }
      this.isAddPrivatekey = false
      this.isAllApprove = false
      this.privateKeys = ''
      this.batchQueryBalance()
    },
    //批量查询余额
    batchQueryBalance(){
      let walletArr = this.walletArr
      let tokenCt = new ethers.Contract(this.token0Address, ercABI, this.provider)
      for (let i = 0; i < this.walletArr.length; i++) {
        if (this.token0Symbol === this.mainSymbol) {
          this.checkBalanceETH(walletArr[i],i)
        } else {
          this.checkBalanceToken(walletArr[i],i,tokenCt)
        }
      }
    },
    batchQueryETHBalance(){
      let walletArr = this.walletArr
      for (let i = 0; i < this.walletArr.length; i++) {
        this.checkBalanceETH(walletArr[i],i)
      }
    },
    async checkBalanceETH(wallet,id){ //查ETH余额
      try {
        let mainTokenNum = await this.provider.getBalance(wallet.walletAddr)
        this.walletArr[id].ethBalance = mainTokenNum.toString()
      } catch (error) {
        this.$message.error(`${this.$t('batchTools.pubilc.num57')}:${error.code}`)
      }
    },
    async checkBalanceToken(wallet,id,tokenCt){  //查ERC20余额
      try {
        let tokenBa = await tokenCt.balanceOf(wallet.walletAddr)
        this.walletArr[id].tokenBalance = tokenBa.toString()
      } catch (error) {
        this.$message.error(`${this.$t('batchTools.pubilc.num57')}:${error.code}`)
      }
    },

    //批量授权Token0 最大 MAX_INT
    batchApproveToken0(){
      this.batchQueryBalance()
      this.isLoading = true
      if (this.walletArr.length === 0) {
        this.$message.error(this.$t('batchTools.pubilc.num39'))
        this.isLoading = false
        return
      }
      let walletArr = this.walletArr
      for (let i = 0; i < walletArr.length; i++) {
        this.checkApproveToken0( walletArr[i])
      }
    },
    async checkApproveToken0(wallet){  //授权Token0
      wallet.isApprove = '4'
      const amount = BigNumber(MAX_INT)
      let privateKey = wallet.walletKey
      let signer = new ethers.Wallet(privateKey, this.provider)
      let tokenContract = new ethers.Contract(this.token0Address, ercABI, signer)
      let allowanceAmount  = await tokenContract.allowance(wallet.walletAddr, this.routerContractAddr)
      if (+allowanceAmount.toString() >= amount) {
        // console.log("授权数量 : ",+allowanceAmount.toString() - amount)
        wallet.isApprove = '2';//已授权
        this.isAllApprove = true
        this.isLoading = false
        return
      }
      // 定义交易参数
      let gasHs = parseUnits((this.gasPriceNum).toString(), "gwei")
      let txParams = {
        gasPrice: BigNumber(gasHs),
        gasLimit: BigNumber(this.gasLimit),
      }
      try {
        let receipt = await tokenContract.approve(this.routerContractAddr, amount, txParams)
        await receipt.wait()
        console.log(receipt.hash)
        wallet.isApprove = '2';//已授权
        this.isAllApprove = true
        wallet.approveTx = this.chainArr[this.chainID].chainUrl+'/tx/'+receipt.hash //哈希
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        wallet.isApprove = '3';//失败了
        this.$message.error(`${this.$t('batchTools.pubilc.num80')}:${error.code}`)
      }
    },

    //判断是否全部授权
    isAllApproveFn() {
      this.isAllApprove  = this.walletArr.every(item => item.isApprove === 2)
    },
    
    //添加链弹窗
    addChainModal(){
      const id = (this.chainArr.length).toString()
      const arr = {
        "id":id,
        "chainName":this.addChainNmae,
        "chainCoin":this.addChainSymbol,
        "chainId":this.addChainId,
        "chainRpc":this.addChainRpc,
        "chainUrl":this.addchainUrl,
        "exchanges":[],
        "tokenData":[
          {
            "tokenId":"0",
            "tokenContract":this.addWETHAddr,
            "tokenSymbol":this.addChainSymbol,
            "tokenName":'Wrapped ' + this.addChainSymbol,
            "tokenDecimals":'18',
            "tokenLogo":this.addWETHUrl
          }
        ],
      }
      this.chainArr.push(arr);
      this.isAddChain = false
    },
    //添加交易所弹窗
    async addExchangeModal(){
      const exchangeNums = (new Date().getTime()).toString()
      const exchangesDat = {
        "id":exchangeNums,
        "name":this.addExchangeName,
        "routerContractAddr":this.addExchangeRouterAddr,
      };
      this.chainArr[this.chainID].exchanges.push(exchangesDat);
      this.setCommon0Data(0)
      this.setCommon1Data(0)
      this.isAddExchange = false
    },
    //添加代币
    async addTokenModal(){
      var isTokens = this.tokenArr.filter(item => item.tokenContract.includes(this.tokenContractAddr))
      if (isTokens.length === 0) {
        this.tokenArr = []
        try {
          this.isLoading = true
          const id = (new Date().getTime()).toString()
          const tokenContract = new ethers.Contract(this.tokenContractAddr, ercABI, this.provider)
          const tokenSymbol = await tokenContract.symbol()
          const tokenDecimals = await tokenContract.decimals()
          const tokenName = await tokenContract.name()
          const newTokenArr = {
            'tokenId':id,
            'tokenContract':this.tokenContractAddr,
            'tokenSymbol':tokenSymbol,
            'tokenDecimals':tokenDecimals.toString(),
            'tokenName':tokenName,
            'tokenLogo':'./img/tokenIcon/unknown-token.png'
          }
          this.tokenArr = [newTokenArr]
          this.isLoading = false
          this.chainArr[this.chainID].tokenData.push(newTokenArr)
        } catch (error) {
          this.tokenArr = []
          this.isLoading = false
          // this.$message.error(`没有搜索到`)
        }
      }else{
        this.tokenArr = isTokens
      }
      if (this.tokenContractAddr === '') {
        this.tokenArr = this.chainArr[this.chainID].tokenData
      }
    },
    //代币管理 删除某个代币
    deleteItem(id) {
      this.chainArr[this.chainID].tokenData = this.chainArr[this.chainID].tokenData.filter(item => item.tokenId !== id)
      this.tokenArr = this.chainArr[this.chainID].tokenData
    },

    //代币管理 删除某个代币
    deleteExchange(id) {
      this.chainArr[this.chainID].exchanges = this.chainArr[this.chainID].exchanges.filter(item => item.id !== id)
      this.exchangeArr = this.chainArr[this.chainID].exchanges
    },

    //操作 删除单个 walletArr 数据 
    setDelete(id) {
      this.walletArr = this.walletArr.filter((item) => item.id !== id)
    },

    //删除 所有数据 
    setDeleteArr(){
      this.walletArr = []
    },

    //删除选中 数据 
    setCheckArr(){
      let deleteids = this.selectedTable
      const newArr = this.walletArr.filter((a) => !deleteids.some((b) => a.id === b.id))
      this.walletArr = newArr;
    },

    //选中 所有ETH 0
    setSelectEHT0(){
      this.$refs.selectedTable.clearSelection()//清空 勾选框
      for (let i = 0; i <  this.walletArr.length; i++) {
        if ( this.walletArr[i].ethBalance === '0') {
          this.$refs.selectedTable.toggleRowSelection(this.walletArr[i],true)
        }
      }
    },
    //选中 所有ETH 0
    setSelectToken0(){
      this.$refs.selectedTable.clearSelection()
      for (let i = 0; i <  this.walletArr.length; i++) {
        if ( this.walletArr[i].tokenBalance === '0') {
          this.$refs.selectedTable.toggleRowSelection(this.walletArr[i],true)
        }
      }
    },

    //选中 所有错误
    setCheckError(){
      this.$refs.selectedTable.clearSelection()
      for (let i = 0; i <  this.walletArr.length; i++) {
        if ( this.walletArr[i].txResult === '3') {
          this.$refs.selectedTable.toggleRowSelection(this.walletArr[i],true)
        }
      }
    },

    //选择框
    handleSelectionChange(val) {
      this.selectedTable = val;
    },

    //清空本地粗存 walletArr 数据
    deleteCache(){
      this.walletArr = []
    },
    //设置token0
    setCommon0Data(id){
      this.token0LogoURI = this.tokenArr[id].tokenLogo
      this.token0Symbol = this.tokenArr[id].tokenSymbol
      this.token0Name = this.tokenArr[id].tokenName
      this.token0Address = this.tokenArr[id].tokenContract
      this.token0Decimals = this.tokenArr[id].tokenDecimals
      for (let i = 0; i < this.walletArr.length; i++) {
        this.walletArr[i].txResult = '1'
        this.walletArr[i].tokenBalance = '0'
        this.walletArr[i].isApprove = '1'
      }
      this.isToken0 = false
      this.isAllApprove = false
      this.token0Amount = ""
      this.token1Amount = ""
      this.formInputAmount()
    },
    setCommon1Data(id){
      this.token1LogoURI = this.tokenArr[id].tokenLogo
      this.token1Symbol = this.tokenArr[id].tokenSymbol
      this.token1Name = this.tokenArr[id].tokenName
      this.token1Address = this.tokenArr[id].tokenContract
      this.token1Decimals = this.tokenArr[id].tokenDecimals
      this.isToken1 = false
      this.token0Amount = ""
      this.token1Amount = ""
      this.formInputAmount()
    },
    //获取form to 数量  同时更新当前路由 currentPath
    async formInputAmount(){
      if (!isNormalNonZeroNumber(+this.token0Amount)) {
        this.token1Amount = ""
        this.amountOutMin = '0'
        return
      }
      let amountIn = parseUnits(this.token0Amount,this.token0Decimals)
      let routerContract = new ethers.Contract(this.routerContractAddr, routerABI, this.provider)
      let path = [this.token0Address, this.token1Address]
      let amountsOut = ''
      try{
        amountsOut = await routerContract.getAmountsOut(amountIn, path)
      }catch (e) {
        try {
          if (this.token0Symbol !== this.mainSymbol || this.token1Symbol !== this.mainSymbol) {
            path = [this.token0Address,this.tokenArr[0].tokenContract, this.token1Address]
            amountsOut = await routerContract.getAmountsOut(amountIn, path)
          }
        } catch (b) {
          this.$message.error(`${this.$t('batchTools.pubilc.num81')}:${b.code}`)
        }
        if (amountsOut === '') return
      }
      this.currentPath = path
      let token1Amount = formatUnitsFive(amountsOut[path.length - 1].toString(),this.token1Decimals)
      this.token1Amount = token1Amount.toString()
      let amountOutMin = new BigNumber(amountsOut[path.length - 1]).mul(100 - this.gagSGH).div(100)
      this.amountOutMin = formatUnitsFive(amountOutMin,this.token1Decimals)
    },
    async toInputAmount(){
      if (!isNormalNonZeroNumber(+this.token1Amount)) {
        this.token0Amount = ""
        this.amountOutMin = '0'
        return
      }
      let amountsOut = parseUnits(this.token1Amount,this.token1Decimals)
      let routerContract = new ethers.Contract(this.routerContractAddr, routerABI, this.provider)
      let path = [this.token0Address, this.token1Address]
      let amountIn = ''
      try{
        amountIn = await routerContract.getAmountsIn(amountsOut, path)
      }catch (e) {
        try {
          if (this.token0Symbol !== this.mainSymbol || this.token1Symbol !== this.mainSymbol) {
            path = [this.token0Address,this.tokenArr[0].tokenContract, this.token1Address]
            amountIn = await routerContract.getAmountsIn(amountsOut, path)
          }
        } catch (b) {
          this.$message.error(`${this.$t('batchTools.pubilc.num81')}:${b.code}`)
        }
        if (amountIn === '') return
      }
      this.currentPath = path
      let token0Amount = formatUnitsFive(amountIn[0].toString(),this.token0Decimals)
      this.token0Amount = token0Amount.toString()
      let amountOutMin = new BigNumber(amountsOut).mul(100 - this.gagSGH).div(100)
      this.amountOutMin = formatUnitsFive(amountOutMin,this.token1Decimals)
    },
    // 交换from to数据
    turnAround() {
      this.token0Amount = ''
      this.token1Amount = ''
      const tempAddress = this.token0Address
      this.token0Address = this.token1Address
      this.token1Address = tempAddress
      const tempLogoURI = this.token0LogoURI
      this.token0LogoURI = this.token1LogoURI
      this.token1LogoURI = tempLogoURI
      const tempSymbol = this.token0Symbol
      this.token0Symbol = this.token1Symbol
      this.token1Symbol = tempSymbol
      const tempDecimals = this.token0Decimals
      this.token0Decimals = this.token1Decimals
      this.token1Decimals = tempDecimals
      this.isAllApprove = false
    },
    //批量 购买
    async batchSwap(){
      await this.batchQueryBalance()
      let walletArr = this.walletArr
      if (walletArr.length === 0) {
        this.$message.error(this.$t('batchTools.pubilc.num39'))
        return
      }
      if (this.token0Amount==='' && !this.isCustomQuantity) {
        this.$message.error(this.$t('batchTools.pubilc.num83'))
        return
      }
      this.isLoading = true
      for (let i = 0; i < walletArr.length; i++) {
        if (this.token0Symbol === this.mainSymbol && this.token1Symbol !== this.mainSymbol) {
          if (!this.isParallel) {
            this.swapExactETHForTokens(walletArr[i])  //用ETH换token  买 并行执行
          } else {
            await this.swapExactETHForTokens(walletArr[i])  //用ETH换token  买  串行执行
          }
        } else if(this.token0Symbol !== this.mainSymbol && this.token1Symbol !== this.mainSymbol) {
          if (!this.isParallel) {
            this.swapExactTokensForTokensSupportingFeeOnTransferTokens(walletArr[i])  //用token买token  并行执行
          } else {
            await this.swapExactTokensForTokensSupportingFeeOnTransferTokens(walletArr[i])  //用token买token  串行执行
          }
        }else{
          if (!this.isParallel) {
            this.swapExactTokensForETH(walletArr[i])  //token换EMT  卖  并行执行
          } else {
            await this.swapExactTokensForETH(walletArr[i])  //token换EMT  卖  串行执行
          }
        }
      }
    },
    async swapExactETHForTokens(wallet){ //用ETH换token 买 根据精确的ETH交换尽量多的token
      wallet.txResult = '4'
      
      let privateKey = wallet.walletKey
      let signer = new ethers.Wallet(privateKey, this.provider)
      let tokenContract = new ethers.Contract(this.routerContractAddr, routerABI, signer)
      let amountIn = ''
      if (this.isCustomQuantity) {
        amountIn = BigNumber(wallet.ethBalance).mul(this.customPercentage).div('100')
      }else{
        amountIn = parseUnits(this.token0Amount.toString(),this.token0Decimals)
      }
      // 定义交易参数
      let gasHs = parseUnits((this.gasPriceNum).toString(), "gwei")
      var txParams = {}
      if (this.isGasLimit) {
        txParams = { gasPrice: BigNumber(gasHs), value:amountIn}
      }else{
        txParams = { gasPrice: BigNumber(gasHs), gasLimit: BigNumber(this.gasLimit), value:amountIn}
      }
      let toAddr = ''
      if (!this.isCustomAcceptAddress) {
        toAddr = wallet.walletAddr
      }else{
        toAddr = this.customAcceptAddress
      }
      let ethPath = [this.token0Address,this.token1Address]
      let amountsOut = ''
      try{
        amountsOut = await tokenContract.getAmountsOut(amountIn.toString(), ethPath)
      }catch (e) {
        wallet.txResult = '3';//失败了
        this.$message.error(`${this.$t('batchTools.pubilc.num81')}:${e.code}`)
        this.isLoading = false
      }
      let amountOutMin = new BigNumber(amountsOut[1]).mul(100 - this.gagSGH).div(100)
      console.log('amountOutMin: ' + amountOutMin);
      //最好是先通过获取GAS 判断一下
      let gas = await tokenContract.estimateGas.swapExactETHForTokens(amountOutMin, ethPath, toAddr, '1914823077',txParams).catch(async e =>{
        let receiptOne = await tokenContract.swapExactETHForTokensSupportingFeeOnTransferTokens(amountOutMin, ethPath, toAddr, '1914823077',txParams).catch(async e =>{
          wallet.txResult = '3';//失败了
          this.isLoading = false
          this.$notify.error({
            title: `${this.$t('batchTools.pubilc.num72')}`,
            message: e.code
          });
          throw e.code;
        })
        await receiptOne.wait()
        console.log(receiptOne.hash)
        wallet.txResult = '2';//成功了
        let hastTx = this.chainArr[this.chainID].chainUrl+'/tx/'+receiptOne.hash //哈希
        wallet.swapTx = hastTx //哈希
        this.$notify({
          title: `${this.$t('batchTools.pubilc.num82')}`,
          dangerouslyUseHTMLString: true,
          message: `<a href="${hastTx}" target="_blank" rel="noopener noreferrer"> ${this.$t('batchTools.pubilc.num84')} </a>`,
          type: 'success'
        });
        // this.token0Amount = ""
        this.token1Amount = ""
        this.isLoading = false
        await this.batchQueryBalance()
        throw e.code;
      })
      console.log("gas 1",gas.toString())
      try {
        let receipt = await tokenContract.swapExactETHForTokens(amountOutMin, ethPath, toAddr, '1914823077',txParams)
        await receipt.wait()
        console.log(receipt.hash)
        wallet.txResult = '2';//成功了
        let hastTx = this.chainArr[this.chainID].chainUrl+'/tx/'+receipt.hash //哈希
        wallet.swapTx = hastTx //哈希
        this.$notify({
          title: `${this.$t('batchTools.pubilc.num82')}`,
          dangerouslyUseHTMLString: true,
          message: `<a href="${hastTx}" target="_blank" rel="noopener noreferrer"> ${this.$t('batchTools.pubilc.num84')} </a>`,
          type: 'success'
        });
        // this.token0Amount = ""
        this.token1Amount = ""
        this.isLoading = false
        await this.batchQueryBalance()
      } catch (error) {
        wallet.txResult = '3';//失败了
        this.$message.error(`${this.$t('batchTools.pubilc.num72')}:${error.code}`)
        this.isLoading = false
      }
    },
    async swapExactTokensForTokensSupportingFeeOnTransferTokens(wallet){   //用token买token 支持收税的根据精确的token交换尽量多的token
      wallet.txResult = '4'
      let privateKey = wallet.walletKey
      let signer = new ethers.Wallet(privateKey, this.provider)
      let tokenContract = new ethers.Contract(this.routerContractAddr, routerABI, signer)
      let amountIn = ''
      if (this.isCustomQuantity) {
        amountIn = BigNumber(wallet.tokenBalance).mul(this.customPercentage).div('100')
      }else{
        amountIn = parseUnits(this.token0Amount.toString(),this.token0Decimals)
      }

      // 定义交易参数
      let gasHs = parseUnits((this.gasPriceNum).toString(), "gwei")
      var txParams = {}
      if (this.isGasLimit) {
        txParams = { gasPrice: BigNumber(gasHs)}
      }else{
        txParams = { gasPrice: BigNumber(gasHs), gasLimit: BigNumber(this.gasLimit)}
      }
      let toAddr = ''
      if (!this.isCustomAcceptAddress) {
        toAddr = wallet.walletAddr
      }else{
        toAddr = this.customAcceptAddress
      }
      let path = [this.token0Address, this.token1Address]
      let amountsOut = ''
      try{
        amountsOut = await tokenContract.getAmountsOut(amountIn.toString(), path)
      }catch (e) {
        try {
          path = [this.token0Address,this.tokenArr[0].tokenContract, this.token1Address]
          amountsOut = await tokenContract.getAmountsOut(amountIn.toString(), path)
        } catch (b) {
          wallet.txResult = '3';//失败了
          this.$message.error(`${this.$t('batchTools.pubilc.num81')}:${b.code}`)
          this.isLoading = false
        }
        if (amountsOut === ''){
          wallet.txResult = '3';//失败了
          // this.$message.error(`${this.$t('batchTools.pubilc.num81')}:${e.code}`)
          this.isLoading = false
        } 
      }
      let amountOutMin = new BigNumber(amountsOut[path.length - 1]).mul(100 - this.gagSGH).div(100)
      try {
        let receipt = await tokenContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, toAddr, '1914823077',txParams)
        await receipt.wait()
        console.log(receipt.hash)
        wallet.txResult = '2';//成功了
        let hastTx = this.chainArr[this.chainID].chainUrl+'/tx/'+receipt.hash //哈希
        wallet.swapTx = hastTx //哈希
        this.$notify({
          title: `${this.$t('batchTools.pubilc.num82')}`,
          dangerouslyUseHTMLString: true,
          message: `<a href="${hastTx}" target="_blank" rel="noopener noreferrer"> ${this.$t('batchTools.pubilc.num84')}  </a>`,
          type: 'success'
        });
        // this.token0Amount = ""
        this.token1Amount = ""
        this.isLoading = false
        await this.batchQueryBalance()
      } catch (error) {
        wallet.txResult = '3';//失败了
        this.$message.error(`${this.$t('batchTools.pubilc.num72')}:${error.code}`)
        this.isLoading = false
      }
    },
    async swapExactTokensForETH(wallet){ //token换EMT  卖   根据精确的token交换尽量多的ETH
      wallet.txResult = '4'
      let privateKey = wallet.walletKey
      let signer = new ethers.Wallet(privateKey, this.provider)
      let tokenContract = new ethers.Contract(this.routerContractAddr, routerABI, signer)
      let amountIn = ''
      if (this.isCustomQuantity) {
        amountIn = BigNumber(wallet.tokenBalance).mul(this.customPercentage).div('100')
      }else{
        amountIn = parseUnits(this.token0Amount.toString(),this.token0Decimals)
      }
      // 定义交易参数
      let gasHs = parseUnits((this.gasPriceNum).toString(), "gwei")
      var txParams = {}
      if (this.isGasLimit) {
        txParams = { gasPrice: BigNumber(gasHs)}
      }else{
        txParams = { gasPrice: BigNumber(gasHs), gasLimit: BigNumber(this.gasLimit)}
      }
      let toAddr = ''
      if (!this.isCustomAcceptAddress) {
        toAddr = wallet.walletAddr
      }else{
        toAddr = this.customAcceptAddress
      }
      let ethPath = [this.token0Address,this.token1Address]
      let amountsOut = ''
      try{
        amountsOut = await tokenContract.getAmountsOut(amountIn.toString(), ethPath)
      }catch (e) {
        wallet.txResult = '3';//失败了
        this.$message.error(`${this.$t('batchTools.pubilc.num81')}:${e.code}`)
        this.isLoading = false
      }
      let amountOutMin = new BigNumber(amountsOut[1]).mul(100 - this.gagSGH).div(100)
      //最好是先通过获取GAS 判断一下
      let gas = await tokenContract.estimateGas.swapExactTokensForETH(amountIn, amountOutMin, ethPath, toAddr, '1914823077').catch(async e =>{
        let receiptOne = await tokenContract.swapExactTokensForETHSupportingFeeOnTransferTokens(amountIn, amountOutMin, ethPath, toAddr, '1914823077',txParams).catch(async e =>{
          wallet.txResult = '3';//失败了
          this.$notify.error({
            title: `${this.$t('batchTools.pubilc.num72')}`,
            message: e.code
          });
          this.isLoading = false
          throw e.code;
        })
        await receiptOne.wait()
        console.log(receiptOne.hash)
        wallet.txResult = '2';//成功了
        let hastTx = this.chainArr[this.chainID].chainUrl+'/tx/'+receiptOne.hash //哈希
        wallet.swapTx = hastTx //哈希
        this.$notify({
          title: `${this.$t('batchTools.pubilc.num82')}`,
          dangerouslyUseHTMLString: true,
          message: `<a href="${hastTx}" target="_blank" rel="noopener noreferrer"> ${this.$t('batchTools.pubilc.num84')} </a>`,
          type: 'success'
        });
        // this.token0Amount = ""
        this.token1Amount = ""
        this.isLoading = false
        await this.batchQueryBalance()
        throw e.code;
      })
      console.log("gas 1",gas.toString())
      try {
        let receipt = await tokenContract.swapExactTokensForETH(amountIn, amountOutMin, ethPath, toAddr, '1914823077',txParams)
        await receipt.wait()
        console.log(receipt.hash)
        wallet.txResult = '2';//成功了
        let hastTx = this.chainArr[this.chainID].chainUrl+'/tx/'+receipt.hash //哈希
        wallet.swapTx = hastTx //哈希
        this.$notify({
          title: `${this.$t('batchTools.pubilc.num82')}`,
          dangerouslyUseHTMLString: true,
          message: `<a href="${hastTx}" target="_blank" rel="noopener noreferrer"> ${this.$t('batchTools.pubilc.num84')} </a>`,
          type: 'success'
        });
        // this.token0Amount = ""
        this.token1Amount = ""
        this.isLoading = false
        await this.batchQueryBalance()
      } catch (error) {
        wallet.txResult = '3';//失败了
        this.$message.error(`${this.$t('batchTools.pubilc.num72')}:${error.code}`)
        this.isLoading = false
      }
    },

    //复制
    async copy(text, output, className) {
      const clipboard = new Clipboard(className, {
          text: () => {
              return text
          },
      })
      clipboard.on('success', () => {
          this.$message.success(output)
          clipboard.destroy()
      })
      clipboard.on('error', () => {
          this.$message.error(this.$t('pubilc.copyFailed'))
          clipboard.destroy()
      })
    },
  },
  watch:{
    //监听 walletArr 本地持久化
    walletArr:{
      deep:true,
      handler(newValue){
        localStorage.setItem('walletArr',JSON.stringify(newValue))
      }
    },
    chainArr:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainArr',JSON.stringify(newVal))
      }
    },
    chainID:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainID',JSON.stringify(newVal))
      }
    }
  },
};
</script>

<style scoped>
.swapMain{
  height: auto;
  background: #f9fafb;
  margin: 30px 10px;
}

.swapMain .swapContainer{
  height: auto;
  background: #fff;
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 15px;
  border: 1px solid #eee;
}
.swapMain .swapContainer .swapContainerTop{
  height: 50px;
  padding-top: 5px;
  /* border-bottom: 1px solid #ddd; */
}
.swapMain .swapContainer .swapContainerTop .setSwap{
  float: left;
  padding: 0 2px;
  width: 100%;
  font-size: 22px;
  font-weight: 600;
}
.swapMain .swapContainer .swapContainerTop .setSwap .setSwapText{
  float: left;
}
.swapMain .swapContainer .swapContainerTop .setSwap .setSwapBn{
  float: right;
}
.swapMain .swapContainer .swapContainerTop .setSwap .setSwapBn i{
  cursor:pointer;
  padding-left: 10px;
}
.swapMain .swapContainer .swapContainerTokenOne{
  height: 106px;
  background: #F7F8FA;
  border-radius: 20px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  display: flex;
}

.swapMain .swapContainer .swapContainerTokenOne .tokenSelect{
  float: right;
  width: auto;
  margin: 22px 0;
  background: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  display: block;
  cursor:pointer;
  position: relative;
  z-index: 9;
  right: 5px;
  border: 1px solid #eee;
}

.swapMain .swapContainer .swapContainerTokenOne .tokenSelect .tokenL {
  float: left;
  line-height: 42px;
  height: 40px;
}
.swapMain .swapContainer .swapContainerTokenOne .tokenSelect .tokenL .tokenlogoURI {
  width: 35px;
  float: left;
}
.swapMain .swapContainer .swapContainerTokenOne .tokenSelect .tokenL .tokenlogoURI img{
  width: 25px;
  height: 25px;
  margin-top: 8px;
  border-radius: 50%;
} 
.swapMain .swapContainer .swapContainerTokenOne .tokenSelect .tokenL .tokenSymbol{
  font-size: 14px;
  float: left;
  padding-right: 5px;
}
.swapMain .swapContainer .swapContainerTokenOne .tokenAmount{
  float: left;
  min-width: 50%;
  padding: 0px 3px;
  flex: 1;
}
::v-deep .swapMain .swapContainer .swapContainerTokenOne .tokenAmount .el-input__inner{
  border: none !important;
  background: #f7f8fa;
  font-size: 18px;
  height: 98px;
}

.swapMain .swapContainer .swapSelect{
  width: 100%;
  height: 55px;
  margin: 5px 0;
}
.swapMain .swapContainer .swapSelect .sscLeft{
  float: left;
  line-height: 38px;
}

.swapMain .swapContainer .swapSelect .sscRight{
  float: right;
  max-width: 60%;
}

.swapMain .swapContainer .exchangeBn{
  position: relative;
  z-index: 99;
  margin: 4px auto;
  width: 30px;
  height: 2px;
  top: -20px;
}
.swapMain .swapContainer .exchangeBn-bj{
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 50%;
  padding: 4px;
}
.swapMain .swapContainer .exchangeBn i{
  text-align: center;
  width: 30px;
  height: 30px;
  background: #67c23a;
  border-radius: 50%;
  line-height: 30px;
  color: #fff;
}
.swapMain .swapContainer .exchangeBn:hover i{
  background: #ebb563;
}

.swapMain .swapContainer .swapContainerBn button{
  margin: 15px 0;
  width: 100%;
}

.swapMain .swapContainer .swapContainerBottom{
  height: 106px;
  background: #F7F8FA;
  border-radius: 5px;
  padding: 8px;
}

.swapMain .swapContainer .swapContainerBottom div{
  line-height: 28px;
  font-size: 13px;
}
.swapMain .swapContainer .swapContainerBottom span{
  float: right;
}
.swapMain .swapContainer .swapContainerBottom span i{
  padding-left: 5px;
}
.commonTokenList{
  margin-top: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.commonToken{
  margin-bottom: 10px;
  margin-right: 10px;
  white-space: nowrap;
  display: inline-block;
}
.commonToken .commonTokenMain{
  padding: 5px 15px;
  border: 1px solid #888;
  border-radius: 10px;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
}
.commonToken img{
  float: left;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  padding-top: 2px;
}
.commonToken .commonTokenSymbol{
  line-height: 30px;
}
.tokenListMain{
  margin-bottom: 15px;
  max-height: 350px;
  overflow: auto;
}
.allTokenList{
  width: 98%;
  height: 66px;
  padding: 10px 10px;
  cursor: pointer;
}
.isDisabled{
  color: #adadad;
  pointer-events: none;
  cursor: not-allowed;
}
.allTokenList:hover{
  background: #eee;
}
.allTokenList .allTokenLeft{
  float: left;
  width: 100%;
}
.noneTokenLeft{
  text-align: center;
  color: #af2241;
  font-size: 18px;
}
.allTokenList .allTokenLeft .tokenL{
  float: left;
  width: 35px;
}
.allTokenList .allTokenLeft .tokenL img{
  height: 25px;
  width: 25px;
  margin-top: 10px;
  border-radius: 50%;
}
.allTokenList .allTokenLeft .tokenR{
  margin-left: 35px;
}
.allTokenList .allTokenLeft .tokenR .tokenSymbol{
  font-size: 15px;
  float: left;
  width: 100%;
}
.allTokenList .allTokenLeft .tokenR .tokenSymbol span{
  font-size: 12px;
} 
.allTokenList .allTokenLeft .tokenR .tokenContract{
  padding-top: 8px;
  font-size: 14px;
  float: left;
  width: 100%;
}
.allTokenList .allTokenLeft .tokenR .tokenContract i{
  color: #d61515;
  padding-left: 8px;
}
.el-row{
  margin-bottom: 20px;
}
::v-deep .el-table th > .cell {
  text-align: center;
}
::v-deep .el-table td > .cell {
  text-align: center;
}


.el-table td.el-table__cell{
  text-align: center;
}

.addressView {
  width: 100%;
  height: auto;
  min-height: 94%;
}

.container {
  max-width: 1200px;
  padding: 32px;
  height: min-content;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.selectDelete{
  background: #fff;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 20px;
}
.selectDelete button{
  margin-bottom: 10px;
  margin-left: 10px;
}
.selectDelete .bnzu{
  margin-top: 15px;
}
.selectDelete .onTable{
  height: 455px;
  max-height: 455px;
  overflow-y: auto;
  overflow-x: hidden;
}
.sub-title{
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.gagSGHInput{
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
}
::v-deep .gagSGHInput .el-input-group{
  width: 150px;
}
.sendText{
  margin-left: 15px;
}
.title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  position:relative;
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #ccc;
}
::v-deep .el-dialog__body{
  padding: 20px 20px !important;
}

.load {
  width: 30px;
  height: 30px;
  filter: invert(100%);
  vertical-align: middle;
}

#dexscreener-embed{
  position:relative;
  width:100%;
  padding-bottom:620px;
}
@media(min-width:1400px){
  #dexscreener-embed{
    padding-bottom:620px;
  }
}
#dexscreener-embed iframe{
  position:absolute;
  width:100%;
  height:600px;
  top:0;
  left:0;
  border:0;
}

@media (max-width:768px){
    .container .result{
      word-break: break-all;
    }
    
    .container div .unctionalArea{
        flex-wrap: wrap;
    }

    .unctionalArea .el-button{
        margin-top: 20px;
    }
    ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
    .tokenSelect .tokenL .tokenSymbol{
      font-size: 12px!important;
    }
    .allTokenList{
      height: 80px;
    }
}
</style>
