import { ethers } from "ethers"

export function BigNumber(wei) {
    return wei != "0x0" ? ethers.BigNumber.from(wei) : "";
}

export function formatUnits(wei,decimals) {
    return wei != "0x0" ? ethers.utils.formatUnits(wei, decimals) : "0";
}

export function formatUnitsFive(wei,decimals) {
    return wei != "0x0" ? Math.floor(ethers.utils.formatUnits(wei, decimals) * 100000 ) / 100000 : "0";
}

export function parseUnits(wei,decimals) {
    return wei != "0x0" ? ethers.utils.parseUnits(wei, decimals).toString() : "0";
}

// export function BigInt(wei) {
//     return wei != "0x0" ? ethers.utils.parseUnits(wei, decimals).toString() : "0";
// }

export function showAccount(str) {
    if (str) {
        const account = String(str);
        return account.substring(0, 4) + "..." + account.substring(account.length - 4, account.length);
    }
    return "";
}
export function isNormalNonZeroNumber(value) {  
    if (!Number.isFinite(value)) {  
        return false;  
    }
    if (value === 0 || value < 0 || isNaN(value)) { 
        return false;  
    }
    if (Number.isInteger(value)) {  
        return true;  
    } 
    return true;  
}
//字符串转16进制
export function stringToHex(str) {
    let hex = '';
    for(let i = 0; i < str.length; i++) {
      hex += str.charCodeAt(i).toString(16).padStart(2, '0');
    }
    return '0x' + hex
}

export function getQueryVariable(variable){
	var query = window.location.search.substring(1);
	var vars = query.split("&");
	for (var i=0;i<vars.length;i++) {
			var pair = vars[i].split("=");
			if(pair[0] == variable){return pair[1];}
	}
	return(false);
}

export function decToHex(decNumber) {
    return '0x' + Number(decNumber).toString(16);  
}

export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const MAX_INT = "115792089237316195423570985008687907853269984665640564039457584007913129639935";