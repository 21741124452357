<template>
	<div class="home">
		<Navigation></Navigation>
		<div class="listTool">
			<el-row :gutter="10">
				<div class="listTitle"><p>{{$t('home.hotTool')}}</p></div>
				<div v-for="(item, index) in hotToolList" :key="'toolList' + index" @click="toRouter(item.router)">
					<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
						<div class="text">
							<div class="textImg"><img :src='item.url'></div>
							<div class="textRight">
								<div class="textTitle">{{ item.title }} <span><i class="el-icon-arrow-right"></i></span></div>
								<div class="textDetail">{{ item.detail }}</div>
							</div>
						</div>
					</el-col>
				</div>
			</el-row>
			<el-row :gutter="10">
				<div class="listTitle"><p>{{$t('home.toolList[21].title')}}</p></div>
				<div v-for="(item, index) in createTokenList" :key="'toolList' + index" @click="toRouter(item.router)">
					<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
						<div class="text">
							<div class="textImg"><img :src='item.url'></div>
							<div class="textRight">
								<div class="textTitle">{{ item.title }} <span><i class="el-icon-arrow-right"></i></span></div>
								<div class="textDetail">{{ item.detail }}</div>
							</div>
						</div>
					</el-col>
				</div>
			</el-row>
			<el-row :gutter="10">
				<div class="listTitle"><p>{{$t('home.allTool')}}</p></div>
				<div v-for="(item, index) in toolList" :key="'toolList' + index" @click="toRouter(item.router)">
					<el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
						<div class="text">
							<div class="textImg"><img :src='item.url'></div>
							<div class="textRight">
								<div class="textTitle">{{ item.title }} <span><i class="el-icon-arrow-right"></i></span></div>
								<div class="textDetail">{{ item.detail }}</div>
							</div>
						</div>
					</el-col>
				</div>
			</el-row>
		</div>
	</div>
</template>

<script>
import Navigation from '../components/Navigation'

export default {
	name: 'home',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: 'Chaintool,区块链工具,区块链开发',
				},
				{
					name: 'description',
					content:
						'Chaintool 实用工具, 让区块链开发更轻松, 巧妙处理、提高效率，所需的工具尽在于此',
				},
			],
		}
	},

	computed: {
		hotToolList() {
			return [
				{
					title: this.$t('home.toolList[13].title'),
					detail: this.$t('home.toolList[13].detail'),
					router: '/batchCollection',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[14].title'),
					detail: this.$t('home.toolList[14].detail'),
					router: '/autoTrade',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[19].title'),
					detail: this.$t('home.toolList[19].detail'),
					router: '/batchMultiSender',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[33].title'),
					detail: this.$t('home.toolList[33].detail'),
					router: '/lock/create',
					url: require('../assets/imgs/toolList3.png'),
				},
			]
		},
		toolList() {
			return [
				{
					title: this.$t('home.toolList[14].title'),
					detail: this.$t('home.toolList[14].detail'),
					router: '/autoTrade',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[21].title'),
					detail: this.$t('home.toolList[21].detail'),
					router: '/createToken',
					url: require('../assets/imgs/toolList11.png'),
				},
				{
					title: this.$t('home.toolList[19].title'),
					detail: this.$t('home.toolList[19].detail'),
					router: '/batchMultiSender',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[12].title'),
					detail: this.$t('home.toolList[12].detail'),
					router: '/insertionTool',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[13].title'),
					detail: this.$t('home.toolList[13].detail'),
					router: '/batchCollection',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[1].title'),
					detail: this.$t('home.toolList[1].detail'),
					router: '/bulkQuery',
					url: require('../assets/imgs/toolList2.png'),
				},
				{
					title: this.$t('home.toolList[15].title'),
					detail: this.$t('home.toolList[15].detail'),
					router: '/batchApprove',
					url: require('../assets/imgs/toolList3.png'),
				},
				{
					title: this.$t('home.toolList[10].title'),
					detail: this.$t('home.toolList[10].detail'),
					router: '/faucet',
					url: require('../assets/imgs/toolList11.png'),
				},
				{
					title: this.$t('home.toolList[5].title'),
					detail: this.$t('home.toolList[5].detail'),
					router: '/generateWallet/evmWallet',
					url: require('../assets/imgs/toolList8.png'),
				},
				{
					title: this.$t('home.toolList[11].title'),
					detail: this.$t('home.toolList[11].detail'),
					router: 'generateWallet/btcWallet',
					url: require('../assets/imgs/toolList8.png'),
				},
				{
					title: this.$t('home.toolList[3].title'),
					detail: this.$t('home.toolList[3].detail'),
					router: '/abi',
					url: require('../assets/imgs/toolList5.png'),
				},
				{
					title: this.$t('home.toolList[6].title'),
					detail: this.$t('home.toolList[6].detail'),
					router: '/querySelector',
					url: require('../assets/imgs/toolList7.png'),
				},
				{
					title: this.$t('home.toolList[0].title'),
					detail: this.$t('home.toolList[0].detail'),
					router: '/unitConvert',
					url: require('../assets/imgs/toolList1.png'),
				},
				{
					title: this.$t('home.toolList[2].title'),
					detail: this.$t('home.toolList[2].detail'),
					router: '/traceview',
					url: require('../assets/imgs/toolList4.png'),
				},
				{
					title: this.$t('home.toolList[4].title'),
					detail: this.$t('home.toolList[4].detail'),
					router: '/address',
					url: require('../assets/imgs/toolList6.png'),
				},
				{
					title: this.$t('home.toolList[7].title'),
					detail: this.$t('home.toolList[7].detail'),
					router: '/topicID',
					url: require('../assets/imgs/toolList9.png'),
				},
				{
					title: this.$t('home.toolList[8].title'),
					detail: this.$t('home.toolList[8].detail'),
					router: '/hashTool',
					url: require('../assets/imgs/toolList10.png'),
				},
				{
					title: this.$t('home.toolList[9].title'),
					detail: this.$t('home.toolList[9].detail'),
					router: '/calldata',
					url: require('../assets/imgs/toolList11.png'),
				},
			]
		},
		createTokenList() {
			return [
				{
					title: this.$t('home.toolList[22].title'),
					detail: this.$t('home.toolList[22].detail'),
					router: '/createToken/stardand',
					url: require('../assets/imgs/toolList2.png'),
				},
				{
					title: this.$t('home.toolList[23].title'),
					detail: this.$t('home.toolList[23].detail'),
					router: '/createToken/holdReflection',
					url: require('../assets/imgs/toolList2.png'),
				},
				{
					title: this.$t('home.toolList[24].title'),
					detail: this.$t('home.toolList[24].detail'),
					router: '/createToken/lpWithInviter',
					url: require('../assets/imgs/toolList2.png'),
				},
				{
					title: this.$t('home.toolList[25].title'),
					detail: this.$t('home.toolList[25].detail'),
					router: '/createToken/lpBurn',
					url: require('../assets/imgs/toolList2.png'),
				},
				{
					title: this.$t('home.toolList[26].title'),
					detail: this.$t('home.toolList[26].detail'),
					router: '/createToken/lpMine',
					url: require('../assets/imgs/toolList2.png'),
				},
				{
					title: this.$t('home.toolList[29].title'),
					detail: this.$t('home.toolList[29].detail'),
					router: '/createToken/X314Protocol',
					url: require('../assets/imgs/toolList2.png'),
				},
			]
		},
		title() {
			return this.$t('title.home')
		},
	},

	methods: {
		toRouter(router) {
			this.$router.push(router)
		},
	},
}
</script>

<style scoped>


a {
	text-decoration-line: none;
}

.home {
	width: 100%;
	height: auto;
	margin: 0 auto;
}
.listTitle{
	border-bottom: 1px solid #ddd;
	margin: 10px;
	color: #000;
	font-size: 18px;
	text-align: center;
}
.listTitle p{
	width: 120px;
	text-align: center;
	border-bottom: 2px solid #c1081e;
	padding-bottom: 15px;
}
.listTool{
	/* display: flex;
	flex-wrap: wrap; */
	/* gap: 16px; */
	width: 100%;
	margin: 0 auto;
	padding: 20px;
}
.listTool .text {
	width: 100%;
	padding: 15px;
	height: 100px;
	float: left;
	margin-bottom: 10px;
	background: #fff;
	border-radius: 10px;
	border: 1px solid #ddd;
}
.listTool .text:hover .textTitle{
	color: #c9273c;
}
.listTool .text:hover {
	box-shadow: 0 5px 10px -4px rgba(34,23,11,.2);
}
.listTool .text .textImg {
	width: 60px;
	height: 100px;
	float: left;
}
.listTool .text .textImg img{
	border-radius: 50%;
	border: 1px solid #ca273b;
	padding: 5px;
	width: 25px;
	height: 25px;
}
.listTool .text .textTitle{
	padding-bottom: 5px;
	font-size: 16px;
}
.listTool .text span{
	float: right;
}
.listTool .text .textDetail{
	padding-bottom: 15px;
	font-size: 14px;
	color: #888;
	margin-right: 25px;
}
</style>