<template>
	<div class="stardand">
		<div class="mainSender">
			<div class="container">

        <div class="senderMain">
          <div class="titleHe">{{$t('home.toolList[30].title')}}</div>

          <template>
            <el-alert
              title="生成钱包在你本地电脑执行,网站不能、也永远不会存储你的私钥，建议在生成钱包时断开网络（禁用WIFI或拔掉网线）。密钥库文件使用 AES-128-CTR 密码使用 BKDF2-SHA256 推导函数和 65536 次散列轮进行加密。 "
              effect="dark"
							type="warning">
            </el-alert>
          </template>
					<div class="title">
						前缀还是后缀
					</div>
					<div class="title">
						<template>
							<el-radio-group v-model="radioAround">
								<el-radio :label="1">前缀</el-radio>
								<el-radio :label="2">后缀</el-radio>
							</el-radio-group>
						</template>
					</div>
					<div class="title">
						请输入要生成的靓号字符串 比如：888
					</div>
					<div class="titleNum">
						<el-input v-model="inputNum" placeholder="请输入靓号"></el-input>
					</div>
					<div class="title">
						线程(根据设备配置调整)
					</div>
					<div class="title">
						<el-input-number v-model="thread " @change="threadChange" :min="1" :max="16" label="描述文字"></el-input-number>
					</div>

					<div class="title">
						<el-button v-if="radioAround === 1" type="primary" @click="createAccount()" :loading="isLoading">生成</el-button>
            <el-button v-else type="primary" @click="createAccountTow()" :loading="isLoading">生成</el-button>
						<el-button type="info" @click="suspend()">暂停</el-button>
					</div>

					<div v-if="address">  
						<div>
							<el-input v-model="address" disabled>
								<template slot="prepend">地址</template>
								<template slot="append">复制</template>
							</el-input>
						</div>
						<div>
							<el-input v-model="privateKey" disabled>
								<template slot="prepend">私钥</template>
								<template slot="append">复制</template>
							</el-input>
						</div>
					</div>  

        </div>

			</div>
		</div>
	</div>

</template>

<script>
import { Wallet } from 'ethers'
import Navigation from '../../components/Navigation'

export default {
	name: 'stardand',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '',
				},
			],
		}
	},

	data() {
		return {
			isLoading: false,

			inputNum: null,
			radioAround: 1,
			address: null,
			privateKey: null,
			intervalId: null,
			thread: 4,
		}
	},

	async created() {
		
    // console.log(decToHex(97))
	},

	beforeDestroy() {  
		this.suspend()
	},

	computed: {
		title() {
			return this.$t('title.stardand')
		},
	},

	async mounted() {
		
	},
	methods: {
		//createAccount
		createAccount() {
			if (!this.inputNum) {
				this.$message.error('请输入要生成的靓号')
				return
			}
			this.address = null
			this.isLoading = true
			this.intervalId = setInterval(() => {
				if (this.address === null) {
					this.startGenerating()
				}else{
					this.isLoading = false
					return
				}
      }); // 每1秒执行一次  
      return this.intervalId
    },

    createAccountTow() {
			if (!this.inputNum) {
				this.$message.error('请输入要生成的靓号')
				return
			}
			this.address = null
			this.isLoading = true
			this.intervalId = setInterval(() => {
				if (this.address === null) {
					this.endGenerating()
				}else{
					this.isLoading = false
					return
				}
      }); // 每1秒执行一次  
      return this.intervalId
    },

		suspend() {
			if (this.intervalId) {
				this.isLoading = false
        clearInterval(this.intervalId); 
        this.intervalId = null;
      }
    },

		startGenerating() {
      const wallet = Wallet.createRandom()
			let account = wallet.address;
			if (this.radioAround === 1 && account.substring(2).startsWith(this.inputNum)) {
				console.log('111');
				this.address = account
				this.privateKey = wallet.privateKey
				return
			}
			// console.log(account);
    },

    endGenerating() {
      const wallet = Wallet.createRandom()
			let account = wallet.address;
			if (this.radioAround === 2 && account.endsWith(this.inputNum)) {
				console.log('222');
				this.address = account
				this.privateKey = wallet.privateKey
				return
			}
			// console.log(account);
    },

		threadChange(value) {
			console.log(value)
		},

	},
}
</script>

<style scoped>
.stardand {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 900px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}

.senderMain {
  padding: 5px 30px 30px 30px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .titleHe{
  font-size: 22px;
  margin: 20px 0;
  text-align: center;
}
.senderMain .title{
  font-size: 16px;
  margin: 20px 0;
  text-align: center;
}
.senderMain .titleNum{
  width: 300px;
	text-align: center;
	margin: 0 auto;
}

</style>