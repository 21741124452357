<template>
	<div class="batchMultiSender">
		<Navigation></Navigation>
		<div class="mainSender">
			<div class="container">
        <div class="senderTop">
          <div class="title"> {{$t('title.batchMultiSender')}} </div>
          <el-steps align-center :active="stepNum" process-status="success" class="steps">
            <el-step :title="$t('batchTools.transfer.name1')"></el-step>
            <el-step :title="$t('batchTools.transfer.name2')"></el-step>
            <el-step :title="$t('batchTools.transfer.name3')"></el-step>
          </el-steps>
        </div>
        <div class="senderMain" v-if="stepNum === 0">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
              <div class="tips"> {{$t('batchTools.transfer.name4')}} </div>
              <el-select v-model="chainNumber" class="chainNumBox" @change="setChainId">
                <el-option v-for="(item, index) in chainlistAll" :label="item.chainName"  :key="'chainNumber' + index" :value="item.id">
                  <span class="itemChainLogo" style="float: left; color: #8492a6; font-size: 13px; margin-right: 5px;">
                    <img :src="item.chainLogo" style="width: 21px;margin-top: 6px;border-radius: 50%;">
                  </span>
                  <span class="itemChainName" style="float: left;">{{ item.chainName }}</span>
                </el-option>
              </el-select>
              <img class="chainLogo" :src="chainLogo">
            </el-col>
            <el-col :xs="24" :sm="18" :md="18" :lg="18" :xl="18">
              <div class="tips"> {{$t('batchTools.transfer.name5')}} 
                <span class="isArrAmount">
                 {{ tokenBalance }} {{ tokenSymbol }}
                </span>
              </div>
              <el-select v-model="tokenNum" class="tokenNumBox" 
                filterable 
                remote 
                :loading="isloading" 
                :remote-method="addSearchToken" 
                :loading-text="$t('batchTools.transfer.name32')"
                @visible-change="chooseToken">
                <el-option v-for="(item, index) in tokenArr" :label="item.symbol +' '+ item.tokenAddr" :key="'tokenNum' + index+item.symbol" :value="item.id">
                  <span class="tokenNumL">{{ item.symbol }} {{ item.tokenAddr }}</span>
                </el-option>
              </el-select>
            </el-col>
          </el-row>

          <div class="tipsAddr"> {{$t('batchTools.transfer.name6')}} 
            <span @click="isUploadFiles = !isUploadFiles ? true : false">
              <div v-if="isUploadFiles"> {{$t('batchTools.transfer.name7')}} </div>
              <div v-else> {{$t('batchTools.transfer.name33')}} </div>
            </span>
          </div>
          <div class="edit-container" v-if="isUploadFiles">
            <div class="edit">
              <div class="leftBox"><textarea wrap="off" cols="2" id="leftNum" disabled></textarea></div>
              <textarea
                :placeholder="$t('batchTools.transfer.name6')"
                @input="handleTextareaInput"
                v-model="inputWalletArr"
                onscroll="document.getElementById('leftNum').scrollTop = this.scrollTop;"
                spellcheck="false"
                class="area-content">
              </textarea>
            </div>
          </div>
          <!--上传钱包地址-->
          <div v-else class="uploadFiles">
            <el-upload
              :on-success="handleSuccess"  
              :before-upload="beforeUpload"  
              class="uploadBn"
              drag
              action="/"
              multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text"> {{$t('batchTools.transfer.name8')}} </div>
              <div class="el-upload__text"> {{$t('batchTools.transfer.name9')}} </div>
            </el-upload>
          </div>


          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="tips">
                {{$t('batchTools.transfer.name10')}}  
                <span class="isArrAmount">
                  <el-switch v-model="isSameAmount" :inactive-text="$t('batchTools.transfer.name11')" active-color="#E6A23C" inactive-color="#E6A23C" :active-text="$t('batchTools.transfer.name12')">
                  </el-switch>
                </span>
              </div>
              <div v-if="isSameAmount">
                <el-input class="sameInp" v-model="sendAmount" :placeholder="$t('batchTools.transfer.name34')"></el-input>
                <el-button class="sameBn" type="warning" @click="sendSameNum()" plain> {{$t('batchTools.transfer.name13')}} </el-button>
              </div>
              <div v-else >
                <el-input class="differentInp" :placeholder="$t('batchTools.transfer.name35')" v-model="minSendValue"></el-input>
                <el-input class="differentInp" :placeholder="$t('batchTools.transfer.name36')" v-model="maxSendValue"></el-input>
                <el-button class="differentBn" type="warning" @click="sendDifferentNum()" plain> {{$t('batchTools.transfer.name13')}} </el-button>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="tips"><span><el-button type="text" @click="viewExamples">{{$t('batchTools.transfer.name14')}}  </el-button></span></div>
            </el-col>
          </el-row>

          <div class="nextStepBn">
            <el-button type="warning" @click="nextStepOne"> {{$t('batchTools.transfer.name15')}} </el-button>
            <!-- <el-button type="warning" @click="exportexcel">下载 Excel</el-button> -->
          </div>

        </div>
        <div class="senderMain" v-else-if="stepNum === 1">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="classNum">
                <div class="tipsNam">{{ walletArr.length }}</div>
                <div class="tipsNer"> {{$t('batchTools.transfer.name16')}} </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="classNum">
                <div class="tipsNam">{{ totalSendValue }} <span>{{ tokenSymbol }}</span></div>
                <div class="tipsNer"> {{$t('batchTools.transfer.name17')}} </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="classNum">
                <div class="tipsNam">1</div>
                <div class="tipsNer"> {{$t('batchTools.transfer.name18')}} </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="classNum">
                <div class="tipsNam">{{ tokenBalance }} <span>{{ tokenSymbol }}</span></div>
                <div class="tipsNer"> {{$t('batchTools.transfer.name19')}} </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="classNum">
                <div class="tipsNam">{{ estimateGas }} <span>{{ mainSymbol }}</span></div>
                <div class="tipsNer"> {{$t('batchTools.transfer.name20')}} </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
              <div class="classNum">
                <div class="tipsNam">{{ gasPriceNum }} <span>Gwei</span></div>
                <div class="tipsNer">Gas Price</div>
              </div>
            </el-col>
          </el-row>

          <template>
            <el-table
              :data="walletArr"
              border
              class="tableMain">
              <el-table-column
                label="ID"
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                prop="address"
                :label="$t('batchTools.transfer.name21')"
                width="380">
              </el-table-column>
              <el-table-column
                prop="amount"
                :label="$t('batchTools.transfer.name22')"
                width="180">
              </el-table-column>
              <el-table-column
                prop=""
                :label="$t('batchTools.transfer.name23')">
                <template slot-scope="scope"><el-button size="mini" type="danger" icon="el-icon-delete" @click="setDelete(scope.$index)" circle></el-button></template>
              </el-table-column>
              <div slot="empty">
                <p> {{$t('batchTools.transfer.name37')}} </p>
              </div>
            </el-table>
          </template>

          <div v-if="tokenSymbol !== mainSymbol && isApprove === false">
            <template>
              <div class="approveRadioBn">
                <el-radio v-model="isApproveRadio" @change="getAllowanceAmount" label="1" border> {{$t('batchTools.transfer.name27')}}  {{ tokenSymbol }}   {{$t('batchTools.transfer.name28')}} </el-radio>
                <el-radio v-model="isApproveRadio" @change="getAllowanceAmount" label="2" border> {{$t('batchTools.transfer.name29')}}  {{ totalSendValue }} {{ tokenSymbol }} </el-radio>
              </div>
            </template>
          </div>

          <el-row :gutter="20">
            <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4" class="nextReturn">
              <el-button type="warning" @click="nextStepReturn" plain> <i class="el-icon-back"></i> </el-button>
            </el-col>
            <div v-if="tokenSymbol === mainSymbol || isApprove === true">
              <el-col :xs="18" :sm="20" :md="20" :lg="20" :xl="20" class="nextSending">
                <el-button type="warning" @click="setMulti"> {{$t('batchTools.transfer.name26')}} </el-button>
              </el-col>
            </div>
            <div v-else>
              <el-col :xs="18" :sm="20" :md="20" :lg="20" :xl="20" class="nextSending">
                <el-button type="primary" @click="approveToken" :loading="isloading"> {{$t('batchTools.transfer.name25')}} </el-button>
              </el-col>
            </div>
          </el-row>

        </div>

        <div class="senderMain" v-else-if="stepNum === 2">
          <template>
            <el-table 
              v-loading="isloading"
              :data="walletArr"
              border
              class="tableMain">
              <el-table-column
                label="ID"
                type="index"
                width="50">
              </el-table-column>
              <el-table-column
                prop="address"
                :label="$t('batchTools.transfer.name21')"
                width="380">
              </el-table-column>
              <el-table-column
                prop="amount"
                :label="$t('batchTools.transfer.name22')"
                width="180">
              </el-table-column>
              <div slot="empty">
                <p> {{$t('batchTools.transfer.name37')}} </p>
              </div>
            </el-table>
          </template>

          <el-row :gutter="20">
            <el-col :xs="6" :sm="4" :md="4" :lg="4" :xl="4" class="nextReturn">
              <el-button type="warning" @click="nextStepReturn" plain> <i class="el-icon-back"></i> </el-button>
            </el-col>
            <el-col :xs="18" :sm="20" :md="20" :lg="20" :xl="20" class="nextSending">
              <div v-if="hastTx ===''">
                <el-button type="warning" :loading="isloading"> {{$t('batchTools.transfer.name30')}} </el-button>
              </div>
              <div v-else>
                <a :href="hastTx" target="_blank" rel="noopener">
                  <el-button type="success"> {{$t('batchTools.transfer.name31')}} </el-button>
                </a>
              </div>
            </el-col>
          </el-row>

        </div>

			</div>
		</div>
	</div>
</template>

<script>
import { ethers } from 'ethers'
import Navigation from '../components/Navigation'
import * as XLSX from 'xlsx'
import { BigNumber , formatUnits, MAX_INT, parseUnits, decToHex} from '../utils/utils.js'
import ercABI from '../abi/ERC20.json'
import chainlistData from '../abi/mutilTransfer/multiSenderChainList.json'
import mutilTransABI from '../abi/mutilTransfer/mutilTransferABI.json'

export default {
	name: 'batchMultiSender',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '批量查询钱包余额，通过钱包地址查询余额',
				},
			],
		}
	},

	data() {
		return {
      //链
      chainNumber:JSON.parse(localStorage.getItem('chainNumber')) || '0',
      chainlistAll: JSON.parse(localStorage.getItem('chainlistAll')) || chainlistData,
      chainLogo:'',
      tokenNum:'0',
      provider:{},
      signer:{},
      account:'',

      gasPriceNum:'',
      estimateGas:'',
      fee:'',
      hastTx:'',
      isApprove:false,
      isApproveRadio:'1',
			//所有代币地址
			tokenArr: [],
			//全部钱包地址
			inputWalletArr: '',
      walletArr:[],
      allAddrs:[],
      allAmountPlus:[],
			//查询结果
			searchResult: [],
			//是加载中
      isUploadFiles:true,
			isloading: false,
      isDeflation: false,
      stepNum: 0,

      isSameAmount: true,
      sendAmount: '',
      randomNumber:'',
      minSendValue: '', // 最小值  
      maxSendValue: '', // 最大值  
      
      mainSymbol:'',
      tokenBalance:'',
      tokenSymbol:'',
      tokenDecimals:'18',
      tokenContractAddr:'',
      multiContractAddr:'',
      num: '',
		}
	},

	async created() {
    if (window.ethereum) {  
      window.ethereum.on('chainChanged', (chainId) => this.handleChainChanged(chainId))
      window.ethereum.on('accountsChanged', (accounts) => this.accountsChanged(accounts))
    }
    // console.log(decToHex(97))
	},
  beforeDestroy() {  
    if (window.ethereum) {  
      window.ethereum.off('chainChanged', this.handleChainChanged)
      window.ethereum.off('accountsChanged', this.accountsChanged)
    }  
  },
	computed: {
		title() {
			return this.$t('title.batchMultiSender')
		},
    totalSendValue() {
      if (this.walletArr.length === 0 || this.walletArr[0].amount === undefined) {
        return 0
      }else{
        return Math.round(this.walletArr.reduce((sum, item) => sum + Number(item.amount), 0) * 10000000)/ 10000000;  
      }
    },
	},
  async mounted() {
    await this.initAccount()
    if (this.chainNumber === `${this.$t("tokenPublic.token134")}`) {
      this.chainLogo = './img/chainIcon/not.png'
      return
    }
    this.initData()
  },
	methods: {
    async handleChainChanged(chainId) {  
      // 当链变化时，更新组件的chainNumber数据
      const chainIdB = Number(BigNumber(chainId).toString())
      const selectedChain = this.chainlistAll.find(item => item.chainId === chainIdB)
      if (!selectedChain) {
        this.chainNumber = `${this.$t("tokenPublic.token134")}`
        this.chainLogo = './img/chainIcon/not.png'
        return;
      }
      this.chainNumber = selectedChain.id;
      this.tokenNum = '0'
      await this.initAccount()
      this.initData()
    },
    async accountsChanged() {  
      this.tokenNum = '0'
      await this.initAccount()
      this.initData()
    },
    //初始化数据
    async initData() {
      this.tokenArr = this.chainlistAll[this.chainNumber].tokenArr
      this.chainLogo = this.chainlistAll[this.chainNumber].chainLogo
      this.mainSymbol =this.chainlistAll[this.chainNumber].nativeCurrency.symbol
      this.tokenSymbol = this.tokenArr[this.tokenNum].symbol
      this.tokenDecimals = this.tokenArr[this.tokenNum].decimals
      this.tokenContractAddr = this.tokenArr[this.tokenNum].tokenAddr
      this.multiContractAddr = this.chainlistAll[this.chainNumber].mutilTransferAddr
    },
    //输入换行自动序号
    handleTextareaInput(e) {
      let str = e.target.value;
      str = str.replace(/\r/gi, "");
      str = str.split("\n");
      let n = str.length;
      let lineobj = document.getElementById("leftNum");
      for (let i = 1; i <= n; i++) {
        if (document.all) {
          this.num += i + "\r\n";   //判断浏览器是否是IE
        } else {
          this.num += i + "\n";
        }
      }
      lineobj.value = this.num;
      this.num = "";
    },
    //获取Gas Price
    async getGasPriceFn(){
      let gas = await this.provider.getGasPrice()
      gas = Math.ceil(formatUnits(gas, "gwei") * 100) / 100
      this.gasPriceNum = gas
    },
    //获取 预估Gas   获取fee
    async getEstimateGas(){
      const multiContract = new ethers.Contract(this.multiContractAddr, mutilTransABI, this.signer)
      const fee  = await multiContract.fee()
      this.fee = formatUnits(fee.toString(), '18')
      const gasHs = parseUnits(this.gasPriceNum.toString(), "gwei")
      console.log(this.fee);
      if (this.tokenContractAddr ==='') {//发主币
        if (this.isSameAmount) {//相同数量ETH'
          console.log('相同数量ETH');
          const sendAmount = parseUnits(this.sendAmount,this.tokenDecimals)
          const totalSendValue = parseUnits(this.totalSendValue.toString(),this.tokenDecimals)
          const sendAmountAndFer = BigNumber(fee).add(totalSendValue)
          const txParams = { gasPrice: BigNumber(gasHs), value:sendAmountAndFer}
          const gas  = await multiContract.estimateGas.transferEth(this.allAddrs, sendAmount, txParams)
          this.estimateGas = formatUnits(gas, "gwei")
        } else {//随机数量ETH'
          console.log('随机数量ETH');
          const sendAmount = parseUnits(this.totalSendValue.toString(),this.tokenDecimals)
          const sendAmountAndFer = BigNumber(fee).add(sendAmount)
          console.log(sendAmountAndFer.toString())
          const txParams = { gasPrice: BigNumber(gasHs), value:sendAmountAndFer}
          const gas  = await multiContract.estimateGas.transferProEth(this.allAddrs, this.allAmountPlus, txParams)
          this.estimateGas = formatUnits(gas, "gwei")
        }
      } else {//发其他TOKEN
        if (this.isSameAmount) {//相同数量token
          console.log('相同数量token');
          const sendAmount = parseUnits(this.sendAmount,this.tokenDecimals)
          const txParams = { gasPrice: BigNumber(gasHs), value:BigNumber(fee)}
          const gas  = await multiContract.estimateGas.transferToken(this.tokenContractAddr,this.allAddrs, sendAmount, txParams)
          this.estimateGas = formatUnits(gas, "gwei")
        } else {//随机数量token
          console.log('随机数量token');
          const txParams = { gasPrice: BigNumber(gasHs), value:BigNumber(fee)}
          const gas  = await multiContract.estimateGas.transferProToken(this.tokenContractAddr,this.allAddrs, this.allAmountPlus, txParams)
          this.estimateGas = formatUnits(gas, "gwei")
        }
      }
    },
    //发送批量转账
    async setMulti(){
      this.isloading = true
      const multiContract = new ethers.Contract(this.multiContractAddr, mutilTransABI, this.signer)
      const fee  = await multiContract.fee()
      const gasHs = parseUnits(this.gasPriceNum.toString(), "gwei")
      if (this.tokenContractAddr ==='') {//发主币
        if (this.isSameAmount) {//相同数量ETH'
          try {
            console.log('相同数量ETH');
            const sendAmount = parseUnits(this.sendAmount,this.tokenDecimals)
            const totalSendValue = parseUnits(this.totalSendValue.toString(),this.tokenDecimals)
            const sendAmountAndFer = BigNumber(fee).add(totalSendValue)
            const txParams = { gasPrice: BigNumber(gasHs), value:sendAmountAndFer}
            if (this.stepNum++ > 2) this.stepNum = 0;
            const receipt  = await multiContract.transferEth(this.allAddrs, sendAmount, txParams)
            await receipt.wait()
            console.log(receipt.hash)
            this.isloading = false
            this.hastTx = this.chainlistAll[this.chainNumber].blockExplorerUrls+'/tx/'+receipt.hash //哈希
          } catch (error) {
            this.$message.error(error.code)
            this.isloading = false
            return
          }
        } else {//随机数量ETH'
          try {
            console.log('随机数量ETH');
            const sendAmount = parseUnits(this.totalSendValue.toString(),this.tokenDecimals)
            const sendAmountAndFer = BigNumber(fee).add(sendAmount)
            const txParams = { gasPrice: BigNumber(gasHs), value:sendAmountAndFer}
            if (this.stepNum++ > 2) this.stepNum = 0;
            const receipt  = await multiContract.transferProEth(this.allAddrs, this.allAmountPlus, txParams)
            await receipt.wait()
            console.log(receipt.hash)
            this.isloading = false
            this.hastTx = this.chainlistAll[this.chainNumber].blockExplorerUrls+'/tx/'+receipt.hash //哈希
          } catch (error) {
            this.$message.error(error.code)
            this.isloading = false
            return
          }
        }
      } else {//发其他TOKEN
        if (this.isSameAmount) {//相同数量token
          try {
            console.log('相同数量token');
            const sendAmount = parseUnits(this.sendAmount,this.tokenDecimals)
            console.log(sendAmount);
            const txParams = { gasPrice: BigNumber(gasHs), value:BigNumber(fee)}
            if (this.stepNum++ > 2) this.stepNum = 0;
            const receipt  = await multiContract.transferToken(this.tokenContractAddr,this.allAddrs, sendAmount, txParams)
            await receipt.wait()
            console.log(receipt.hash)
            this.isloading = false
            this.hastTx = this.chainlistAll[this.chainNumber].blockExplorerUrls+'/tx/'+receipt.hash //哈希
          } catch (error) {
            this.$message.error(error.code)
            this.isloading = false
            return
          }
        } else {//随机数量token
          try {
            console.log('随机数量token');
            const txParams = { gasPrice: BigNumber(gasHs), value:BigNumber(fee)}
            if (this.stepNum++ > 2) this.stepNum = 0;
            const receipt  = await multiContract.transferProToken(this.tokenContractAddr,this.allAddrs, this.allAmountPlus, txParams)
            await receipt.wait()
            console.log(receipt.hash)
            this.isloading = false
            this.hastTx = this.chainlistAll[this.chainNumber].blockExplorerUrls+'/tx/'+receipt.hash //哈希
          } catch (error) {
            this.$message.error(error.code)
            this.isloading = false
            return
          }
        }
      }
    },
    //查询授权数量
    async getAllowanceAmount(){
      let approveAmount = ''
      if (this.isApproveRadio === '1') {
        approveAmount = MAX_INT
      }else{
        approveAmount = parseUnits(this.totalSendValue.toString(),this.tokenDecimals)
      }
      const tokenContract = new ethers.Contract(this.tokenContractAddr, ercABI, this.signer)
      const allowanceAmount  = await tokenContract.allowance(this.account,this.multiContractAddr)
      if (+allowanceAmount.toString() >= BigNumber(approveAmount)) {
        this.getEstimateGas()
        this.isApprove = true
        return
      }
      this.isApprove = false
      this.estimateGas = '0'
    },
    //授权
    async approveToken(){
      this.isloading = true
      let approveAmount = ''
      if (this.isApproveRadio === '1') {
        approveAmount = MAX_INT
      }else{
        approveAmount = parseUnits(this.totalSendValue.toString(),this.tokenDecimals)
      }
      try {
        const tokenContract = new ethers.Contract(this.tokenContractAddr, ercABI, this.signer)
        const receipt  = await tokenContract.approve(this.multiContractAddr,approveAmount)
        await receipt.wait()
        console.log(receipt.hash)
        this.isloading = false
        this.getAllowanceAmount()
      } catch (error) {
        this.$message.error(`${this.$t('batchTools.pubilc.num80')}:${error.code}`)
        this.isloading = false
      }
      
    },
    //下一步 第一步
    nextStepOne() {
      this.hastTx = ''
      if (this.inputWalletArr === '') {
        this.$message.error(this.$t('batchTools.transfer.name38'))
        return
      }
      const balances = parseUnits(this.tokenBalance.toString(),this.tokenDecimals)
      const totalSendNum = parseUnits(this.totalSendValue.toString(),this.tokenDecimals)
      if (+balances < +totalSendNum) {
        this.$message.error(this.$t('batchTools.transfer.name39'))
        return
      }
      if (this.isSameAmount) {
        if (this.sendAmount === '') {
          this.$message.error(this.$t('batchTools.transfer.name40'))
          return
        }
      } else {
        if (this.minSendValue === '' || this.maxSendValue === '') {
          this.$message.error(this.$t('batchTools.transfer.name41'))
          return
        }
      }
      if (this.stepNum++ > 2) this.stepNum = 0;
      if (this.tokenSymbol === this.mainSymbol) {
        this.getEstimateGas()
      }else{
        this.getAllowanceAmount()
      }
    },
    //返回 上一步
    nextStepReturn() {
      if (this.stepNum-- > 0) this.stepNum = 0;
      this.hastTx = ''
    },
    setDelete(id) {  
      this.walletArr = this.walletArr.filter((_, index) => index !== id)
      this.allAddrs = this.allAddrs.filter((_, index) => index !== id)
      this.allAmountPlus = this.allAmountPlus.filter((_, index) => index !== id)
    },
    //获取所有钱包
    getWalletArr() {
      this.walletArr = []
      this.allAddrs = []
      this.allAmountPlus = []
      let wallets= this.inputWalletArr.split('\n')
      for (let i = 0; i < wallets.length; i++) {
        let walletAddr = wallets[i].split(',')[0];
        let transferAmount = wallets[i].split(',')[1];
        let transferAmountPlus = parseUnits(transferAmount.toString(),this.tokenDecimals)
        this.walletArr.push(
          {
            address:walletAddr,
            amount:transferAmount,
            amountPlus:transferAmountPlus
          }
        );
        this.allAddrs.push(walletAddr);
        this.allAmountPlus.push(transferAmountPlus);
      }
    },
    //获取随机数
    getRandomNumbers(){
      let randomNumbers = Number(this.minSendValue) + (Number(this.maxSendValue) - Number(this.minSendValue)) * Math.random()
      return Math.floor(randomNumbers * 10000000) / 10000000
    },
    //跟新 发送随机数量到 textarea
    sendDifferentNum() {
      if (this.inputWalletArr ==='') {
        this.$message.error(this.$t('batchTools.transfer.name42'))
        return
      }
      this.walletArr = []
      let newAddressArr = this.inputWalletArr.split('\n')
      const newLines = newAddressArr.map(line => line.split(',')[0] +','+ this.getRandomNumbers())
      const newTextareaContent = newLines.join("\n");  
      this.inputWalletArr = newTextareaContent
      this.getWalletArr()
    },
    //跟新 发送固定数量到 textarea
    sendSameNum() {
      if (this.inputWalletArr ==='') {
        this.$message.error(this.$t('batchTools.transfer.name42'))
        return
      }
      this.walletArr = []
      let newAddressArr = this.inputWalletArr.split('\n')
      const newLines = newAddressArr.map(line => line.split(',')[0] +','+ this.sendAmount)
      const newTextareaContent = newLines.join("\n");  
      this.inputWalletArr = newTextareaContent
      this.getWalletArr()
    },
    //上传文本 txt 和表格文件 xlsx 到 textarea
    beforeUpload(file) {
      const type = file.name.substring(file.name.lastIndexOf('.') + 1)
      const reader = new FileReader()
      if (type === 'txt') {
        reader.onload = (e) => {  
          this.inputWalletArr = e.target.result; // 文件内容将存储在content中  
          this.isUploadFiles = true
        };  
        reader.readAsText(file); // 读取文件内容为文本格式  
        return true; // 允许上传继续  
      }
      if (type === 'xlsx') {
        reader.onload = (ev) => {
          try {
            const data = ev.target.result
            const workbook = XLSX.read(data, {
              type: 'binary'
            })
            const wsname = workbook.SheetNames[0]
            const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname])// 生成json表格内容
            let str = ''
            ws.forEach((item) => {
              str += Object.values(item) + '\n'
            })
            this.inputWalletArr = str
            this.isUploadFiles = true
          } catch (e) {
            return false
          }
        }
        reader.readAsBinaryString(file)// 读取文件内容为文本格式  
        return true; // 允许上传继续  
      }
      this.isUploadFiles = true
    },  
    handleSuccess(file) {  
      console.log(file);
      // 这里可以处理服务器返回的数据，如果需要的话。  
    },
    viewExamples() {  
      this.inputWalletArr = "0x6024B69AC76B38aCD9caFCfac9f46D4983D1d98A\n0xe807e69f763cfCae4d3cdb0983865AA14Dc62F77\n0x3458Bd6f3D203475E8c79eDF87D1c742Fe4E07f7"
      this.sendAmount = '0.0001'
      this.sendSameNum()
    },
    //切换token
    async addSearchToken(inpAddress){
      if (inpAddress !== '') {
        this.isloading = true
        let newTokens = this.tokenArr.filter((item) => item.tokenAddr === inpAddress)
        if (newTokens.length !== 0) {
          this.isloading = false
          this.tokenArr = newTokens
          return
        }else{
          try{
            const tokenContract = new ethers.Contract(inpAddress, ercABI, this.provider)
            const symbolToken = await tokenContract.symbol()
            const decimalsToken  = await tokenContract.decimals()
            const nameToken  = await tokenContract.name()
            console.log(symbolToken,decimalsToken,inpAddress)
            const ids = this.tokenArr.length
            const tokenPush = {
              id:ids,
              name: nameToken,
              symbol: symbolToken,
              decimals: decimalsToken,
              tokenAddr: inpAddress
            }
            this.tokenArr.push(tokenPush)
          }catch(e){
            this.$message.error(this.$t('batchTools.transfer.name43'))
          }
        }
      }
      this.isloading = false
    },
    chooseToken(){
      this.tokenArr = this.chainlistAll[this.chainNumber].tokenArr
      this.tokenSymbol = this.tokenArr[this.tokenNum].symbol
      this.tokenDecimals = this.tokenArr[this.tokenNum].decimals
      this.tokenContractAddr = this.tokenArr[this.tokenNum].tokenAddr
      this.getBalanceOf()
    },
    async getBalanceOf(){
      if (this.tokenContractAddr === '') {
        const balance = await this.provider.getBalance(this.account)
        this.tokenBalance = Math.floor(formatUnits(balance, 18)  * 10000000 ) / 10000000
      }else{
        const tokenContract = new ethers.Contract(this.tokenContractAddr, ercABI, this.provider)
        const balance  = await tokenContract.balanceOf(this.account)
        this.tokenBalance = Math.floor(formatUnits(balance, this.tokenDecimals)  * 10000000 ) / 10000000
      }
    },
    //切换链
    async setChainId(){
      this.switchChain()
    },
    // ----------切换链-------------
		async switchChain() {
			if (window.ethereum) {
        console.log('----------- switchChain -------------')
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          this.getBalanceOf()
          this.getGasPriceFn()
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: decToHex(Number(this.chainlistAll[this.chainNumber].chainId)) }],
          })
				} catch (switchError) {
          if (switchError.code === 4902) {
            this.$message.error(switchError.message)
            try {
              await window.ethereum.request({
                "method": 'wallet_addEthereumChain',
                "params": [
                  {
                    "chainId": decToHex(Number(this.chainlistAll[this.chainNumber].chainId)),
                    "chainName": this.chainlistAll[this.chainNumber].chainName,
                    "rpcUrls": this.chainlistAll[this.chainNumber].rpcUrls,
                    "nativeCurrency": {
                      "name": this.chainlistAll[this.chainNumber].nativeCurrency.name,
                      "symbol": this.chainlistAll[this.chainNumber].nativeCurrency.symbol,
                      "decimals": this.chainlistAll[this.chainNumber].nativeCurrency.decimals
                    },
				            "blockExplorerUrls":this.chainlistAll[this.chainNumber].blockExplorerUrls
                  },
                ],
              });
            } catch (addError) {
              this.$message.error(addError.code)
            }
          }
					// User denied account access
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
      
			// Verify Accounts!
		},
    // ----------初始化账户-------------
		async initAccount() {
			if (window.ethereum) {
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          this.getBalanceOf()
          this.getGasPriceFn()
          //获取链ID
          let chainId = await window.ethereum.request({ method: 'eth_chainId' })
          let chainIdB = Number(BigNumber(chainId).toString())
          let selectedChain = this.chainlistAll.find(item => item.chainId === chainIdB)
          if (!selectedChain) {
            this.chainNumber = `${this.$t("tokenPublic.token134")}`
            return;
          }
          this.chainNumber = selectedChain.id;
          console.log('chainNumber: ' + this.chainNumber)
				} catch (switchError) {
          this.$message.error(switchError.code)
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
			// Verify Accounts!
		},
		//导出excel
		exportexcel() {
			if (this.searchResult.length == 0) {
				this.$message(this.$t('batchMultiSender.exportExcelPrompt'))
				return
			}
			/** 从表生成工作簿对象*/
			const wb = XLSX.utils.table_to_book(
				document.querySelector('#outExcel'),
				{
					raw: true,
				}
			)
			/** 获取二进制字符串作为输出 */
			const wbout = XLSX.write(wb, {
				bookType: 'xlsx',
				bookSST: true,
				type: 'array',
			})
			try {
				FileSaver.saveAs(
					new Blob([wbout], { type: 'application/octet-stream' }),
					//设置导出文件名称
					'钱包余额表格.xlsx'
				)
			} catch (error) {}
			return wbout
		},

	},
  watch:{
    //监听 chainNumber 本地持久化
    chainNumber:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainNumber',JSON.stringify(newVal))
      }
    },
    chainlistAll:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainlistAll',JSON.stringify(newVal))
      }
    }
  },
}
</script>

<style scoped>
.batchMultiSender {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 900px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}
.senderTop {
  max-width: 900px;
  width: 100%;
	margin: 5px auto;
}
.senderTop .title{
  max-width: 900px;
  width: 100%;
	display: flex;
	justify-content: center;
  font-size: 22px;
  line-height: 50px;
}
.senderTop .steps{
  text-align: center; 
  margin: 30px 0;
}
.senderMain {
  padding: 5px 30px 30px 30px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .differentInp{
  width: 35%;
}
.senderMain .differentBn{
  width: 30%;
}
.senderMain .sameBn{
  width: 30%;
}
.senderMain .sameInp{
  width: 70%;
}
.senderMain .tips{
  padding: 15px 0px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}
.senderMain .tips span{
  float: right;
}

.senderMain .tipsAddr{
  padding: 5px 0px;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
}
.senderMain .tipsAddr span{
  float: right;
  padding: 5px 8px;
  margin-left: 3px;
  border: 1px solid #ddd;
  background: #eee;
  margin-top: -5px;
}
.senderMain .chainNumBox{
  width: 100%;
}
::v-deep .chainNumBox .el-input__inner{
  padding-left: 30px;
}
.senderMain .chainLogo{
  position: absolute;  
  width: 18px;  
  height: 18px;
  top: 68px;
  left: 12px;
  border-radius: 50%;  
  margin-left: 5px;
  z-index: 999;
}
.senderMain .tokenNumBox{
  width: 100%;
}
.tokenNumL{
  float: left;
}
.senderMain .textareaContainer{
  position: relative;  
}
.senderMain .number{
  position: absolute;  
  left: 0;  
  top: 0;  
  padding-right: 5px; /* 根据需要调整文本框与序号之间的间距 */  
}
.senderMain .textArea {
  padding-left: 10px; /* 根据序号的大小和间距调整文本框左侧的间距 */
}
.senderMain .nextStepBn{
  margin-top: 20px;
}
.senderMain .nextStepBn button{
  margin-top: 20px;
	width: 100%;
}
.senderMain .nextReturn button{
  margin-top: 20px;
	width: 100%;
}
.senderMain .nextSending button{
  margin-top: 20px;
	width: 100%;
}
.senderMain .classNum{
  padding: 10px 0;
  margin: 10px 0;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  border-radius: 20px;
  text-align: center;
}
.senderMain .classNum .tipsNam{
  padding: 10px 0;
  font-size: 22px;
}
.senderMain .classNum .tipsNer{
  padding: 10px 0;
  font-size: 14px;
}
.senderMain .tableMain{
  width: 100%;
  margin: 10px 0;
}
.title {
	font-size: 18px;
	font-weight: 700;
	margin-bottom: 15px;
	position: relative;
}
.approveRadioBn label{
  width: 98%;
  margin: 10px;
}
.uploadFiles{
  width: 100%;
  margin: 9px 0;
  text-align: center;
}
.uploadBn{
  width: 100%;
  margin: 15px 0;
}
::v-deep .uploadBn .el-upload {
  width: 100% !important;
}
::v-deep .el-alert__title{
  line-height: 33px !important;
}
::v-deep .uploadBn .el-upload-dragger{
  width: 100% !important;
}

.edit-container {
  height: 372px;
  margin: 15px 0;
}

.edit {
  display: flex;
  height: 370px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.leftBox {
  width: 40px;
  height: 100%;
  text-align: left;
}

.area-content {
  padding: 10px 8px;
  width: 100%;
  height: 100%;
  font-size: 13px;
  line-height: 24px;
  color: #333;
  font-family: Consolas;
  border: none;
  background: #fff;
  box-sizing: border-box;
  outline: none;
  resize: none;
  white-space: nowrap;
}

#leftNum {
  padding: 10px 4px;
  height: 100%;
  width: 100%;
  line-height: 24px;
  font-size: 13px;
  text-align: center;
  color: #888;
  font-weight: bold;
  resize: none;
  outline: none;
  overflow-y: hidden;
  overflow-x: hidden;
  border: 0;
  background: #eee;
  box-sizing: border-box;
}
@media (max-width:768px){
  .tokenNumL{
    font-size: 10px;
  }
}
</style>