import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home.vue'
import Unitconvert from '../views/UintConvert.vue'
import Abi from '../views/ABI.vue'
import Traceview from '../views/TraceView.vue'
import Bulkquery from '../views/BulkQuery.vue'
import Notfind from '../views/Notfind.vue'
import Address from '../views/Address.vue'
import QuerySelector from '../views/QuerySelector.vue'
import GenerateWallet from '../views/GenerateWallet.vue'
import ConvertTopicID from '../views/ConvertTopicID.vue'
import HashTool from '../views/HashTool.vue'
import CalldataDecode from '../views/CalldataDecode.vue'
import Faucet from '../views/Faucet.vue'
import BTCWallet from "../views/generateWallet/BTCWallet.vue"
import EVMWallet from "../views/generateWallet/EVMWallet.vue"
import BeautifulWallet from "../views/generateWallet/BeautifulWallet.vue"
import InsertionTool from '../views/InsertionTool.vue'
import BatchCollection from '../views/BatchCollection.vue'
import AutoTrade from '../views/AutoTrade.vue'
import BatchApprove from '../views/BatchApprove.vue'
import BatchMultiSender from '../views/BatchMultiSender.vue'

import CreateToken from '../views/CreateToken.vue'

import Stardand from '../views/createToken/Stardand.vue'
import HoldReflection from '../views/createToken/HoldReflection.vue'
import LPBurn from '../views/createToken/LPBurn.vue'
import LPMine from '../views/createToken/LPMine.vue'
import LPwithInviter from '../views/createToken/LPwithInviter.vue'
import X314Protocol from '../views/createToken/X314.vue'

import TokenAdmin from '../views/TokenAdmin.vue'
import TokenList from '../views/tokenAdmin/TokenList.vue'
import StardandDetail from '../views/tokenAdmin/StardandDetail.vue'
import HoldReflectionDetail from '../views/tokenAdmin/HoldReflectionDetail.vue'
import LpWithInviterDetail from '../views/tokenAdmin/LpWithInviterDetail.vue'
import LpBurnDetail from '../views/tokenAdmin/LpBurnDetail.vue'
import LpMineDetail from '../views/tokenAdmin/LpMineDetail.vue'
import X314Detail from '../views/tokenAdmin/X314Detail.vue'

import Lock from '../views/Lock.vue'
import LockList from '../views/lock/List.vue'
import CreateLock from '../views/lock/Create.vue'
import LockDetail from '../views/lock/Detail.vue'

Vue.use(Router)

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    // mode: 'history',//去掉#号 但是打包后刷新页面404
    routes: [
        {
            path: '/',
            name: 'Home',
            component: Home,
        },
        {
            path: '/unitConvert',
            name: 'Unitconvert',
            component: Unitconvert
        },
        {
            path: '/unitConvert/:weiValue',
            name: 'Unitconvert-Value',
            component: Unitconvert
        },
        {
            path: '/abi/:currencySymbol/:address',
            name: 'Abi-Value',
            component: Abi
        },
        {
            path: '/abi',
            name: 'Abi',
            component: Abi
        },
        {
            path: '/traceview',
            name: 'Traceview',
            component: Traceview
        },
        {
            path: '/bulkQuery',
            name: 'Bulkquery',
            component: Bulkquery,
        },
        {
            path: '/address',
            name: 'AddresAndEMS',
            component: Address
        },
        {
            path: '/querySelector',
            name: 'QuerySelector',
            component: QuerySelector
        },
        {
            path: '/generateWallet',
            component: GenerateWallet,
            children:[
                {
                    path:'',
                    redirect:'evmWallet'
                },
                {
                    path:'evmWallet',
                    name:'evmWallet',
                    component:EVMWallet
                },
                {
                    path:'beautifulWallet',
                    name:'BeautifulWallet',
                    component:BeautifulWallet
                },
                {
                    path:'btcWallet',
                    name:'btcWallet',
                    component:BTCWallet
                }
            ]
        },
        {
            path: '/createToken',
            component: CreateToken,
            children:[
                {
                    path:'',
                    redirect:'stardand'
                },
                {
                    path:'stardand',
                    name:'Stardand',
                    component:Stardand
                },
                {
                    path:'holdReflection',
                    name:'HoldReflection',
                    component:HoldReflection
                },
                {
                    path:'lpBurn',
                    name:'LPBurn',
                    component:LPBurn
                },
                {
                    path:'lpMine',
                    name:'LPMine',
                    component:LPMine
                },
                {
                    path:'lpWithInviter',
                    name:'LPwithInviter',
                    component:LPwithInviter
                },
                {
                    path:'X314Protocol',
                    name:'X314Protocol',
                    component:X314Protocol
                }
            ]
        },
        {
            path: '/tokenAdmin',
            component: TokenAdmin,
            children:[
                {
                    path:'',
                    redirect:'tokenList'
                },
                {
                    path:'tokenList',
                    name:'TokenList',
                    component:TokenList
                },
                {
                    path:'stardandDetail/:coinAddress',
                    name:'StardandDetail',
                    component:StardandDetail
                },
                {
                    path:'holdReflectionDetail/:coinAddress',
                    name:'HoldReflectionDetail',
                    component:HoldReflectionDetail
                },
                {
                    path:'lpWithInviterDetail/:coinAddress',
                    name:'LpWithInviterDetail',
                    component:LpWithInviterDetail
                },
                {
                    path:'lpBurnDetail/:coinAddress',
                    name:'LpBurnDetail',
                    component:LpBurnDetail
                },
                {
                    path:'lpMineDetail/:coinAddress',
                    name:'LpMineDetail',
                    component:LpMineDetail
                },
                {
                    path:'X314Detail/:coinAddress',
                    name:'X314Detail',
                    component:X314Detail
                }
            ]
        },
        {
            path: '/lock',
            component: Lock,
            children:[
                {
                    path:'',
                    redirect:'lockList'
                },
                {
                    path:'list',
                    name:'LockList',
                    component:LockList
                },
                {
                    path:'create',
                    name:'CreateLock',
                    component:CreateLock
                },
                {
                    path:'detail/:token/:owner/:id',
                    name:'LockDetail',
                    component:LockDetail
                }
            ]
        },
        {
            path: '/topicID',
            name: 'ConvertTopicID',
            component: ConvertTopicID
        },
        {
            path: '/hashTool',
            name: 'HashTool',
            component: HashTool
        },
        {
            path: '/insertionTool',
            name: 'InsertionTool',
            component: InsertionTool
        },
        {
            path: '/batchCollection',
            name: 'BatchCollection',
            component: BatchCollection
        },
        {
            path: '/autoTrade',
            name: 'AutoTrade',
            component: AutoTrade
        },
        {
            path: '/batchApprove',
            name: 'BatchApprove',
            component: BatchApprove
        },
        {
            path: '/batchMultiSender',
            name: 'BatchMultiSender',
            component: BatchMultiSender
        },
        {
            path: '/calldata',
            name: 'CalldataDecode',
            component: CalldataDecode
        },
        {
            path: '/faucet',
            name: 'Faucet',
            component: Faucet
        },
        {
            path: '/*',
            name: 'Notfind',
            component: Notfind
        },
    ],
})