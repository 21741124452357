export default {
    pubilc: {
        usingHelp: "Help",
        copySauccessfully: "Copied",
        copyFailed: "Copy Failed",
        noData: "No data",
        cancel: "Cancel",
        sure: "OK",
        copy: "Copy",
        querying: "loading",
        btnInquire: "query",
        tool:"Tool",
        wallet:"Wallet"
    },
    title: {
        home: "Toolbox For Blockchain Developer",
        unitConvert: "Unit Converter",
        bulkQuery: "Batch Query Wallet Balance",
        traceview: "Tx trace viewer",
        abi: "ABI Caller",
        address: "Address & ENS Seek",
        querySelector: "Selector By Function Signature",
        topicID: "TopicID Querier",
        hashTool: "Hash Tools",
        calldata: "Input Calldata Decoder & Encoder",
        faucet: "Testnet Faucets",
        insertionTool: "Insertion Tool",
        batchCollection: "Batch Collection",
        batchApprove: "Batch Approve",
        autoTrade: "Auto Trade",
        batchMultiSender: "Token MultiSender",
        createToken: "Create tokens",
        stardand: "Stardard Token",
        holdReflection: "Hold Reflection",
        lpBurn: "Mint LPBurn",
        lpMine: "LP Mine",
        lpWithInviter: "LP With Inviter",
        tokenAdmin: "Token Admin",
        tokenList: "Token List",
        X314: "X314 Protocol",
        tokenLock: "Token Lock",
        lockList: "Lock List",
        createLock: "Create Lock"
    },
    navigation: {
        connectWallet: "Connect Wallet",
        nav: {
            home: "Home",
            languageSelection: "Lang",
            folloUs: "Follow",
        }
    },
    home: {
        title: ["OpenSource ", "Toolbox", "Make Blockchain Develop More Easier"],
        describe: "Open source is trustworthy, good to use to improve efficiency. The tools you need are here.",
        btnFeedback: "Feedback",
        subheadingToolList: "Tools",
        listDescribe: "Free & Efficiency, try it out",
        EVMWalletGenerator: 'EVM Wallet Generator',
        allTool: 'All Tool',
        hotTool: 'Hot Tool',
        toolList: [
            {
                title: 'Unit Converter',
                detail: 'ETH Unit Converter',
            },
            {
                title: 'Balance Querier',
                detail: 'Batch query wallet balance',
            },
            {
                title: 'Tx Trace Viewer',
                detail: 'Parse internal details of EVM transactions',
            },
            {
                title: 'ABI Caller',
                detail: 'Friendly generate callable functions for ABI',
            },
            {
                title: 'Address & ENS Seek',
                detail: 'Address lookup and ENS lookup',
            },
            {
                title: 'Wallet Generator',
                detail: 'Trustworthy & Batch EVM / BTC Wallet Generator',
            },
            {
                title: 'Selector Querier',
                detail: 'Function selector <=> Function signature',
            },
            {
                title: 'TopicID Querier',
                detail: 'Event TopicID <=> Event signature',
            },
            {
                title: 'Hash Tools',
                detail: 'Commonly used Hash Tools: keccak-256, base64 and so on...',
            },
            {
                title: 'Decoder & Encoder',
                detail: 'Encoding and decoding Tx Calldata',
            },
            {
                title: 'Testnet Faucets',
                detail: 'Testnet Faucet All in one',
            },
            {
                title: 'BTC Wallet Generator',
                detail: 'Trustworthy & Batch BTC Wallet Generator',
            },
            {
                title: 'Insertion Tool',
                detail: 'Insertion Tool',
            },
            {
                title: 'Batch Collection',
                detail: 'Batch Collection',
            },
            {
                title: 'Auto Trade',
                detail: 'Auto Trade',
            },
            {
                title: 'Batch Approve',
                detail: 'Batch Approve',
            },
            {
                title: 'Index',
                detail: 'Index',
            },
            {
                title: 'Batch Tools',
                detail: 'Batch Tools',
            },
            {
                title: 'Other tools',
                detail: 'Other tools',
            },
            {
                title: 'Token MultiSender',
                detail: 'Efficient Token multisender.',
            },
            {
                title: 'Wallet Tracking',
                detail: 'Wallet Tracking',
            },
            {
                title: 'Create Token',
                detail: 'Create Token',
            },
            {
                title: 'Stardard Token',
                detail: 'Stardard Token',
            },
            {
                title: 'Hold Dividends',
                detail: 'Hold Dividends',
            },
            {
                title: 'LP Dividends Inviter Rebate',
                detail: 'LP Dividends Inviter Rebate',
            },
            {
                title: 'Mint LP Burn',
                detail: 'Mint LP Burn',
            },
            {
                title: 'LP Mine Inviter Rebate',
                detail: 'LP Mine Inviter Rebate',
            },
            {
                title: 'Token Admin',
                detail: 'Token Admin',
            },
            {
                title: 'Token List',
                detail: 'Token List',
            },
            {
                title: 'X314 Protocol',
                detail: 'X314 Protocol',
            },
            {
                title: 'Beautiful Wallet',
                detail: 'Wallet Number Address Generator',
            },
            {
                title: 'Token Lock',
                detail: 'Token Lock',
            },
            {
                title: 'Lock List',
                detail: 'Lock List',
            },
            {
                title: 'Create Lock',
                detail: 'Create Lock',
            }
        ],
    },
    uintConvert: {
        title: "Unit Converter",
        describe: "The smallest unit of Ether is Wei in EVM, 1 Ether == 10^18 Wei, gwei usual as the unit when paying gas fee.",
        illustrate: ["The difference between ethereum and ether:", "Ethereum is an open blockchain platform that allows anyone to deploy or interact with smart contracts on it", "Ether is the native coin in Ethereum, ether used to pay for the gas fee"],
    },
    bulkQuery: {
        enterAddress: "Token Address",
        enterAddressPrompt: "Input your wallet address",
        enterWalletAddress: "Wallet",
        enterWalletAddressPrompt: "One address per line",
        inquireResult: "Result",
        list: ["Wallet", "Token", "Balance"],
        bntCheckBalance: "Get Balance",
        btnExportExcel: "Download Excel",
        exportExcelPrompt: "No balance data",
        checkBalancePrompt: ["No wallet address", "Token address error, please review"],
    },
    traceView: {
        title: "Tx Trace Viewer",
        transactionHash: "Tx Hash",
        inputTransactionHash: "Input transaction hash",
        btnAnalyze: "Analyze",
        options: "options",
        inputAddressMap: "Input address alias",
        inputFunctionMap: "Input function alias",
        example: "A example",
        AddressMap: "Address alias",
        FunctionMap: "Function alias",
        CallTraces: "Traces",
        prompt: ["input tx hash", "retry input tx hash and "],
        analyzing: "analyzing...",
        parsingFailed: ["An error has occurred", "Failed"]
    },
    abi: {
        title: "ABI Caller",
        btnAddContract: "Add Contract",
        btnShare: "Share",
        sharePrompt: "Share to friends, will auto load contract",
        btnABI: "Show ABI",
        ABIPrompt: "Click for show ABI, copy ABI",
        btnEtherscan: "Go etherscan",
        EtherscanPrompt: "Go etherscan for more details ",
        btnEdit: "edit",
        editPrompt: "Modify contract name, network , address, ABI",
        btnDelect: "Delete",
        delectPrompt: "Delete this contract",
        contractName: "Name",
        blockchainAddress: "Chain",
        contractAddress: "contract",
        callFunctions: "Function:",
        inputValue: "msg.value",
        pleaseChoose: "Choose",
        clearOutput: "Clean Output",
        run: "Run",
        transactionDetails: "Tx Details",
        returnContent: "Return:",
        contract: "Contract",
        commonABIs: "Commonly ABI",
        blockchainNetwork: "Chain",
        selectNetwork: "choose network",
        chooseCommonABI: "From Commonly ABI",
        uploadABIFile: "Upload ABI File",
        etherscanGet: "From Etherscan",
        lookOverABI: "Show ABI",
        jsonABI: "JSON ABI",
        readableABI: "Humanable ABI",
        inputPrompt: "Please input",
        quit: "Exit",
        validateName: "Contract name",
        sameName: "Contract name existed",
        validateAddress: "Please check contract address",
        checkAbi: "Input ABI",
        checkNetwork: "Choose network",
        autoAddError: 'Load contract error',
        promptSharingError: "Share Failed, please retry later",
        copyShareSuccess: "Copy share link success",
        examineAddressAndNetwork: "Please input contract address and network",
        getABIeroor: "Fail to get ABI ",
        useCurrentNetwork: "Network by wallet",
        checkAbiError: "ABI Error",
        notSelectedContractPrompt: "none contract selected",
        contractNotRecorded: "No source on Etherscan",
        deniedAccess: "User denied account access",
        notInstalledMetaMaskPrompt: "Need install MetaMask",
        abiError: "ABI Invalid",
        switchNetworkPrompt: "Network are mismatch, switch network ...",
        currentNetworkError: "Network error",
        connectionRequestError: "Network error, please retry connect and reload",
        btnReturn:"Return",
        btnDevelop:"Expand Panel",
        btnFold:"Collapse Panel",
        riskWarning:"The current contract is not open source verified, please confirm the risk!!!",
    },
    address: {
        address: "wallet address",
        btnConvert: "Lookup",
        btnInquire: "Lookup",
        inputENS: "ens domain",
        errorAddressPrompt: "invalid wallet address, please retype",
        errorENSPrompt: "No corresponding results were found."
    },
    generateWallet: {
        tips: ["Tips:  only run in your browser, totally", "Open Source", ". No one can get your mnemonic or private key, feel free to use."],
        randomBatch: "By Random",
        customize: "By Pri-key or Mnemonic",
        mnemonicLength: "How many mnemonic words",
        quantity: "Address numbers",
        btnGenerateImmediately: "Create",
        regenerate: "Re-create",
        exportexcel: "Download",
        walletsNumber: "Address numbers",
        subtitle: "Create Wallet by Private key or mnemonic",
        createNow: "Create",
        enterPrompt: "Private key or mnemonic",
        address: "Address",
        privateKey: "Pri-key",
        mnemonic: 'Mnemonic',
        nullErrorPrompt: "Invalid input",
        IllegalErrorPrompt: "Invalid address numbers",
        beyondErrorPrompt: "Address number too big, must be smaller than 100",
        inputErrorPrompt: "Create failed",
        popoverBtnClick: "Click",
        options:["One Mnemonic","Many Mnemonic","User Input"],
        advancedOptions:{
            title:"Advanced",
            tips:["Refer:","BIP44"],
            options:["Purpose","Coin","Account","External / Internal","Index","Path"]
        },
        messages:['Max is 100','Please wait...','Advanced is disable, where create by private key'],
        subtitleMnemonicCreate:"Create wallet by mnemonic",
        publicKey:"Public key",
    },
    querySelector: {
        title: "Selector Querier",
        inputFunctionName: 'Get selector by function signature',
        inputFunctionNamePrompt: "enter the function signature",
        inquire: "query",
        inputByteFunctionSelector: "Get function signature by selector",
        functionInputError: "Input error",
        noRecord: "no record",
        pointOutQuerying:"loading"
    },
    topicID: {
        title: "TopicID Querier",
        EventSignature: 'Get topicId by event signature',
        inputEventSignature: "Input Event Signature",
        TopicID: "Get event signature by topicId",
        InputTopicID: "Input topicId",
        signatureInputError: "Such as: event Transfer(address indexed from, address indexed to, uint256 amount)",
        topicIDInputError: "no record"
    },
    hashTool: {
        title: "Hash Tools",
        btnConfirm: "Confirm",
        coding: "Encoding",
        decoding: "Decoding",
        errorHexadecimal: "Invalid hex data",
        decodingError: "Decoding Error",
        noInput: "No input data"
    },
    calldata: {
        decoding: "Decoding",
        coding: "Encoding",
        inputFunction: "input function",
        inputFunctionPrompt: "function signaturs",
        parameter: "Params",
        inputParameterPrompt: "A param per line, Array like this:[0x2222,0x4444]",
        inputError: "Input Error",
        inputCalldata: "Calldata:",
        selectFunction: "Function",
        autoChoose: "Auto",
        manualInput: "Manual",
        decodingResult: "Result",
        parameter: "Param",
        parameterType: "Type",
        value: "Value",
        queryFailed: "An error has occurred",
    },
    faucet: {
        prompt: ["Request testnet coins and tokens for test you smart contract.", "ChainTool Faucets collet commonly used faucet websites for developers to enjoy.", "If you have a faucet website that we don't list, feel free to submit ", "PR", " or ", "ISSUE", ", Your support is our motivation."],
        currency: "Currency",
        url: "Website",
        remark: "Comment",
        operate: "Operate",
        receive: "Go",
        copyUrl: "Copy",
        copyURLSuccessfully: "URL Copied",
    },
    notfind: {
        title: "No Page Found",
        return: "Go back"
    },
    faucetData: {
        ethereum: "Ethereum",
        testnet: " Testnet",
        remarkGoerli: ["requires a minimum mainnet balance of 0.001 ETH", "by pow", "Twitter Auth,  faucet ETH, wETH, DAI, NFTs", "10 for those who have deployed contracts before 202211"],
        remarkSepolia: ["1 Sepolia ETH per day", "by pow", "10 for those who have deployed contracts before 202211"],
        remarkMumbai: ["0.5 Mumbai MATIC per day"],
        remarkHarmony: ["Shard 0、Shard 1、Shard 2、Shard 3"],
        remarkCelo: ["need github auth"]
    },
    connectWallet: {
        walleAccessDenied: "MetaMask refused",
        notInstalledMetaMask: "Please install MetaMask."
    },
    batchTools:{
        pubilc:{
            num1:"Import Privatekey",
            num2:"Import Wallet",
            num3:"Add Token",
            num4:"Add Chain",
            num5:"Delete local cache",
            num6:"Address",
            num7:"Action",
            num8:"Selected",
            num9:"Balance",
            num10:"Delete Selected",
            num11:"Delete All",
            num12:"Select Chain",
            num13:"RPC Settings",
            num14:"Choose Tokens",
            num15:"Token Contract",
            num16:"Choose Tokens",
            num17:"Check Balance",
            num18:"Receive Address",
            num19:"Transfer Yourself",
            num20:"MODE",
            num21:"Text Mode",
            num22:"Hexadecimal Mode",
            num23:"Get Gas",
            num24:"Start Batch Engraving",
            num25:"Native Tokens",
            num26:"Result (Tx)",
            num27:"Please import the private key first~",
            num28:"Token Contract",
            num29:"Selection Error",
            num30:"Send Amount",
            num31:"All Amounts",
            num32:"Starting batch collection",
            num33:"Tokens",
            num34:"Token Contract Address",
            num35:"Authorized Contract Address",
            num36:"Approved Amount",
            num37:"Start Batch Approval",
            num38:"Authorized Contract Address",
            num39:"Please import the wallet first~",
            num40:"Import private keys, one per line",
            num41:"Exchange",
            num42:"Token Management",
            num43:"Set Up",//科学家抢币设置
            num44:"Fast",
            num45:"Standard",
            num46:"Slow",
            num47:"Auto Gas Limit",
            num48:"Slippage Tolerance",
            num49:"Custom transaction amount",
            num50:"Custom unified acceptance address",
            num51:"Customize the current chain RPC",
            num52:"Batch redemption",
            num53:"Transaction routing",
            num54:"Minimum acquisition",
            num55:"Blockchain Name",//自定义链
            num56:"Native Token",
            num57:"Query failed",
            num58:"Chain ID",
            num59:"RPC Node",
            num60:"Browser URL",
            num61:"Add Exchange",//添加交易所
            num62:"Exchange",
            num63:"Exchange Name",
            num64:"Contract address",
            num65:"Contract address error",
            num66:"Add failed, please check if the chain is correct",
            num67:"Unexecuted",
            num68:"Successful",
            num69:"Failed",
            num70:"Waiting",
            num71:"Wrong",
            num72:"Sending transaction failed",
            num73:"Batch flash exchange",
            num74:"Monitoring approval",
            num75:"Not detected",
            num76:"Approved",
            num77:"Please enter the token contract address",
            num78:"Token not found",
            num79:"Routing contract address",
            num80:"Approval failed",
            num81:"Failed to obtain quantity",
            num82:"Transaction successful",
            num83:"Please enter the amount",
            num84:"View transaction hash",
            num85:"Please enter the amount",
            num86:"The main currency does not require approval",
            num87:"Receiving address error",
            num88:"The content sent cannot be empty",
        },
        transfer:{
            name1:"Prepare",
            name2:"Approve",
            name3:"Batch sending",
            name4:"Switch main chain",
            name5:"Token Contract",
            name6:"Payment address",
            name7:"Upload files",
            name8:"Drag the file here, or click upload",
            name9:"Supported file types: CSV/Excel/Txt",
            name10:"Send amount",
            name11:"Random",
            name12:"Same",
            name13:"Settings",
            name14:"View Demo",
            name15:"Next step",
            name16:"Total number of addresses",
            name17:"Total number of token sent",
            name18:"Total number of transactions",
            name19:"Token balance",
            name20:"Commission",
            name21:"Wallet address",
            name22:"Amount",
            name23:"Action",
            name24:"Return",
            name25:" Approve",
            name26:"Sending",
            name27:"Approve",
            name28:"Unlimited",
            name29:"Exact amount to be sent",
            name30:"On chain! Please wait a moment",
            name31:"Success! View Results",
            name32:"Loading ...",
            name33:"Manual input",
            name34:"Please enter the sending amount",
            name35:"Minimum Random Number",
            name36:"Maximum Random Number",
            name37:"Please enter your wallet first~",
            name38:"The receiving address is empty",
            name39:"Your current balance is not enough",
            name40:"The quantity sent cannot be empty",
            name41:"Sending random numbers cannot be empty",
            name42:"Please enter the receiving address",
            name43:"No search found",
        }
    },
    tokenPublic: {
        token0: "Tutorial",
        token1: "Standard Tokens",
        token2: "Clean contract, easy to use, tax free and function free, Ave testing all green",
        token3: "Dividend in local currency",
        token4: "Simple and clean contract, no black and white lists, no permissions, automatic opening with pooling, you can benefit from holding coins!",
        token5: "LP dividends + referral rewards",
        token6: "Subordinate trading, superior rewards, continuous fission, and scaling up!",
        token7: "Bottom pool combustion",
        token8: "Timely burning of the bottom pool, passive price increase, and community consensus building!",
        token9: "LP mining + recommendation rewards",
        token10: "Pooled mining, constant output, no front-end or back-end, fully decentralized operation",
        token11: "Chain",
        token12: "Name",
        token13: "Symbol",
        token14: "Total Supply",
        token15: "Decimals",
        token16: "Dividend tokens",
        token17: "Buy Tax",
        token18: "Marketing Tax",
        token19: "Liquidity Tax",
        token20: "Dividend tax",
        token21: "Burn Tax",
        token22: "Sell Tax",
        token23: "Referral Rewards",
        token24: "Referral Tax",
        token25: "Dividend Algebra",
        token26: "First ratio",
        token27: "Second ratio",
        token28: "Third ratio",
        token29: "Surplus ratio",
        token30: "Binding a recommendation relationship requires a certain amount of tokens to be transferred from the superior to the subordinate. When the subordinate returns, the binding is considered successful. Example: The default minimum transfer amount is 0. The superior can transfer any amount to the subordinate, and the subordinate can reverse any amount. The minimum transfer amount can be set on the console",
        token31: "Marketing Wallet",
        token32: "Current wallet",
        token33: "Select Exchange",
        token34: "Select Currency",
        token35: "Other Tokens",
        token36: "When adding liquidity, it is necessary to add transaction pairs between tokens and pool tokens, otherwise normal dividends and backflows cannot be carried out",
        token37: "open the trading",
        token38: "The first transaction after adding a pool needs to be manually opened in the console (if closed, trading can be carried out immediately after adding liquidity), and cannot be closed after the transaction is opened",
        token39: "Kill block",
        token40: "All addresses traded within n blocks after initiating transactions will be blacklisted to prevent robots from rushing to buy, and transactions must be manually initiated",
        token41: "Number of blocks",
        token42: "Tax switch",
        token43: "The tax rate can be manually adjusted after creating the token, and the buying and selling tax rates cannot exceed 25% each. This switch cannot be turned on again after it is turned off",
        token44: "Auto Airdrop",
        token45: "After turning on the switch, when the user trades, they will automatically drop small tokens to a random address to increase their coin holding address. A maximum of 5 tokens can be dropped at a time",
        token46: "Number of airdrops",
        token47: "Blacklist",
        token48: "The black part of the wallet address makes it unable to trade, and this switch cannot be turned on again after being turned off",
        token49: "Wallet Limit",
        token50: "The maximum number of tokens held by a single wallet can be set, and this switch cannot be turned on again after being turned off",
        token51: "Create Token",
        token52: "Fee",
        token53: "Create Contract",
        token54: "Contract address",
        token55: "Display after successful creation",
        token56: "Open source config",
        token57: "Enter block explorer",
        token58: "Tutorial",
        token59: "Copy Code",
        token60: "Copy construction parameters",
        token61: "The construction parameters cannot be retrieved. If not open source immediately, please copy and save to the local document",
        token62: "The total tax rate for purchases cannot exceed 25%",
        token63: "The total selling tax rate cannot exceed 25%",
        token64: "Creation failed",
        token65: "Tax rate explanation",
        token66: "Return tax rate: Tokens with a specified amount in the transaction will be automatically added to the liquidity pool, ensuring that the transaction always has liquidity",
        token67: "Marketing tax : The specified amount of tokens in the transaction will be automatically transferred to the marketing wallet.",
        token68: "Burn tax: The specified amount of tokens in the transaction will be entered into the dead address, realizing a deflation mechanism in disguise",
        token69: "Dividend tax: The specified amount of tokens in the transaction is used to purchase dividend tokens and send them to the holder",
        token70: "The total tax rate for purchases cannot exceed 25%, and the total tax rate for transactions cannot exceed 50%",
        token71: "Tokens to be distributed as dividends (this token must exist in a public chain native coin pool, such as BNB, ETH, etc.)",
        token72: "Recommendation reward explanation",
        token73: "Recommendation reward: Referees who receive mining reward addresses can receive the same amount of recommendation rewards",
        token74: "Minimum token holding amount: The minimum token holding amount. To reduce gas fees, only addresses with a token holding amount greater than the minimum holding amount can receive invitation rewards. 0 means that all referrals can receive rewards",
        token75: "Generation 1: Reward the immediate superior of the address, and so on for other generations",
        token76: "Other algebraic rewards: After filling in the percentage of rewards for the first three generations, the proportion of rewards for other algebras is the same",
        token77: "Calculation method: Other rewards for each generation are retained in whole digits, and the excess is given to the first generation, ensuring a total of 100%",
        token78: "Level",
        token79: "Calculate",
        token80: "LP Mine rewards",
        token81: "Total proportion",
        token82: "Daily rewards",
        token83: "Referral Rewards",
        token84: "Min Holds",
        token85: "Explanation of Mining Rewards",
        token86: "The proportion of total mining amount: the ratio of total mining rewards to supply, divided from the total supply",
        token87: "Used for mining and recommending rewards",
        token88: "Daily Mining Rewards: By default, each address can receive mining rewards every 24 hours, and the quantity obtained for each address is the daily mining reward * LP ratio",
        token89: "Minimum LP holding: defaults to 0, meaning all LPs can participate in mining. To reduce gas costs, it is recommended to modify it in the console after adding liquidity for the first time",
        token90: "Burning Setting",
        token91: "Combustion cycle",
        token92: "Hour",
        token93: "Percent",
        token94: "Burning cycle: How often should the bottom pool be burned, in hours, and when there is a sale",
        token95: "Burning percentage: The proportion of tokens destroyed during each burning of the bottom pool to the total number of tokens in the pool, with a minimum unit of one thousandth, which is 0.01%",
        token96: "To prevent the pool from burning too quickly and preventing transactions, the maximum burning percentage is 1%, and the minimum burning cycle is 1 hour, which means that the pool token can be destroyed up to 24% per day",
        token97: "For example, with a burning percentage of 1% and a burning cycle of 1 hour, there are 100 tokens in the pool. With the same amount of buying and selling, the remaining balance for each day is approximately 76,57,43",
        token98: "The actual increase is subject to real-time changes in trading volume and cannot be accurately estimated",
        token99: "Confirm to close?",
        token100: "Token List",
        token101: "If you encounter token failure to open source or other usage issues, please enter the communication group to provide feedback: https://t.me/wuhaxi",
        token102: "Communication groups",
        token103: "List of All Tokens",
        token104: "Contract address",
        token105: "Name",
        token106: "Symbol",
        token107: "Template",
        token108: "Creator",
        token109: "Time",
        token110: "Check",
        token111: "My Token List",
        token112: "Manage",
        token113: "Token Details",
        token114: "Name",
        token115: "Symbol",
        token116: "TotalSupply",
        token117: "Decimals",
        token118: "Template",
        token119: "Creator",
        token120: "Contract address",
        token121: "Copy Link",
        token122: "Exchange",
        token123: "Pool address",
        token124: "Bottom pool token",
        token125: "Buy Tax",
        token126: "Sell Tax",
        token127: "Marketing",
        token128: "Burn",
        token129: "Liquidity",
        token130: "Dividends",
        token131: "Invitor",
        token132: "Transfer of ownership",
        token133: "Transfer address",
        token134: "Chain not supported",
        token135: "Pooling tax rate",
        token136: "Withdrawal tax rate",
        token137: "Marketing Wallet",
        token138: "Kill Block",
        token139: "Airdrop addresses",
        token140: "Wallet holding limit",
        token141: "No upper limit",
        token142: "Supply mining pool",
        token143: "Total daily mining volume",
        token144: "Min LP holdings for mining",
        token145: "Recommendation rewards",
        token146: "Generation ratio",
        token147: "Second generation ratio",
        token148: "Third generation ratio",
        token149: "Other proportions",
        token150: "Rewards on behalf of others",
        token151: "Min transfer quantity",
        token152: "Note: The minimum number of recommended relationships to bind, and after confirmation, the binding is successful; Default to 0",
        token153: "Invitation mini holding amount",
        token154: "Note: Only addresses with a coin holding amount greater than the minimum holding amount can receive invitation rewards",
        token155: "Permission control",
        token156: "Discard permissions",
        token157: "Mint control",
        token158: "Set price per unit",
        token159: "Set quantity per serving",
        token160: "Set max number of copies",
        token161: "Extract tokens from contract",
        token162: "Liquidity control",
        token163: "Set pooling tax rate",
        token164: "Set withdrawal tax rate",
        token165: "Transaction control",
        token166: "Allow add liquidity",
        token167: "Open transaction",
        token168: "Set blacklist",
        token169: "Kill and Run Robot",
        token170: "Add transaction pairs",
        token171: "Tax rate control",
        token172: "Modify tax rate",
        token173: "Set tax rate whitelist",
        token174: "Modify marketing wallet",
        token175: "Dividend control",
        token176: "Extract tokens from contracts",
        token177: "Set dividend blacklist",
        token178: "Set dividend threshold",
        token179: "Recommended reward control",
        token180: "Modify recommended tax rate",
        token181: "Set Invitation reward ratio",
        token182: "Set min transfer amount",
        token183: "Set min holding rewards",
        token184: "Mining control",
        token185: "Set mining blacklist",
        token186: "Set daily mining amount",
        token187: "Set min LP holdings",
        token188: "Combustion control",
        token189: "Modify Burn percen",
        token190: "Note: The maximum combustion percentage is 1%, and the minimum is 0.01% (with a maximum of two decimal places)",
        token191: "Burning cycle (hours)",
        token192: "Note: must be an integer",
        token193: "Modify combustion cycle",
        token194: "Marketing Wallet Address",
        token195: "Tax rate whitelist address",
        token196: "Add tax rate whitelist",
        token197: "Remove tax rate whitelist",
        token198: "Please enter the address, one per line",
        token199: "Price per unit",
        token200: "Quantity",
        token201: "Pooling tax rate: maximum 25%",
        token202: "Tax rate for pool withdrawal: maximum of 100%, and the coins obtained from pool withdrawal will be destroyed",
        token203: "Add blacklist",
        token204: "Remove blacklist",
        token205: "Rush block",
        token206: "Note: Transactions need to be manually initiated. The addresses of transactions within the first X blocks of the transaction will be considered as running bots and automatically added to the blacklist",
        token207: "Transaction to address",
        token208: "Note: If your token is listed on different exchanges or there are different trading pairs, please add the corresponding liquidity pool address. Otherwise, tax rates, dividends, and other mechanisms will not work on other trading pairs!",
        token209: "Purchase tax rate: maximum sum less than 25%",
        token210: "Selling tax rate: The maximum sum is less than 25%",
        token211: "Nothing",
        token212: "Insufficient contract balance",
        token213: "Burning percentage",
        token214: "Mint price",
        token215: "Mint quantity per serving",
        token216: "Mint maximum number of copies",
        token217: "Withdrawal tax rate: maximum of 50%",
        token218: "Dividend blacklist address",
        token219: "When the number of dividend tokens in the dividend wallet exceeds this value, dividends will start. The default value is 0.1 dividend tokens, which means that dividends will only start when the value of the dividend tax reaches this value. Setting it too high may result in no dividends for a long time. It is strongly recommended that new users do not modify this option (Summary: Dividend USDT is good by default, and dividend WBNB can be adjusted down appropriately, such as 0.0001. Do not arbitrarily increase this value, as any problems caused are unrelated to the platform)",
        token220: "The recommended tax rate for buying and selling is the same, and it is necessary to ensure that the total tax rate for buying cannot exceed 25%, and the total tax rate for selling cannot exceed 25%",
        token221: "Reward Algebra",
        token222: "The current total proportion is",
        token223: "Please click on it before confirming",
        token224: "Calculate",
        token225: "Confirm that the total proportion is 100%",
        token226: "Used to set the minimum transfer amount for binding recommendation relationships. If you want any transfer amount to be able to bind recommendation relationships, set it to 0",
        token227: "Example: Set the minimum transfer amount to 0.1. The superior needs to transfer at least 0.1 to the subordinate, and the subordinate needs to transfer at least 0.1 to the superior. Once the transfer is successful, it will be bound",
        token228: "Balance is 0",
        token229: "Mining blacklist address",
        token230: "This address will not receive mining rewards",
        token231: "Mint settings",
        token232: "Minimum transfer amount for each price: MINT",
        token233: "Quantity per share: The number of tokens returned corresponding to the minimum transfer amount at MINT",
        token234: "Total number of copies: Before opening, all copies of MINT are allowed. Any number exceeding this limit will result in failure",
        token235: "Start MINT: After the token is issued, simply transfer the token into the contract",
        token236: "This address will not receive mining rewards",
        token237: "Stop MINT when it exceeds the total number of MINT shares or when starting a transaction",
        token238: "Each Quantity",
        token239: "Total Copies",
        token240: "Unit Price",
        token241: "Share",
        token242: "314 Settings",
        token243: "Liquidity ratio",
        token244: "Cooling time",
        token245: "Second",
        token246: "Liquidity ratio: The proportion of tokens automatically transferred to the contract address during coin issuance, used to provide liquidity",
        token247: "Cooling time: interval time between each buy, interval time between each individual sell",
        token248: "Only marketing wallets have the authority to add/remove liquidity and extend lock pool time",
        token249: "Add liquidity",
        token250: "Unlocking blocks",
        token251: "Wallet Upperlimit",
        token252: "Cooling time",
        token253: "Remove liquidity",
        token254: "Extend lock pool time",
        token255: "Modify holding limit",
        token256: "Close holding limit",
        token257: "Modify cooling time",
        token258: "Current Block",
        token259: "Unlocking Block",
        token260: "Add",
        token261: "Estimated initial price",
        token262: "Due to the lack of a sliding point mechanism in the 314 protocol, the actual transaction price is related to the size and quantity of the pool, and the estimated price is for reference only",
        token263: "The number of new lock pool blocks needs to be greater than the previous set value",
        token264: "Unrestricted",
        token265: "314 Protocol",
        token266: "Sweeping the world with innovative gameplay, no need for swap to redeem, transaction cooling and anti pinch! "
    },
    tokenlock: {
        lock1:'One click creation of Token and LP liquidity lock',
        lock2:'Contract',
        lock3:'name',
        lock4:'symbol',
        lock5:'total',
        lock6:'decimal',
        lock7:'balance',
        lock8:'quantity',
        lock9:'max',
        lock10:'Unlock',
        lock11:'Select date and time',
        lock12:'The current lock contract address is',
        lock13:',If the token has functions such as transfer tax rate or dividend distribution, please exclude this address',
        lock14:'approve',
        lock15:'locking',
        lock16:'owner',
        lock17:'Lock List',
        lock18:'All',
        lock19:'Lock contract',
        lock20:'Lock amount',
        lock21:'Lock time',
        lock22:'Unlock time',
        lock23:'Action',
        lock24:'My',
        lock25:'The quantity sent cannot be empty',
        lock26:'Send quantity greater than balance',
        lock27:'Lock Details',
        lock28:'Lock Record',
        lock29:'Countdown',
        lock30:'Add Amount',
        lock31:'Extended lock',
        lock32:'Unlock',
        lock33:'Add locked tokens',
        lock34:'Current locked quantity',
        lock35:'My balance',
        lock36:'Add Amount',
        lock37:'Extend unlock date',
        lock38:'Current unlocking date',
        lock39:'New unlock date'
    }
}