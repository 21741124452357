<template>
	<div class="detailMode">
		<div class="mainSender">
			<div class="container">

        <div class="senderMain">
          <div class="title">{{$t('tokenPublic.token113')}}({{$t('title.lpMine')}})</div>
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="lgCols" :xl="xlCols">
              <el-descriptions class="bottomMargin"  :column="2" size="medium" border>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token114')}}</template>
                  {{ coinName }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token115')}}</template>
                  {{ coinSymbol }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token116')}} </template>
                  {{ coinTotalSupply }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token117')}}</template>
                  {{ coinDecimals }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token118')}} </template>
                  {{$t('title.lpMine')}}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token119')}}</template>
                  <div class="copy">{{ showAccount(coinOwner) }} <i class="el-icon-copy-document" @click="copy(coinOwner)"></i></div> 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token120')}}</template>
                  <div class="copy">{{ showAccount(coinAddress) }} <i class="el-icon-copy-document" @click="copy(coinAddress)"></i></div> 
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin"  :column="1" size="medium" border>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token122')}}</template>
                  <div class="copy">{{ swapRouterName }} <i class="el-icon-copy-document" @click="copy(swapRouterAddress)"></i></div> 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token123')}}</template>
                  <div class="copy">{{ showAccount(mainPair) }} <i class="el-icon-copy-document" @click="copy(mainPair)"></i></div> 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token124')}}</template>
                  <div class="copy">{{ currencyCoin }} <i class="el-icon-copy-document" @click="copy(currencyAddress)"></i></div> 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token125')}}</template>
                  {{ buyTaxTotal }}% ({{$t('tokenPublic.token127')}}{{ buyFundFee }}% + {{$t('tokenPublic.token128')}}{{ buy_burnFee }}% + {{$t('tokenPublic.token129')}}{{ buyLPFee }}%)
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token126')}} </template>
                  {{ sellTaxTotal }}% ({{$t('tokenPublic.token127')}}{{ sellFundFee }}% + {{$t('tokenPublic.token128')}}{{ sell_burnFee }}% + {{$t('tokenPublic.token129')}}{{ sellLPFee }}%)
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token136')}} </template>
                  {{ removeLiquidityFee }}%
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token137')}} </template>
                  <div class="copy">{{ showAccount(fundAddress) }} <i class="el-icon-copy-document" @click="copy(fundAddress)"></i></div> 
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token138')}} </template>
                  {{ kb }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token139')}} </template>
                  {{ airdropNumbs }}
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin"  :column="1" size="medium" border>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token142')}} </template>
                  {{ mineTotal }} {{ coinSymbol }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token143')}} </template>
                  {{ LPRewardCondition }} {{ coinSymbol }}
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token144')}} </template>
                  {{ minLPHoldAmount }} LP
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token145')}}  </template>
                  {{ generations }}{{$t('tokenPublic.token150')}}<br/>
                  {{$t('tokenPublic.token146')}} {{ fristRate }}%<br/>
                  {{$t('tokenPublic.token147')}} {{ secondRate }}%<br/>
                  {{$t('tokenPublic.token148')}} {{ thirdRate }}%<br/>
                  {{$t('tokenPublic.token149')}} {{ leftRate }}%
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token151')}} </template>
                  <div>{{ minTransAmount }} {{ coinSymbol }}</div>
                  <small>{{$t('tokenPublic.token152')}}</small>
                </el-descriptions-item>
                <el-descriptions-item>
                  <template slot="label">{{$t('tokenPublic.token153')}} </template>
                  <div>{{ minInvitorHoldAmount }} {{ coinSymbol }}</div>
                  <small>{{$t('tokenPublic.token154')}}</small>
                </el-descriptions-item>
              </el-descriptions>

            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7"  v-if="isShowControl">
              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token155')">
                  <el-button size="mini" @click="isTransferOwnership = true"> {{$t('tokenPublic.token132')}}</el-button>
                  <el-button size="mini" @click="renounceOwnership()" :loading="isRenounceOwnership"> {{$t('tokenPublic.token156')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token162')">
                  <el-button size="mini" @click="isSetRemoveLiquidityFee = true"> {{$t('tokenPublic.token164')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token165')">
                  <el-button :disabled="!enableOffTrade || startTradeBlock !=='0'" size="mini" @click="launch()" :loading="isLaunch"> {{$t('tokenPublic.token167')}}  </el-button>
                  <el-button :disabled="!enableRewardList" size="mini" @click="isMultiBclist = true"> {{$t('tokenPublic.token168')}} </el-button>
                  <el-button :disabled="!enableKillBlock" size="mini" @click="isKb = true"> {{$t('tokenPublic.token169')}} </el-button>
                  <el-button size="mini" @click="isAddSwapPair = true"> {{$t('tokenPublic.token170')}}  </el-button>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token171')">
                  <el-button :disabled="!enableChangeTax" size="mini" @click="isCompleteCustoms = true"> {{$t('tokenPublic.token172')}} </el-button>
                  <el-button size="mini" @click="isFeeWhiteList = true"> {{$t('tokenPublic.token173')}} </el-button>
                  <el-button size="mini" @click="isSetFundAddress = true"> {{$t('tokenPublic.token174')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token184')">
                  <el-button size="mini" @click="isSetExcludeLPProvider = true"> {{$t('tokenPublic.token185')}} </el-button>
                  <el-button size="mini" @click="isSetLPRewardCondition = true"> {{$t('tokenPublic.token186')}}</el-button>
                  <el-button size="mini" @click="isSetMinLPHoldAmount = true"> {{$t('tokenPublic.token187')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>

              <el-descriptions class="bottomMargin" direction="vertical" :column="1" border>
                <el-descriptions-item :label="$t('tokenPublic.token179')">
                  <el-button size="mini" @click="isSetMinInvitorHoldAmount = true"> {{$t('tokenPublic.token183')}}</el-button>
                  <el-button size="mini" @click="isChangeInviteRate = true"> {{$t('tokenPublic.token181')}}</el-button>
                  <el-button size="mini" @click="isSetMinTransAmount = true"> {{$t('tokenPublic.token182')}}</el-button>
                </el-descriptions-item>
              </el-descriptions>
            </el-col>
          </el-row>
        </div>

        <el-dialog :title="$t('tokenPublic.token132')" :visible.sync="isTransferOwnership" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token133')}}:</div>
          <el-input v-model="newOwner" placeholder="0x..."></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isTransferOwnership = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="transferOwnership()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token164')" :visible.sync="isSetRemoveLiquidityFee" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token217')}}:</div>
          <el-input v-model="removeLiquidityFee" placeholder="0"><template slot="append">%</template></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetRemoveLiquidityFee = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setRemoveLiquidityFee()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token168')" :visible.sync="isMultiBclist" width="30%">
          <template>
            <el-radio v-model="radioBlacklist" label="1">{{$t('tokenPublic.token203')}}</el-radio>
            <el-radio v-model="radioBlacklist" label="2">{{$t('tokenPublic.token204')}}</el-radio>
          </template>
          <div class="titleBottom">{{$t('tokenPublic.token205')}}</div>
          <el-input type="textarea" rows="5" v-model="multiBclistAdders" :placeholder="$t('tokenPublic.token198')"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isMultiBclist = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setMultiBclist()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>
        
        <el-dialog :title="$t('tokenPublic.token169')" :visible.sync="isKb" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token205')}}:</div>
          <el-input v-model="kb" placeholder="0"></el-input>
          <small>{{$t('tokenPublic.token206')}}</small>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isKb = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setkb()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token170')" :visible.sync="isAddSwapPair" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token207')}}:</div>
          <el-input v-model="swapPairAddress" placeholder="0x..."></el-input>
          <small>{{$t('tokenPublic.token208')}}</small>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isAddSwapPair = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setSwapPairList()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token172')" :visible.sync="isCompleteCustoms" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token209')}}</div>
          <el-input class="xBottom" v-model="buyLPFee" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token19')}}</template><template slot="append">%</template></el-input>
          <el-input class="xBottom" v-model="buyFundFee" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token18')}}</template><template slot="append">%</template></el-input>
          <el-input class="xBottom" v-model="buy_burnFee" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token21')}}</template><template slot="append">%</template></el-input>
          <div class="titleBottom">{{$t('tokenPublic.token210')}}</div>
          <el-input class="xBottom" v-model="sellLPFee" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token19')}}</template><template slot="append">%</template></el-input>
          <el-input class="xBottom" v-model="sellFundFee" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token18')}}</template><template slot="append">%</template></el-input>
          <el-input class="xBottom" v-model="sell_burnFee" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token21')}}</template><template slot="append">%</template></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isCompleteCustoms = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="completeCustoms()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token173')" :visible.sync="isFeeWhiteList" width="30%">
          <template>
            <el-radio v-model="radioBlacklist" label="1">{{$t('tokenPublic.token196')}}</el-radio>
            <el-radio v-model="radioBlacklist" label="2">{{$t('tokenPublic.token197')}}</el-radio>
          </template>
          <div class="titleBottom">{{$t('tokenPublic.token195')}}</div>
          <el-input type="textarea" rows="5" v-model="feeWhiteListAdders" :placeholder="$t('tokenPublic.token198')"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isFeeWhiteList = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setFeeWhiteList()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token174')" :visible.sync="isSetFundAddress" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token194')}}:</div>
          <el-input v-model="newFundAddress" placeholder="0x..."></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetFundAddress = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setFundAddress()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token185')" :visible.sync="isSetExcludeLPProvider" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token229')}}:</div>
          <el-input v-model="newExcludeLPProvider" placeholder="0x..."></el-input>
          <small>{{$t('tokenPublic.token230')}}</small>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetExcludeLPProvider = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setExcludeLPProvider()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token186')" :visible.sync="isSetLPRewardCondition" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token143')}}:</div>
          <el-input v-model="LPRewardCondition" placeholder="0"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetLPRewardCondition = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setLPRewardCondition()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>
        
        <el-dialog :title="$t('tokenPublic.token187')" :visible.sync="isSetMinLPHoldAmount" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token187')}}:</div>
          <el-input v-model="minLPHoldAmount" placeholder="0"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetMinLPHoldAmount = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setMinLPHoldAmount()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token183')" :visible.sync="isSetMinInvitorHoldAmount" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token153')}}:</div>
          <el-input v-model="minInvitorHoldAmount" placeholder="0"><template slot="append">{{ coinSymbol }}</template></el-input>
          <small>{{$t('tokenPublic.token154')}}</small>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetMinInvitorHoldAmount = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setMinInvitorHoldAmount()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token181')" :visible.sync="isChangeInviteRate" width="30%">
          <el-input class="xBottom" v-model="generations" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token221')}}</template><template slot="append">{{$t('tokenPublic.token78')}}</template></el-input>
          <el-input class="xBottom" v-model="fristRate" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token26')}}</template><template slot="append">%</template></el-input>
          <el-input class="xBottom" v-model="secondRate" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token27')}}</template><template slot="append">%</template></el-input>
          <el-input class="xBottom" v-model="thirdRate" placeholder="0"><template slot="prepend">{{$t('tokenPublic.token28')}}</template><template slot="append">%</template></el-input>
          <el-input class="xBottom" v-model="leftRate" placeholder="0" disabled><template slot="prepend">{{$t('tokenPublic.token29')}}</template><template slot="append">%</template></el-input>
          <small>{{$t('tokenPublic.token222')}} {{inviterTotal}}% {{$t('tokenPublic.token223')}} <el-button type="warning" size="mini" @click="getLeftRate()"  plain>{{$t('tokenPublic.token224')}}</el-button> ,{{$t('tokenPublic.token225')}}%</small>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isChangeInviteRate = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="changeInviteRate()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenPublic.token182')" :visible.sync="isSetMinTransAmount" width="30%">
          <div class="titleBottom">{{$t('tokenPublic.token182')}}:</div>
          <el-input v-model="newMinTransAmount" placeholder="0"><template slot="append">{{ coinSymbol }}</template></el-input>
          <small>{{$t('tokenPublic.token226')}}</small>
          <small>{{$t('tokenPublic.token227')}}</small>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isSetMinTransAmount = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setMinTransAmount()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        
			</div>
		</div>
	</div>

</template>

<script>
import { ethers } from 'ethers'
import Navigation from '../../components/Navigation'
import { BigNumber , formatUnitsFive, parseUnits, decToHex} from '../../utils/utils.js'
import tokenABI from '../../abi/token/LPMineABI.json'
import chainlistData from '../../abi/tokenChainList.json'
import Clipboard from "clipboard";
import { number } from 'bitcoinjs-lib/src/script'

export default {
	name: 'stardand',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '',
				},
			],
		}
	},

	data() {
		return {
      //链
      chainNum:JSON.parse(localStorage.getItem('chainNum')) || '0',
      chainlist: JSON.parse(localStorage.getItem('chainlist')) || chainlistData,
      exchanges:'',
      //代币
      mainSymbol:'',
      provider:{},
      signer:{},
      account:'',
      isLoading:false,
      //是否显示左侧控制
      isShowControl:false,

      stardandFactory:'',
      //代币基本信息
      coinAddress:null,
      coinName:'',
      coinSymbol:'',
      coinDecimals:'',
      coinTotalSupply:'',
      coinOwner:'',
      //经济模型
      swapRouterAddress:'',
      swapRouterName:'',
      mainPair:'',
      currencyCoin:'',
      currencyAddress:'',

      buyFundFee:'',
      buyLPFee:'',
      buy_burnFee:'',
      sellFundFee:'',
      sellLPFee:'',
      sell_burnFee:'',
      //挖矿
      mineTotal:'',
      mineRate:'',
      LPRewardCondition:'',
      minLPHoldAmount:'',
      minInvitorHoldAmount:'',

      generations:'',
      fristRate:'',
      secondRate:'',
      thirdRate:'',
      leftRate:'',
      minTransAmount:'',

      fundAddress:'',
      kb:'',
      airdropNumbs:'',
      removeLiquidityFee:'0',

      enableOffTrade:false,
      enableKillBlock:false,
      enableRewardList:false,
      enableChangeTax:false,
      currencyIsEth:false,
      airdropEnable:false,

      startTradeBlock:'0',

      //左侧控制
      isTransferOwnership:false,
      newOwner:'',
      isRenounceOwnership:false,
      //设置撤池税率
      isSetRemoveLiquidityFee:false,
      //黑名单
      radioBlacklist:'1',
      isMultiBclist:false,
      multiBclistAdders:'',
      multiBclistAll:[],
      //杀抢跑区块
      isKb:false,
      //添加交易对地址
      isAddSwapPair:false,
      swapPairAddress:'',
      //修改税率
      isCompleteCustoms:false,
      //设置税率白名单
      isFeeWhiteList:false,
      feeWhiteListAdders:'',
      feeWhiteListAll:[],
      //修改营销钱包
      isSetFundAddress:false,
      newFundAddress:'',
      //设置挖矿黑名单
      isSetExcludeLPProvider:false,
      newExcludeLPProvider:'',
      //设置每日挖矿总量
      isSetLPRewardCondition:false,
      //设置最小LP持有量
      isSetMinLPHoldAmount:false,
      //设置推荐奖励最小持币量
      isSetMinInvitorHoldAmount:false,
      //设置推荐奖励比例
      isChangeInviteRate:false,
      newTotal:'0',
      //设置最小转账金额
      isSetMinTransAmount:false,
      newMinTransAmount:'0',

      isStartLP:false,
      isLaunch:false,

		}
	},

	async created() {
    this.coinAddress = this.$route.params.coinAddress
    this.exchanges = this.chainlist[this.chainNum].exchanges
    this.mainSymbol = this.chainlist[this.chainNum].nativeCurrency.symbol
    this.stardandFactory = this.chainlist[this.chainNum].stardandFactory
    // console.log(decToHex(97))
	},
	computed: {
		title() {
			return this.$t('title.lpMine')
		},
    lgCols() {  
      return this.isShowControl ? 17 : 24;  
    },  
    xlCols() {  
      return this.isShowControl ? 17 : 24;  
    },
    buyTaxTotal() {
      const buyTotal = Number(this.buyFundFee) + Number(this.buyLPFee) + Number(this.buy_burnFee)
			return buyTotal
		},
    sellTaxTotal() {
      const sellTotal = Number(this.sellFundFee) + Number(this.sellLPFee) + Number(this.sell_burnFee)
			return sellTotal
		},
    inviterTotal() {
      const topThreeTotal = Number(this.fristRate) + Number(this.secondRate) + Number(this.thirdRate) + Number(this.leftRate) * (Number(this.generations)-3)
      return topThreeTotal
		},
	},
  async mounted() {
    await this.initAccount()
    if (this.chainNum === `${this.$t("tokenPublic.token134")}`) {
      this.chainLogo = './img/chainIcon/not.png'
      return
    }
    this.allEnableSwitch()
    this.getTokenDetail()
    this.getEconomicDetail()
    this.getInviterDetail()
    this.getMineParameter()
  },
	methods: {
    //税率
    async allEnableSwitch() {
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        this.coinOwner = await tokenContract.owner()
        if (this.coinOwner === this.account) {
          this.isShowControl = true
        }
        this.enableOffTrade = await tokenContract.enableOffTrade()
        this.enableKillBlock = await tokenContract.enableKillBlock()
        this.enableRewardList = await tokenContract.enableRewardList()
        this.enableChangeTax = await tokenContract.enableChangeTax()
        this.currencyIsEth = await tokenContract.currencyIsEth()
        this.airdropEnable = await tokenContract.airdropEnable()

        if (this.enableOffTrade) {
          const startTradeBlock = await tokenContract.startTradeBlock()
          this.startTradeBlock = startTradeBlock.toString()
        } 

        if (!this.enableKillBlock) {
           this.kb = this.$t("tokenPublic.token211")
        } else {
          let kb = await tokenContract.kb()
          this.kb = BigNumber(kb).toString()
        }
        if (!this.airdropEnable) {
          this.airdropNumbs = this.$t("tokenPublic.token211")
        } else {
          let airdropNumbs = await tokenContract.airdropNumbs()
          this.airdropNumbs = BigNumber(airdropNumbs).toString()
        }

      } catch (error) {
        console.log("EnableSwitch:"+error.code);
      }
    },
    //基本信息
    async getTokenDetail() {
      if (!this.coinAddress) {
        console.log("无法获取到代币地址");
        return
      }
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        this.coinName = await tokenContract.name()
        this.coinSymbol = await tokenContract.symbol()
        const decimals = await tokenContract.decimals()
        this.coinDecimals = BigNumber(decimals).toString()
        const totalSupply = await tokenContract.totalSupply()
        this.coinTotalSupply = formatUnitsFive(totalSupply, this.coinDecimals)
        
        //挖矿
        this.mineRate = await tokenContract.mineRate()
        const mineTotalPe = totalSupply.mul(this.mineRate).toString()
        this.mineTotal = formatUnitsFive(mineTotalPe, this.coinDecimals)
        const LPRewardConditionPe = await tokenContract.LPRewardCondition()
        this.LPRewardCondition = formatUnitsFive(LPRewardConditionPe, this.coinDecimals)
        const minLPHoldAmounttPe = await tokenContract.minLPHoldAmount()
        this.minLPHoldAmount = formatUnitsFive(minLPHoldAmounttPe, '18')
        const minInvitorHoldAmountPe = await tokenContract.minInvitorHoldAmount()
        this.minInvitorHoldAmount = formatUnitsFive(minInvitorHoldAmountPe, this.coinDecimals)

        this.swapRouterAddress = await tokenContract._swapRouter()
        await this.getSwapRouterName()

        this.mainPair = await tokenContract._mainPair()
        this.currencyAddress = await tokenContract.currency()
        await this.getCurrencyCoin()

        const  removeLiquidityFee = await tokenContract.removeLiquidityFee()
        this.removeLiquidityFee = removeLiquidityFee.div('100').toString()
        this.fundAddress = await tokenContract.fundAddress()

      } catch (error) {
        console.log("TokenDetail:"+error.code);
      }
    },
    async getCurrencyCoin() {
      const tokenContract = new ethers.Contract(this.currencyAddress, tokenABI, this.signer)
      this.currencyCoin = await tokenContract.symbol()
    },
    async getSwapRouterName() {
      const exchange = this.exchanges.find(exchange => exchange.routerAddr === this.swapRouterAddress)
      if (exchange) {  
        this.swapRouterName = exchange.name
      } else {  
        this.swapRouterName = ''
      }
    },
    //税率
    async getEconomicDetail() {
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)

        let buyFundFeePe = await tokenContract._buyFundFee()
        this.buyFundFee = buyFundFeePe.div('100').toString()
        let buyLPFeePe = await tokenContract._buyLPFee()
        this.buyLPFee = buyLPFeePe.div('100').toString()
        let buy_burnFeePe = await tokenContract.buy_burnFee()
        this.buy_burnFee = buy_burnFeePe.div('100').toString()

        let sellFundFeePe = await tokenContract._sellFundFee()
        this.sellFundFee = sellFundFeePe.div('100').toString()
        let sellLPFeePe = await tokenContract._sellLPFee()
        this.sellLPFee = sellLPFeePe.div('100').toString()
        let sell_burnFeePe = await tokenContract.sell_burnFee()
        this.sell_burnFee = sell_burnFeePe.div('100').toString()

      } catch (error) {
        console.log("EconomicDetail:"+error.code);
      }
    },
    //推荐奖励
    async getInviterDetail() {
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const generations = await tokenContract.generations()
        this.generations = generations.toString()
        const fristRate = await tokenContract.fristRate()
        this.fristRate = fristRate.toString()
        const secondRate = await tokenContract.secondRate()
        this.secondRate = secondRate.toString()
        const thirdRate = await tokenContract.thirdRate()
        this.thirdRate = thirdRate.toString()
        const leftRate = await tokenContract.leftRate()
        this.leftRate = leftRate.toString()

        const minTransAmount = await tokenContract._minTransAmount()
        this.minTransAmount = formatUnitsFive(minTransAmount, this.coinDecimals)
      } catch (error) {
        console.log("InviterDetail:"+error.code);
      }
    },
    //挖矿参数
    async getMineParameter() {
      try {
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)


      } catch (error) {
        console.log("MineParameter:"+error.code);
      }
    },
    //transferOwnership
    async transferOwnership() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.transferOwnership(this.newOwner)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //renounceOwnership
    async renounceOwnership() {
      try {
        this.isRenounceOwnership = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.renounceOwnership()
        await receipt.wait()
        this.$message.success('success')
        this.isRenounceOwnership = false
      } catch (error) {
        this.$message.error(error.code)
        this.isRenounceOwnership = false
        return
      }
    },
    //setRemoveLiquidityFee
    async setRemoveLiquidityFee() {
      const newRemoveLiquidityFee = BigNumber(this.removeLiquidityFee).mul('100').toString()
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setRemoveLiquidityFee(newRemoveLiquidityFee)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //startLP
    async startLP() {
      try {
        this.isStartLP = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.startLP()
        await receipt.wait()
        this.$message.success('success')
        this.isStartLP = false
      } catch (error) {
        this.$message.error(error.code)
        this.isStartLP = false
        return
      }
    },
    //launch
    async launch() {
      try {
        this.isLaunch = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.launch()
        await receipt.wait()
        this.$message.success('success')
        this.isLaunch = false

      } catch (error) {
        this.$message.error(error.code)
        this.isLaunch = false
        return
      }
    },
    //setMultiBclist
    async setMultiBclist() {
      this.multiBclistAll = []
      let newList = this.multiBclistAdders.split('\n')
      let valueBool = false
      for (let i = 0; i < newList.length; i++) {
        let newListStr = newList[i]
        if (newListStr == '' || newListStr.length !== 42 ) {
            return
        }
        if (this.radioBlacklist ==='1') {
          valueBool = true
        } else {
          valueBool = false
        }
        this.multiBclistAll.push(newListStr)
      }
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.multi_bclist(this.multiBclistAll, valueBool)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setkb
    async setkb() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setkb(this.kb)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setkb
    async setSwapPairList() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setSwapPairList(this.swapPairAddress,true)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //completeCustoms
    async completeCustoms() {
      let buyLPFee = BigNumber(this.buyLPFee).mul('100').toString()
      let buyFundFee = BigNumber(this.buyFundFee).mul('100').toString()
      let buy_burnFee = BigNumber(this.buy_burnFee).mul('100').toString()
      let sellLPFee = BigNumber(this.sellLPFee).mul('100').toString()
      let sellFundFee = BigNumber(this.sellFundFee).mul('100').toString()
      let sell_burnFee = BigNumber(this.sell_burnFee).mul('100').toString()

      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.completeCustoms(
          [buyLPFee, buyFundFee, buy_burnFee, sellLPFee, sellFundFee, sell_burnFee]
        )
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setFeeWhiteList
    async setFeeWhiteList() {
      this.feeWhiteListAll = []
      let newList = this.feeWhiteListAdders.split('\n')
      let valueBool = false
      for (let i = 0; i < newList.length; i++) {
        let newListStr = newList[i]
        if (newListStr == '' || newListStr.length !== 42 ) {
            return
        }
        if (this.radioBlacklist ==='1') {
          valueBool = true
        } else {
          valueBool = false
        }
        this.feeWhiteListAll.push(newListStr)
      }
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setFeeWhiteList(this.feeWhiteListAll, valueBool)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setFundAddress
    async setFundAddress() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setFundAddress(this.newFundAddress)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
     //setExcludeLPProvider
     async setExcludeLPProvider() {
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setExcludeLPProvider(this.newExcludeLPProvider,true)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setLPRewardCondition
    async setLPRewardCondition() {
      const newValue = parseUnits(this.LPRewardCondition, this.coinDecimals)
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setLPRewardCondition(newValue)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setMinLPHoldAmount
    async setMinLPHoldAmount() {
      const newValue = parseUnits(this.minLPHoldAmount, '18')
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setMinLPHoldAmount(newValue)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //setMinInvitorHoldAmount
    async setMinInvitorHoldAmount() {
      const newValue = parseUnits(this.minInvitorHoldAmount, this.coinDecimals)
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setMinInvitorHoldAmount(newValue)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //changeInviteRate 
    async changeInviteRate() {
      this.getLeftRate()
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.changeInviteRate(
          [this.generations, this.fristRate, this.secondRate, this.thirdRate, this.leftRate]
        )
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    //计算剩余每代
    getLeftRate() {
      const topThreeTotal = this.fristRate * 100 + this.secondRate * 100 + this.thirdRate * 100
      const surplus = Math.floor((10000-topThreeTotal)/(this.generations-3)/100)
      this.leftRate = surplus
      const newTotal = surplus*(this.generations-3) + topThreeTotal/100
      if (newTotal == 100) {
        this.leftRate = surplus
        this.newTotal = 100
      } else {
        this.fristRate = 100 - newTotal + this.fristRate*100/100
        this.newTotal = surplus*(this.generations-3) + topThreeTotal/100
      }
    },

    //setMinTransAmount
    async setMinTransAmount() {
      const newValue = parseUnits(this.newMinTransAmount, this.coinDecimals)
      console.log(newValue);
      try {
        this.isLoading = true
        const tokenContract = new ethers.Contract(this.coinAddress, tokenABI, this.signer)
        const receipt  = await tokenContract.setMinTransAmount(newValue)
        await receipt.wait()
        this.$message.success('success')
        this.isLoading = false
      } catch (error) {
        this.$message.error(error.code)
        this.isLoading = false
        return
      }
    },
    // ----------初始化账户-------------
		async initAccount() {
			if (window.ethereum) {
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          //获取链ID
          const chainId = await window.ethereum.request({ method: 'eth_chainId' })
          const chainIdB = Number(BigNumber(chainId).toString())
          const selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
          if (!selectedChain) {
            this.chainNum = `${this.$t("tokenPublic.token134")}`
            return;
          }
          this.chainNum = selectedChain.id;
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: chainId }],
          });
				} catch (switchError) {
          if (switchError.code === 4902) {
            this.$message.error(switchError.message)
            try {
              await window.ethereum.request({
                "method": 'wallet_addEthereumChain',
                "params": [
                  {
                    "chainId": decToHex(Number(this.chainlist[this.chainNum].chainId)),
                    "chainName": this.chainlist[this.chainNum].chainName,
                    "rpcUrls": this.chainlist[this.chainNum].rpcUrls,
                    "nativeCurrency": {
                      "name": this.chainlist[this.chainNum].nativeCurrency.name,
                      "symbol": this.chainlist[this.chainNum].nativeCurrency.symbol,
                      "decimals": this.chainlist[this.chainNum].nativeCurrency.decimals
                    },
				            "blockExplorerUrls":this.chainlist[this.chainNum].blockExplorerUrls
                  },
                ],
              });
            } catch (addError) {
              this.$message.error(addError.code)
            }
          }
					// User denied account access
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
			// Verify Accounts!
		},
    showAccount(str) {
      if (str) {
          const account = String(str);
          return account.substring(0, 4) + "..." + account.substring(account.length - 4, account.length);
      }
      return "";
    },
    //复制
    copy(text) {
      const clipboard = new Clipboard('.copy', {
          text: () => {
              return text
          },
      })
      clipboard.on('success', () => {
          this.$message.success(this.$t('pubilc.copySauccessfully'))
          clipboard.destroy()
      })
      clipboard.on('error', () => {
          this.$message.error(this.$t('pubilc.copyFailed'))
          clipboard.destroy()
      })
    },

	},
  watch:{
    //监听 chainNum 本地持久化
    chainNum:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainNum',JSON.stringify(newVal))
      }
    },
    chainlist:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainlist',JSON.stringify(newVal))
      }
    }
  },
}
</script>

<style scoped>
.detailMode {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 1000px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}

.senderMain {
  padding: 5px 30px 30px 30px;
  height: auto;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .title{
  font-size: 22px;
  margin: 20px;
  text-align: center;
}
.senderMain .bottomMargin{
  margin-bottom: 20px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
.titleBottom{
  margin: 10px 0;
}
.xBottom{
  margin: 3px 0;
}
.senderMain .bottomMargin button{
  margin-bottom: 10px;
  margin-left: 0px;
  width: 100%;
}

::v-deep .el-dialog__header{
  border-bottom: 1px solid #eee;
}
::v-deep .el-dialog__body{
  padding: 15px 20px !important;
}
::v-deep .el-form-item__content{
  margin-bottom: 10px;
}

@media (max-width:768px){
  ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
  }
</style>