<template>
	<div class="navigation">
		<div class="container">
			<div class="btn" @click="toHome()">
				<span>
					<img class="topLogo" src="../assets/imgs/topLogo.png" alt="">
				</span>
			</div>
			<div class="nav">
				<el-menu class="el-menu-demo" mode="horizontal">
					<el-menu-item index="1" @click="toHome()">{{$t('home.toolList[16].title')}}</el-menu-item>
					<el-submenu index="2">
						<template slot="title">{{$t('home.toolList[17].title')}}</template>
						<router-link class="navRouter" to="/insertionTool"><el-menu-item index="2-1">{{$t('home.toolList[12].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/batchCollection"><el-menu-item index="2-2">{{$t('home.toolList[13].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/generateWallet/evmWallet"><el-menu-item index="2-3">{{$t('home.toolList[5].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/generateWallet/btcWallet"><el-menu-item index="2-4">{{$t('home.toolList[11].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/bulkQuery"><el-menu-item index="2-5">{{$t('home.toolList[1].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/batchApprove"><el-menu-item index="2-6">{{$t('home.toolList[15].title')}}</el-menu-item></router-link>
					</el-submenu>
					<el-submenu index="3">
						<template slot="title">{{$t('home.toolList[18].title')}}</template>
						<router-link class="navRouter" to="/faucet"><el-menu-item index="3-1">{{$t('home.toolList[10].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/abi"><el-menu-item index="3-2">{{$t('home.toolList[3].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/querySelector"><el-menu-item index="3-3">{{$t('home.toolList[6].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/unitConvert"><el-menu-item index="3-4">{{$t('home.toolList[0].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/traceview"><el-menu-item index="3-5">{{$t('home.toolList[2].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/address"><el-menu-item index="3-6">{{$t('home.toolList[4].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/topicID"><el-menu-item index="3-7">{{$t('home.toolList[7].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/hashTool"><el-menu-item index="3-8">{{$t('home.toolList[8].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/calldata"><el-menu-item index="3-9">{{$t('home.toolList[9].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/generateWallet/beautifulWallet"><el-menu-item index="3-11">{{$t('home.toolList[30].title')}}</el-menu-item></router-link>
					</el-submenu>
					<router-link class="navRouter" to="/autoTrade"><el-menu-item index="autoTrade">{{$t('home.toolList[14].title')}}</el-menu-item></router-link>
					<router-link class="navRouter" to="/batchMultiSender"><el-menu-item index="batchMultiSender">{{$t('home.toolList[19].title')}}</el-menu-item></router-link>

					<el-submenu index="4">
						<template slot="title">{{$t('home.toolList[21].title')}}</template>
						<router-link class="navRouter" to="/createToken/stardand"><el-menu-item index="4-1">{{$t('home.toolList[22].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/createToken/holdReflection"><el-menu-item index="4-2">{{$t('home.toolList[23].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/createToken/lpWithInviter"><el-menu-item index="4-3">{{$t('home.toolList[24].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/createToken/lpBurn"><el-menu-item index="4-4">{{$t('home.toolList[25].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/createToken/lpMine"><el-menu-item index="4-5">{{$t('home.toolList[26].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/createToken/X314Protocol"><el-menu-item index="4-6">{{$t('home.toolList[29].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/tokenAdmin/tokenList"><el-menu-item index="4-7">{{$t('home.toolList[27].title')}}</el-menu-item></router-link>
					</el-submenu>
					<el-submenu index="5">
						<template slot="title">{{$t('home.toolList[31].title')}}</template>
						<router-link class="navRouter" to="/lock/list"><el-menu-item index="5-1">{{$t('home.toolList[32].title')}}</el-menu-item></router-link>
						<router-link class="navRouter" to="/lock/create"><el-menu-item index="5-2">{{$t('home.toolList[33].title')}}</el-menu-item></router-link>
					</el-submenu>
				</el-menu>
			</div>
			<div class="rightcontainer">

				<div v-if="address">
					<el-button size="small" type="warning" plain>{{ address }}</el-button>
				</div>
				<div v-else @click="login">
					<el-button size="small" type="warning" plain>{{$t("navigation.connectWallet")}}</el-button>
				</div>
				<div v-if="lang === 'zh'" class="langBn">
					<el-button size="small" type="info" @click="cutoverLang('en')" plain>CN</el-button>
				</div>
				<div v-else  class="langBn">
					<el-button size="small" type="info" @click="cutoverLang('zh')" plain>EN</el-button>
				</div>
			</div>
			<div class="more pcHide" @click="show = !show">
				<div><img src="../assets/imgs/more.png" alt=""></div>
			</div>
			<div class="pcHide langBn">
				<div v-if="lang === 'zh'">
					<el-button size="small" type="info" @click="cutoverLang('en')" plain>CN</el-button>
				</div>
				<div v-else>
					<el-button size="small" type="info" @click="cutoverLang('zh')" plain>EN</el-button>
				</div>
			</div>
			<div class="pcHide walletBn">
				<div v-if="address">
					<el-button size="small" type="warning" plain>{{ address }}</el-button>
				</div>
				<div v-else @click="login">
					<el-button size="small" type="warning" plain>{{$t("navigation.connectWallet")}}</el-button>
				</div>
			</div>

			
		</div><transition name="el-zoom-in-top">
				<div v-show="show" class="collapse-item">
					<el-collapse v-model="activeName" accordion>
						<el-collapse-item name="2">
							<template slot="title">{{$t('home.toolList[17].title')}}</template>
							<router-link to="/autoTrade" class="el-icon-arrow-right">{{$t('home.toolList[14].title')}}</router-link>
							<router-link to="/batchMultiSender" class="el-icon-arrow-right">{{$t('home.toolList[19].title')}}</router-link>
							<router-link to="/batchCollection" class="el-icon-arrow-right">{{$t('home.toolList[13].title')}}</router-link>
							<router-link to="/insertionTool" class="el-icon-arrow-right">{{$t('home.toolList[12].title')}}</router-link>
							<router-link to="/batchApprove" class="el-icon-arrow-right">{{$t('home.toolList[15].title')}}</router-link>
							<router-link to="/bulkQuery" class="el-icon-arrow-right">{{$t('home.toolList[1].title')}}</router-link>
							<router-link to="/generateWallet" class="el-icon-arrow-right">{{$t('home.toolList[5].title')}}</router-link>
							<router-link to="/generateWallet/btcWallet" class="el-icon-arrow-right">{{$t('home.toolList[11].title')}}</router-link>
						</el-collapse-item>

						<el-collapse-item name="3">
							<template slot="title">{{$t('home.toolList[21].title')}}</template>
							<router-link to="/createToken/stardand" class="el-icon-arrow-right">{{$t('home.toolList[22].title')}}</router-link>
							<router-link to="/createToken/holdReflection" class="el-icon-arrow-right">{{$t('home.toolList[23].title')}}</router-link>
							<router-link to="/createToken/lpWithInviter" class="el-icon-arrow-right">{{$t('home.toolList[24].title')}}</router-link>
							<router-link to="/createToken/lpBurn" class="el-icon-arrow-right">{{$t('home.toolList[25].title')}}</router-link>
							<router-link to="/createToken/lpMine" class="el-icon-arrow-right">{{$t('home.toolList[26].title')}}</router-link>
							<router-link to="/createToken/X314" class="el-icon-arrow-right">{{$t('home.toolList[29].title')}}</router-link>
							<router-link to="/tokenAdmin/tokenList" class="el-icon-arrow-right">{{$t('home.toolList[27].title')}}</router-link>
						</el-collapse-item>

						<el-collapse-item name="4">
							<template slot="title">{{$t('home.toolList[31].title')}}</template>
							<router-link to="/lock/list" class="el-icon-arrow-right">{{$t('home.toolList[32].title')}}</router-link>
							<router-link to="/lock/create" class="el-icon-arrow-right">{{$t('home.toolList[33].title')}}</router-link>
						</el-collapse-item>

                        <el-collapse-item name="5">
							<template slot="title">{{$t('home.toolList[18].title')}}</template>
							<router-link to="/faucet" class="el-icon-arrow-right">{{$t('home.toolList[10].title')}}</router-link>
							<router-link to="/abi" class="el-icon-arrow-right">{{$t('home.toolList[3].title')}}</router-link>
							<router-link to="/querySelector" class="el-icon-arrow-right">{{$t('home.toolList[6].title')}}</router-link>
							<router-link to="/unitConvert" class="el-icon-arrow-right"> {{$t('home.toolList[0].title')}}</router-link>
							<router-link to="/traceview" class="el-icon-arrow-right"> {{$t('home.toolList[2].title')}}</router-link>
							<router-link to="/address" class="el-icon-arrow-right"> {{$t('home.toolList[4].title')}}</router-link>
							<router-link to="/topicID" class="el-icon-arrow-right"> {{$t('home.toolList[7].title')}}</router-link>
							<router-link to="/hashTool" class="el-icon-arrow-right">{{$t('home.toolList[8].title')}}</router-link>
							<router-link to="/calldata" class="el-icon-arrow-right"> {{$t('home.toolList[9].title')}}</router-link>
						</el-collapse-item>

						<el-collapse-item :title="$t('navigation.nav.folloUs')" name="5">
							<a href="https://t.me/wuhaxi" target="_blank" class="el-icon-arrow-right">Telegram</a>
							<a href="https://twitter.com/wuhaxi" target="_blank" class="el-icon-arrow-right">Twitter</a>
						</el-collapse-item>
					</el-collapse>
				</div>
			</transition>
	</div>
</template>

<script>
import { ethers } from 'ethers'
import Proxy from '../proxy.js'

export default {
	name: 'Navigation',
	data() {
		return {
			address: null,
			lang: 'zh',
			//控制列表的展示
			show: false,
			activeName: '2',
		}
	},

	computed: {
		account() {
			return this.$store.state.account
		},
	},

	async created() {
		if (localStorage.getItem('lang') != null) {
			this.$i18n.locale = localStorage.getItem('lang')
		} else {
			let lan = navigator.systemLanguage || navigator.language
			if (lan.toString().toLowerCase().indexOf('zh') !== -1) {
				this.$i18n.locale = 'zh'
			} else if (lan.toString().toLowerCase().indexOf('en') !== -1) {
				this.$i18n.locale = 'en'
			}
		}
		this.lang = this.$i18n.locale
		if (this.account) {
			this.address = this.formatAccount(this.account)
		}
	},

	async mounted() {
		let that = this
		try {
			window.ethereum.on('accountsChanged', function (accounts) {
				that.address = that.formatAccount(accounts[0])
			})
			const provider = new ethers.providers.Web3Provider(window.ethereum)
			const owner = provider.getSigner()
			this.address = this.formatAccount(await owner.getAddress())
		} catch (error) {}
	},

	methods: {
		async login() {
			let loginStatus = await Proxy.initWeb3Account(
				async (web3Provider) => {
					if (web3Provider) {
						web3Provider.on('accountsChanged', (accounts) => {
							if (accounts[0]) {
								this.address = this.formatAccount(accounts[0])
								this.$store.commit('updataAccount', accounts[0])
							} else {
								this.address = null
								this.$store.commit('updataAccount', null)
							}
						})
						let provider = new ethers.providers.Web3Provider(
							web3Provider
						)
						try {
							let account = await provider
								.getSigner()
								.getAddress()
							this.$store.commit('updataAccount', account)
							this.address = this.formatAccount(account)
						} catch (error) {}
					}
				},
				this
			)
		},

		//切换语言
		cutoverLang(lang) {
			this.lang = lang
			this.$i18n.locale = lang
			localStorage.setItem('lang', lang)
		},

		formatAccount(acc) {
			if (typeof acc !== 'string') {
				return acc;  
			}
			let accLeft = acc.substring(0, 4)
			let accRight = acc.substring(acc.length-4,acc.length)
			return `${accLeft}...${accRight}`
		},

		toHome() {
			this.$router.push('/')
		},

	},
}
</script>

<style scoped>
.navigation {
	width: 100%;
	display: flex;
	justify-content: center;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.container {
	display: flex;
	width: 100%;
	height: 70px;
	box-sizing: border-box;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
}

.container .btn {
	cursor: pointer;
}

.container div span {
	font-size: 24px;
	font-weight: 500;
}

.container .connect {
	cursor: pointer;
	height: 35px;
	display: flex;
	align-items: center;
	gap: 12px;
	padding: 0 20px;
	background-color: #eef0f3;
	border-radius: 5px;
}

.container .connect div:first-child {
	width: 16px;
	height: 16px;
	background-color: #a3a3a3;
	border-radius: 50%;
}

.container .connect div:last-child {
	font-size: 14px;
}

.container .connected div:first-child {
	background-color: #3ec93f;
}

.topLogo {
	width: 130px;
	height: 33px;
}

.github {
	width: 25px;
	height: 25px;
	margin-right: 12px;
	filter: invert(0%);
}

.twitter {
	width: 24px;
	height: 24px;
	margin-right: 12px;
	filter: invert(0%);
}

.rightcontainer {
	display: flex;
	align-items: center;
}

.walletBn{
	float: right;
	padding-top: 2px;
}
.langBn{
	float: right;
	padding-top: 2px;
	margin-left: 8px;
}
.langBn button{
	padding: 9px 8px !important
}

::v-deep .rightcontainer .lang div input {
	border: 0px solid !important;
	width: 80px !important;
	background: none !important;
	margin: 0 0 1px 0 !important;
}

::v-deep .rightcontainer .lang div span span i {
	line-height: 0px;
}

.more {
	cursor: pointer;
	float: left;
	width: 40px;
	height: 40px;
}

.more div {
	width: 22px;
	height: 22px;
	margin: 7px auto;
}

.more div img {
	width: 22px;
	height: 22px;
}

.collapse-item {
	display: none;
}

::v-deep .el-collapse-item__header{
	padding: 0px 15px;
	font-weight: 600;
	background: #E7E7E7;
}

::v-deep .el-collapse-item__content {
	background: #eee;
	padding: 5px 20px 5px 20px;
}

.el-collapse-item__content div {
	margin-top: 10px;
	padding: 10px 10px 10px 15px;
}

.el-collapse-item__content div:hover {
	cursor: pointer;
	background-color: rgb(214, 214, 214);
	border-radius: 10px;
}

.el-collapse-item__content a {
	margin-top: 5px;
	font-size: 14px;
	padding: 10px 0px;
	width: 50%;
	text-decoration: none;
	display: inline-block;
	color: #666;
}

.el-collapse-item__content a:hover {
	cursor: pointer;
	background-color: rgb(214, 214, 214);
}
.nav ul{
    display: flex;
    flex-wrap: nowrap;
}

.navRouter{
		text-decoration: none;
		color: #333;
	}
.nav .router-link-active{
	color: #c1081e !important;
	border-bottom: 2px solid #c1081e;
}

@media (max-width: 400px) {
	.container .btn span img {
		width: 90px;
		height: 25px;
	}
}

@media (max-width: 375px) {
	.more {
		margin-left: 5px;
	}

	.pcHide {
		margin-right: 0px;
	}
}

@media (max-width: 768px) {
	.nav {
		display: none;
	}

	.navigation {
		display: inline-block;
	}

	.collapse-item {
		display: block;
		position: absolute;
		width: 100%;
		z-index: 9999;
	}

	.btn {
		margin: 20px 0 0 0;
		display: inline-block;
	}

	.rightcontainer {
		display: none;
	}

	.pcHide {
		width: auto;
		margin-top: 15px;
		margin-right: 5px;
	}

	.container {
		flex-wrap: wrap;
		height: auto;
		display: inline-block;
	}

	.container .connect {
		width: 145px;
	}

	.connect {
		width: 200px;
		float: right;
	}

	.more {
		float: right;
		margin-left: 10px;
	}

}

@media (min-width: 769px) {
	.pcHide {
		display: none;
	}
	.nav ul li {
		display: block;
		font-size: 16px;
		color: black;
		height: 70px;
		line-height: 70px;
	}
	.nav ul li a {
		text-decoration: none;
	}

	.el-menu--horizontal {
		border-bottom: none;
	}

	::v-deep .nav .el-menu--horizontal > .el-submenu .el-submenu__title {
		color: black;
		font-size: 16px;
		height: 70px;
		line-height: 73px;
		display: block;
		border-bottom: 2px solid #c1081e;
	}
	::v-deep .nav .el-menu--horizontal>.el-menu-item.is-active {
		border-bottom: 2px solid #c1081e;
		color: #c1081e;
	}
}
</style>