export default {
    pubilc: {
        usingHelp: "使用帮助",
        copySauccessfully: "复制成功",
        copyFailed: "复制失败",
        noData: "暂无数据",
        cancel: "取 消",
        sure: "确 定",
        copy: "复制",
        querying: "正在查询",
        btnInquire: "查询",
        tool:"工具",
        wallet:"钱包"
    },
    title: {
        home: "区块链开发者的工具箱",
        unitConvert: "Ether单位换算",
        bulkQuery: "批量查询钱包余额",
        traceview: "交易分析工具",
        abi: "ABI 图形化",
        address: "地址转换与ENS查询",
        querySelector: "函数名查询函数选择器",
        topicID: "查询事件主题(TopicID)",
        hashTool: "Hash工具",
        calldata: "交易输入数据(Calldata)编解码",
        faucet: "测试币水龙头",
        insertionTool: "批量铭刻铭文",
        batchCollection: "批量归集",
        batchApprove: "批量授权",
        autoTrade: "科学家抢币",
        batchMultiSender: "批量转账",
        createToken: "创建代币",
        stardand: "标准代币",
        holdReflection: "持币分红本币",
        lpBurn: "Mint+底池燃烧",
        lpMine: "LP挖矿+推荐",
        lpWithInviter: "LP分红+推荐",
        tokenAdmin: "代币管理",
        tokenList: "代币列表",
        X314: "314协议",
        tokenLock: "Token 锁",
        lockList: "锁列表",
        createLock: "创建锁"
    },
    navigation: {
        connectWallet: "连接钱包",
        nav: {
            home: "首页",
            languageSelection: "语言选择",
            folloUs: "关注我们",
        }
    },
    home: {
        title: ["开源", "工具箱", "让区块链开发更轻松"],
        describe: "开源才值得信赖，好用才提高效率，你所需的工具尽在于此。",
        btnFeedback: "问题反馈",
        subheadingToolList: "工具列表",
        listDescribe: "免费效率工具，快上手试试吧",
        EVMWalletGenerator: '生成 EVM 钱包',
        allTool: '所有工具',
        hotTool: '热门工具',
        toolList: [
            {
                title: '单位换算',
                detail: 'ETH 单位换算',
            },
            {
                title: '批量查余额',
                detail: '批量查询钱包余额',
            },
            {
                title: '交易分析',
                detail: '解析 EVM 交易内部详情',
            },
            {
                title: 'ABI 可视化调用',
                detail: '根据 ABI 自动生成可调用函数',
            },
            {
                title: '地址转换与ENS查询',
                detail: '地址查询转换与ENS查询',
            },
            {
                title: '批量生成EVM钱包',
                detail: '批量生成 EVM 钱包',
            },
            {
                title: '签名选择器查询',
                detail: '根据函数签名查询 4 字节函数选择器',
            },
            {
                title: '事件签名查询',
                detail: '根据事件签名查询主题TopicID及反向查询',
            },
            {
                title: 'Hash 工具',
                detail: '常用 Hash 工具，如keccak-256、base64 等',
            },
            {
                title: '数据编解码',
                detail: '编码及解码交易输入数据（Input Calldata）',
            },
            {
                title: '测试币水龙头',
                detail: '测试网水龙头测试币获取',
            },
            {
                title: '批量生成BTC钱包',
                detail: '批量生成BTC钱包',
            },
            {
                title: '批量铭刻铭文',
                detail: '自动批量铭刻铭文，支持所有EVM链',
            },
            {
                title: '批量归集',
                detail: '批量把多个钱包余额归集到一个钱包',
            },
            {
                title: '科学家抢币',
                detail: '多个区块链去中心化交易所抢币，速度快',
            },
            {
                title: '批量授权',
                detail: '提前授权TOKEN给合约，加快抢购速度',
            },
            {
                title: '首页',
                detail: '首页',
            },
            {
                title: '批量工具',
                detail: '批量工具',
            },
            {
                title: '其他工具',
                detail: '其他工具',
            },
            {
                title: '批量转账',
                detail: '简单好用的批量分发转币的好工具',
            },
            {
                title: '钱包追踪',
                detail: '追踪大佬钱包，提前枪跑',
            },
            {
                title: '创建代币',
                detail: '无需代码，一键创建代币',
            },
            {
                title: '标准代币',
                detail: '干净合约、方便上手、无税无功能、Ave检测全绿',
            },
            {
                title: '分红本币',
                detail: '简单干净合约,无黑白名单,无权限,加池自动开盘,持币即可获益!',
            },
            {
                title: 'LP分红+推荐',
                detail: '下级交易、上级奖励、持续裂变、壮大规模!',
            },
            {
                title: 'Mint+底池燃烧',
                detail: 'Mint + 底池定时燃烧、价格被动上涨、凝聚社区共识!',
            },
            {
                title: 'LP挖矿+推荐',
                detail: '加池挖矿、恒定产出、无前端无后端、完全去中心化运行',
            },
            {
                title: '代币管理',
                detail: '代币管理',
            },
            {
                title: '代币列表',
                detail: '代币列表',
            },
            {
                title: '314协议',
                detail: '席卷全球创新玩法、无需swap即可兑换、交易冷却防夹子!',
            },
            {
                title: '靓号钱包生成器',
                detail: '钱包靓号地址生成器',
            },
            {
                title: 'Token 锁',
                detail: '一键锁 Token 和 LP 流动性',
            },
            {
                title: '锁列表',
                detail: '所有TOKEN 和 LP 流动性 锁列表',
            },
            {
                title: '创建锁',
                detail: '一键创建Token 和 LP 流动性锁',
            }
        ],
    },
    uintConvert: {
        title: "以太 ETH 单位转换器",
        describe: "以太币的最小单位是Wei，1个ETH等于10的18次方Wei。在支付Gas费时大家习惯使用Gwei作为展示单位，日常使用的单位是ETH、Gwei 和 Wei；",
        illustrate: ["以太坊 Ethereum 和以太币 Ether 的区别：", "以太坊 Ethereum 是一个允许任何人在其上创建和使用分布式应用的开放的区块链平台。", "以太币 Ether 是以太坊 Ethereum 中通用的货币，它主要用于支付转账及执行智能合约所消耗的汽油(Gas)，在交易中以太币被缩写为ETH。"],
    },
    bulkQuery: {
        enterAddress: "代币地址",
        enterAddressPrompt: "默认 ETH 地址",
        enterWalletAddress: "钱包地址",
        enterWalletAddressPrompt: "一行输入一个地址",
        inquireResult: "查询结果",
        list: ["钱包地址", "代币", "余额"],
        bntCheckBalance: "查询余额",
        btnExportExcel: "下载 Excel",
        exportExcelPrompt: "你当前还没有查询余额，请查询余额后再进行下载表格",
        checkBalancePrompt: ["你还没有输入钱包地址，请输入钱包地址后重试", "你输入代币地址错误，请重新输入后重试"],
    },
    traceView: {
        title: "交易堆栈 Trace 分析工具",
        transactionHash: "交易哈希",
        inputTransactionHash: "输入交易哈希",
        btnAnalyze: "分析",
        options: "高级选项",
        inputAddressMap: "请填入Address 别名",
        inputFunctionMap: "请填入函数别名",
        example: "例",
        AddressMap: "地址映射",
        FunctionMap: "功能映射",
        CallTraces: "CallTraces",
        prompt: ["请输入正确的交易 Hash 值", "请输入交易hash值后重试"],
        analyzing: "全力分析中...",
        parsingFailed: ["解析失败，请稍后再试", "解析失败"]
    },
    abi: {
        title: "ABI 可视化调用",
        btnAddContract: "添加合约",
        btnShare: "分享",
        sharePrompt: "链接分享给好友，将自动加载合约",
        btnABI: "查看ABI",
        ABIPrompt: "点击可查看ABI,还可以复制ABI",
        btnEtherscan: "查看Etherscan",
        EtherscanPrompt: "点击可跳转到对应区块链浏览器",
        btnEdit: "编辑",
        editPrompt: "可修改合约的名称、网络、地址、ABI",
        btnDelect: "删除",
        delectPrompt: "点击可删除合约",
        contractName: "合约名称",
        blockchainAddress: "区块链",
        contractAddress: "合约地址",
        callFunctions: "调用函数：",
        inputValue: "请输入 Value",
        pleaseChoose: "请选择",
        clearOutput: "清空输出",
        run: "运行",
        transactionDetails: "交易详情：",
        returnContent: "返回内容：",
        contract: "合约",
        commonABIs: "常见 ABI",
        blockchainNetwork: "区块链网络",
        selectNetwork: "请选择要连接的网络",
        chooseCommonABI: "从常见 ABI 选择",
        uploadABIFile: "上传 ABI 文件",
        etherscanGet: "从 Etherscan 获取",
        lookOverABI: "查看 ABI",
        jsonABI: "JSON ABI",
        readableABI: "可读友好的 ABI",
        inputPrompt: "请输入内容",
        quit: "关 闭",
        validateName: "请输入合约名称",
        sameName: "已有相同名称合约存在",
        validateAddress: "请输入正确的合约地址",
        checkAbi: "请输入 ABI",
        checkNetwork: "请选择区块链网络",
        autoAddError: '自动添加合约失败',
        promptSharingError: "分享失败，请稍后再试，",
        copyShareSuccess: "复制分享链接成功",
        examineAddressAndNetwork: "请输入合约地址和网络后重试",
        getABIeroor: "ABI获取失败",
        useCurrentNetwork: "使用当前连接网络",
        checkAbiError: "ABI有误!添加失败,请检查后重试",
        notSelectedContractPrompt: "当前暂未选择合约",
        contractNotRecorded: "该合约Etherscan暂未被记录",
        deniedAccess: "用户拒绝帐户访问",
        notInstalledMetaMaskPrompt: "需要安装MetaMask",
        abiError: "ABI有误",
        switchNetworkPrompt: "当前钱包连接的链与本合约连接的链不同。将为你切换到对应网络",
        currentNetworkError: "当前网络错误",
        connectionRequestError: "连接请求错误，请尝试手动连接。连接后刷新页面重试。",
        btnReturn:"返回",
        btnDevelop:"展开操作栏",
        btnFold:"收起操作栏",
        riskWarning:"当前的合约未开源验证，请确认风险，谨慎使用!!!",
    },
    address: {
        address: "地址",
        btnConvert: "确认转换",
        btnInquire: "确认查询",
        inputENS: "输入ENS",
        errorAddressPrompt: "您输入的地址不合法，请重新输入。",
        errorENSPrompt: "没有查询到对应的ENS,也没有查询到对应的地址。"
    },
    generateWallet: {
        tips: ["Tips:钱包生成过程均在本地完成，代码完全", "开源", "。任何人都无法获取到您的助记词及私钥，请放心使用。"],
        randomBatch: "随机批量生成",
        customize: "自定义私钥或助记词创建",
        mnemonicLength: "选择助记词长度",
        quantity: "生成钱包的数量",
        btnGenerateImmediately: "立即生成",
        regenerate: "重新生成",
        exportexcel: "下载表格",
        walletsNumber: "输入生成钱包数量",
        subtitle: "通过私钥或助记词创建钱包",
        createNow: "立即创建",
        enterPrompt: "输入私钥或助记词",
        address: "地址",
        privateKey: "私钥",
        mnemonic: '助记词',
        nullErrorPrompt: "你没有选择助记词长度或没有填入生成钱包的地址数量",
        IllegalErrorPrompt: "你写入的生成钱包的地址数量非数字或小于1, 无法生成,请重新输入",
        beyondErrorPrompt: "你写入的钱包数量大于100, 无法生成。请输入不大于100的生成数量。",
        inputErrorPrompt: "创建失败，请检查你的输入",
        popoverBtnClick: "查看",
        options:["同一助记词推导","不同助记词","自定义助记词创建"],
        advancedOptions:{
            title:"高级选项",
            tips:["有关高级选项，请参阅","BIP44 规范"],
            options:["协议","币种","账户","外部内部","系数","推导路径"]
        },
        messages:['最大生成钱包数量为100','当前生成钱包数量较多，请耐心等待','通过私钥生成钱包，无法改变生成数量及使用高级选项'],
        subtitleMnemonicCreate:"通过助记词创建钱包",
        publicKey:"公钥",
    },
    querySelector: {
        title: "函数名 <-> 函数选择器",
        inputFunctionName: '通过函数名查询函数选择器',
        inputFunctionNamePrompt: "输入函数签名",
        inquire: "查询",
        inputByteFunctionSelector: "输入函数选择器查询函数签名",
        functionInputError: "你输入的函数有误，请重新输入!",
        noRecord: "你所查找的选择器暂未被记录",
        pointOutQuerying:"正在查询"
    },
    topicID: {
        title: "事件 <-> 主题(TopicID)",
        EventSignature: '根据事件名查询主题(TopicID)',
        inputEventSignature: "输入事件签名",
        TopicID: "根据TopicID查询事件名",
        InputTopicID: "输入主题ID",
        signatureInputError: "输入错误，输入示例：event Transfer(address indexed from, address indexed to, uint256 amount)",
        topicIDInputError: "暂未查询到对应的事件签名。输入示例：0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
    },
    hashTool: {
        title: "Hash 工具",
        btnConfirm: "确认",
        coding: "编码",
        decoding: "解码",
        errorHexadecimal: "你的输入不是16进制的字符，请重新输入",
        decodingError: "解码失败，请检查你的输入后重试",
        noInput: "你的输入为空，请重新输入"
    },
    calldata: {
        decoding: "解码",
        coding: "编码",
        inputFunction: "输入函数",
        inputFunctionPrompt: "请输入函数",
        parameter: "参数",
        inputParameterPrompt: "一行输入一个参数，数组类型参数输入格式:[0x2222,0x4444]",
        inputError: "你的输入有误，请检查后重试",
        inputCalldata: "输入Calldata",
        selectFunction: "选择函数",
        autoChoose: "自动选择",
        manualInput: "手动输入",
        decodingResult: "解码结果",
        parameter: "参数",
        parameterType: "参数类型",
        value: "值",
        queryFailed: "查询失败，请检查你的输入后重试",
    },
    faucet: {
        prompt: ["水龙头（Faucet）是一个平台，为你提供测试币（或 Token ），以便在测试智能合约时使用。", "ChainTool 水龙头为大家收集了常用测试网的水龙头网站，方便开发者们享用。", "如果你有我们没有列出的水龙头网站，欢迎提交", "PR", "或", "ISSUE", "帮我们改进，你的支持是我们无限的动力。"],
        currency: "货币",
        url: "网址",
        remark: "备注",
        operate: "操作",
        receive: "前往领取",
        copyUrl: "复制网址",
        copyURLSuccessfully: "复制网址成功",
    },
    notfind: {
        title: "未到找指定页面",
        return: "返回页面"
    },
    faucetData: {
        ethereum: "以太坊",
        testnet: "测试网",
        remarkGoerli: ["要求在主网至少有 0.001ETH 才能领取", "需要通过挖矿获取", "可领取ETH、wETH、DAI、NFTs，要求登录Twitter", "在 2022 年11之前部署过合约的，可以领 10 个"],
        remarkSepolia: ["每天 1 Sepolia ETH", "需要通过挖矿获取", "在 2022 年11之前部署过合约的，可以领 10 个"],
        remarkMumbai: ["每天0.5 Mumbai MATIC"],
        remarkHarmony: ["包含Shard 0、Shard 1、Shard 2、Shard 3"],
        remarkCelo: ["需要使用Github进行身份验证"]
    },
    connectWallet: {
        walleAccessDenied: "连接失败, MetaMask连接被拒绝。",
        notInstalledMetaMask: "连接失败, 请先安装MetaMask."
    },
    batchTools:{
        pubilc:{
            num1:"导入私钥",
            num2:"导入钱包",
            num3:"添加代币",
            num4:"自定义链",
            num5:"删除本地缓存",
            num6:"地址",
            num7:"操作",
            num8:"选中",
            num9:"余额",
            num10:"删除选中",
            num11:"删除所有",
            num12:"选择链",
            num13:"RPC设置",
            num14:"选择代币",
            num15:"代币合约",
            num16:"选择代币",
            num17:"查询余额",
            num18:"接收地址",
            num19:"自己转自己",
            num20:"选择模式",
            num21:"文本模式",
            num22:"16进制模式",
            num23:"获取最新Gas",
            num24:"开始执行批量铭刻铭文",
            num25:"原生代币数量",
            num26:"结果( Tx )",
            num27:"请先导入私钥哦~",
            num28:"代币合约",
            num29:"选中错误",
            num30:"发送数量",
            num31:"全部数量",
            num32:"开始执行批量归集",
            num33:"代币",
            num34:"代币合约地址",
            num35:"授权对象合约地址",
            num36:"授权数量",
            num37:"开始执行批量授权",
            num38:"授权对象合约地址",
            num39:"请先导入钱包哦~",
            num40:"导入私钥,一行一个",
            num41:"兑换",
            num42:"代币管理",
            num43:"设置",//科学家抢币设置
            num44:"高速",
            num45:"标准",
            num46:"缓慢",
            num47:"自动 Gas Limit",
            num48:"滑点设置",
            num49:"自定义交易数量",
            num50:"自定义统一接受地址",
            num51:"自定义当前链RPC",
            num52:"批量兑换",
            num53:"交易路由",
            num54:"最小获得",
            num55:"区块链名称",//自定义链
            num56:"原生代币标识",
            num57:"查询失败",
            num58:"链ID",
            num59:"RPC节点",
            num60:"浏览器URL",
            num61:"添加交易所",//添加交易所
            num62:"交易所",
            num63:"交易所名称",
            num64:"合约地址",
            num65:"合约地址错误",
            num66:"添加失败，请检查链是否正确",
            num67:"未执行",
            num68:"成功了",
            num69:"失败了",
            num70:"等待中",
            num71:"我错了",
            num72:"发送交易失败",
            num73:"批量闪兑",
            num74:"监测授权",
            num75:"未检测",
            num76:"已授权",
            num77:"请输入代币合约地址",
            num78:"找不到Token",
            num79:"路由合约地址",
            num80:"授权失败",
            num81:"获取数量失败",
            num82:"交易成功",
            num83:"请输入金额",
            num84:"查看交易哈希",
            num85:"请输入数量",
            num86:"主币不用授权",
            num87:"接收地址错误",
            num88:"发送内容不能为空",
        },
        transfer:{
            name1:"准备",
            name2:"确认",
            name3:"批量发送",
            name4:"切换主链",
            name5:"代币合约地址",
            name6:"收款地址（每行一个）",
            name7:"上传文件",
            name8:"将文件拖到此处，或点击上传",
            name9:"支持文件类型： CSV / Excel / Txt",
            name10:"发送数量",
            name11:"随机数量",
            name12:"相同数量",
            name13:"批量设置",
            name14:"查看演示",
            name15:"下一步",
            name16:"地址总数",
            name17:"代币发送总数",
            name18:"交易总数",
            name19:"代币余额",
            name20:"手续费",
            name21:"钱包地址",
            name22:"数量",
            name23:"操作",
            name24:"返回",
            name25:"授权",
            name26:"发送",
            name27:"不限制（今后发送",
            name28:"时无需授权）",
            name29:"需要发送的确切数量",
            name30:"上链中！请稍等片刻",
            name31:"成功了！查看结果",
            name32:"加载中...",
            name33:"手动输入",
            name34:"请输入发送数量",
            name35:"最小随机数",
            name36:"最大随机数",
            name37:"请先输入钱包哦~",
            name38:"收款地址为空",
            name39:"你当前的余额不够，请修改数量后继续",
            name40:"发送数量不能为空",
            name41:"发送随机最小数量或最大数量不能为空",
            name42:"请输入收款地址（每行一个）",
            name43:"没有搜索到",
        }
    },
    tokenPublic: {
        token0: "教程",
        token1: "标准代币",
        token2: "干净合约、方便上手、无税无功能、Ave检测全绿",
        token3: "分红本币",
        token4: "简单干净合约,无黑白名单,无权限,加池自动开盘,持币即可获益!",
        token5: "LP分红+推荐奖励",
        token6: "下级交易、上级奖励、持续裂变、壮大规模!",
        token7: "Mint + 底池燃烧",
        token8: "Mint + 底池定时燃烧、价格被动上涨、凝聚社区共识!",
        token9: "LP挖矿+推荐奖励",
        token10: "加池挖矿、恒定产出、无前端无后端、完全去中心化运行",
        token11: "选择链",
        token12: "代币全称",
        token13: "代币简称",
        token14: "代币总量",
        token15: "代币精度",
        token16: "分红代币",
        token17: "买入税率",
        token18: "营销税率",
        token19: "回流税率",
        token20: "分红税率",
        token21: "销毁税率",
        token22: "卖出税率",
        token23: "推荐奖励",
        token24: "推荐税率",
        token25: "分红代数",
        token26: "一代比例",
        token27: "二代比例",
        token28: "三代比例",
        token29: "剩余每代",
        token30: "绑定推荐关系需要上级向下级转账一定数额的代币,当下级回转后,视为绑定成功。例:默认最小转账金额为0,上级可转账任意给下级,下级回转任意数量,最小转账金额可在控制台设置",
        token31: "营销钱包",
        token32: "当前钱包",
        token33: "选择交易所",
        token34: "选择底池",
        token35: "其他代币",
        token36: "添加流动性时需要添加代币与底池代币之间的交易对, 否则无法进行正常分红与回流",
        token37: "手动开启交易",
        token38: "添加池子后的首次交易需要在控制台手动开启(如关闭, 则添加流动性后立即可以进行交易), 交易开启后无法关闭",
        token39: "杀区块",
        token40: "将对开启交易后在n个区块内交易的地址全部拉入黑名单, 用于防止机器人抢跑买入, 必须手动开启交易",
        token41: "区块数量",
        token42: "税率开关",
        token43: "可在创建代币后手动调整税率, 买卖税率各不能超过25%, 此开关关闭后无法再次开启",
        token44: "自动空投",
        token45: "开启开关后, 用户交易时, 将会自动向随机地址空投小额代币, 以增加持币地址,单次最多空投5个",
        token46: "空投数量",
        token47: "黑名单功能",
        token48: "可拉黑部分钱包地址令其无法交易, 此开关关闭后无法再次开启",
        token49: "最大持币量",
        token50: "可设置单个钱包持有的最大代币数量, 此开关关闭后无法再次开启",
        token51: "立即创建",
        token52: "费用",
        token53: "创建合约",
        token54: "合约地址",
        token55: "等待创建成功后显示",
        token56: "开源参数",
        token57: "浏览器查看",
        token58: "开源教程",
        token59: "复制源代码",
        token60: "复制构造参数",
        token61: "构造参数无法找回,若不立即开源,请复制后保存到本地文档",
        token62: "买入总税率不能超过25%",
        token63: "卖出总税率不能超过25%",
        token64: "创建失败",
        token65: "税率说明",
        token66: "回流税率: 交易中指定额度的代币将会自动添加到流动池内, 保证交易始终存在流动性",
        token67: "营销税率: 交易中指定额度的代币将会自动转入营销钱包中, 用于项目方做其他营销",
        token68: "销毁税率: 交易中指定额度的代币将会被打入黑洞地址, 变相实现通缩机制",
        token69: "分红税率: 交易中指定额度的代币会按持币比例分配给所有持币者,实现持币分红",
        token70: "总税率不能超过25%, 交易总税率不能超过50%",
        token71: "要分红的代币 (此代币必须存在公链原生币池子,如BNB,ETH等)",
        token72: "推荐奖励说明",
        token73: "推荐奖励: 获得挖矿奖励地址的推荐人可获得相同数量的推荐奖励",
        token74: "最小持币量:最小代币持有量,为减少gas费用,仅持币量大于最小持有量的地址才可以领取邀请奖励,0即为所有推荐人均可获得奖励",
        token75: "一代: 奖励地址的直属上级,其他代数以此类推",
        token76: "其他代数奖励: 填入前三代奖励百分比后,其他代数奖励比例相同",
        token77: "计算方式:其他每代奖励,保留整数位,多余部分给到第一代,保证总额为100%",
        token78: "代",
        token79: "核算",
        token80: "LP挖矿奖励",
        token81: "总量占比",
        token82: "每日奖励",
        token83: "推荐奖励",
        token84: "最小持币",
        token85: "挖矿奖励说明",
        token86: "挖矿总量占比: 挖矿总奖励占供应量的比值,从总供应量中分出",
        token87: "用于挖矿和推荐奖励",
        token88: "每日挖矿奖励: 默认每个地址每24小时可获取一次挖矿奖励,每个地址获得的数量为每日挖矿奖励*LP占比",
        token89: "最小LP持有量: 默认为0,即所有LP均可参与挖矿,为减少gas费用,建议在首次添加流动性后在控制台修改",
        token90: "燃烧设置",
        token91: "燃烧周期",
        token92: "小时",
        token93: "百分比",
        token94: "燃烧周期: 每隔多久燃烧一次底池,以小时为单位,有卖盘时燃烧",
        token95: "燃烧百分比: 每次底池燃烧时销毁的代币占池子代币总量的比例,最小单位为万分之一,即0.01%",
        token96: "为防止池子燃烧速度过快导致无法交易,燃烧百分比最大为1%,最小燃烧周期为1小时,即池子代币每天最多销毁24%",
        token97: "举例:燃烧百分比为1%,燃烧周期1小时,池子内有100枚代币,在买卖数额相同的情况下,接下来每天的余额约为76,57,43",
        token98: "实际涨幅根据交易量实时变化,无法准确预估",
        token99: "确认关闭？",
        token100: "代币列表",
        token101: "如遇到代币未能成功开源或其他使用问题, 请进入交流群反馈: https://t.me/wuhaxi",
        token102: "交流群",
        token103: "所有代币列表",
        token104: "合约地址",
        token105: "名称",
        token106: "简称",
        token107: "模板",
        token108: "创建者",
        token109: "时间",
        token110: "查看",
        token111: "我的代币列表",
        token112: "操作",
        token113: "代币详情",
        token114: "全称",
        token115: "简称",
        token116: "总量",
        token117: "精度",
        token118: "代币模版",
        token119: "所有者",
        token120: "合约地址",
        token121: "复制链接",
        token122: "交易所",
        token123: "池子地址",
        token124: "底池代币",
        token125: "买入税率",
        token126: "卖出税率",
        token127: "营销",
        token128: "销毁",
        token129: "回流",
        token130: "分红",
        token131: "推荐",
        token132: "转让所有权",
        token133: "转让地址",
        token134: "不支持此链",
        token135: "加池税率",
        token136: "撤池税率",
        token137: "营销钱包",
        token138: "杀抢跑机器人区块",
        token139: "交易空投地址数",
        token140: "单钱包持币上限",
        token141: "无上限",
        token142: "矿池供给量",
        token143: "每日挖矿总量",
        token144: "挖矿最小LP持有量",
        token145: "推荐奖励",
        token146: "一代比例",
        token147: "二代比例",
        token148: "三代比例",
        token149: "其他各代比例",
        token150: "代奖励",
        token151: "最小转账数量",
        token152: "注:绑定推荐关系的最小数量,回转确认后绑定成功;默认为0",
        token153: "推荐奖励最小持币量",
        token154: "注:仅持币量大于最小持有量的地址才可以获得邀请奖励",
        token155: "权限控制",
        token156: "丢弃权限",
        token157: "Mint控制",
        token158: "设置每份价格",
        token159: "设置每份数量",
        token160: "设置最大份数",
        token161: "提取合约内代币",
        token162: "流动性控制",
        token163: "设置加池税率",
        token164: "设置撤池税率",
        token165: "交易控制",
        token166: "允许用户添加流动性",
        token167: "开启交易",
        token168: "设置黑名单",
        token169: "杀开盘抢跑机器人",
        token170: "添加交易对",
        token171: "税率控制",
        token172: "修改税率",
        token173: "设置税率白名单",
        token174: "修改营销钱包",
        token175: "分红控制",
        token176: "提取合约内分红代币",
        token177: "设置分红黑名单",
        token178: "设置分红阈值",
        token179: "推荐奖励控制",
        token180: "修改推荐税率",
        token181: "设置推荐奖励比例",
        token182: "设置最小转账金额",
        token183: "设置推荐奖励最小持币量",
        token184: "挖矿控制",
        token185: "设置挖矿黑名单",
        token186: "设置每日挖矿总量",
        token187: "设置最小LP持有量",
        token188: "燃烧控制",
        token189: "修改燃烧百分比",
        token190: "注:燃烧百分比最大为1%,最小为0.01% (最多只能两位小数)",
        token191: "燃烧周期(小时)",
        token192: "注:必须是整数",
        token193: "修改燃烧周期",
        token194: "营销钱包地址",
        token195: "税率白名单地址",
        token196: "添加税率白名单",
        token197: "移除税率白名单",
        token198: "请输入地址，每行一个",
        token199: "每份价格",
        token200: "数量",
        token201: "加池税率:最大为25%",
        token202: "撤池税率:最大为100%,撤池所得币将销毁",
        token203: "添加黑名单",
        token204: "移除黑名单",
        token205: "抢跑区块",
        token206: "注:需手动开启交易,开启交易的前X个区块内交易的地址被视为抢跑机器人,自动加入黑名单",
        token207: "交易对地址",
        token208: "注:如果您的代币在不同的交易所上架, 或存在不同的交易对, 请将对应的流动池地址添加进来, 否则税率、分红等机制将在其他交易对上不起作用!",
        token209: "买入税率: 最大之和小于25%",
        token210: "卖出税率: 最大之和小于25%",
        token211: "无",
        token212: "合约余额不足",
        token213: "燃烧百分比",
        token214: "Mint价格",
        token215: "Mint每份数量",
        token216: "Mint最大份数",
        token217: "撤池税率:最大为50%",
        token218: "分红黑名单地址",
        token219: "当分红钱包内的分红代币数量超过该值时, 开始分红, 默认为0.1个分红代币, 即分红税的价值达到该值是才会开始分红, 设置的过高可能会导致长时间无分红, 强烈建议新用户不要修改此选项 (总结: 分红USDT默认就好, 分红WBNB可适当调低, 例如0.0001, 不要随意调高该值, 引发的任何问题与平台无关)",
        token220: "推荐税率买卖相同,需保证买入总税率不能超过25%, 卖出总税率不能超过25%",
        token221: "奖励代数",
        token222: "当前总比例为",
        token223: "确认前请先点击",
        token224: "核算",
        token225: "确认总比例为100%",
        token226: "用于设定绑定推荐关系的最小转账金额,如希望任意转账金额均可绑定推荐关系,设置0即可",
        token227: "例:设定最小转账金额为0.1,上级需转账至少0.1给下级,下级至少回转0.1给上级,转账成功即绑定",
        token228: "余额为0",
        token229: "挖矿黑名单地址",
        token230: "该地址将不会收到挖矿奖励",
        token231: "Mint设置",
        token232: "每份价格:MINT时,最小的转账金额",
        token233: "每份数量:MINT时最小转账金额对应的返回的代币数量",
        token234: "总份数: 在开盘前,允许MINT的全部份数,超过此数量的MINT将会失败",
        token235: "开始MINT:代币发行后,往合约里转入代币即可",
        token236: "该地址将不会收到挖矿奖励",
        token237: "当超过MINT总份数,或者开启交易,停止MINT",
        token238: "每份数量",
        token239: "总份数",
        token240: "每份价格",
        token241: "份",
        token242: "314设置",
        token243: "流动性占比",
        token244: "冷却时间",
        token245: "秒",
        token246: "流动性占比:发币时,自动转入合约地址的代币比例,用以提供流动性",
        token247: "冷却时间:每次买入之间的间隔时间,单个每次卖出的间隔时间",
        token248: "仅营销钱包有权限添加/撤出流动性,延长锁池时间",
        token249: "添加流动性",
        token250: "解锁区块",
        token251: "单钱包持币上限",
        token252: "冷却时间",
        token253: "移除流动性",
        token254: "延长锁池时间",
        token255: "修改持币上限",
        token256: "关闭持币限制",
        token257: "修改冷却时间",
        token258: "当前区块",
        token259: "解锁区块",
        token260: "添加",
        token261: "预估初始价格",
        token262: "由于314协议无滑点机制,实际成交价格与池子大小,数量均有关系,预估价格仅供参考",
        token263: "新的锁池区块数需大于之前的设定值",
        token264: "无限制",
        token265: "314协议",
        token266: "席卷全球创新玩法、无需swap即可兑换、交易冷却防夹子! "
    },
    tokenlock: {
        lock1:'一键创建 Token 和 LP 流动性锁',
        lock2:'合约地址',
        lock3:'名称',
        lock4:'符号',
        lock5:'总量',
        lock6:'精度',
        lock7:'余额',
        lock8:'数量',
        lock9:'最大',
        lock10:'解锁日期',
        lock11:'选择日期时间',
        lock12:'当前锁合约地址为',
        lock13:',如果代币存在转账税率或分红等功能,请排除该地址',
        lock14:'授权',
        lock15:'锁定',
        lock16:'所有者',
        lock17:'Lock 列表',
        lock18:'全部',
        lock19:'锁定合约',
        lock20:'锁定数量',
        lock21:'锁定时间',
        lock22:'解锁时间',
        lock23:'操作',
        lock24:'我的',
        lock25:'发送数量不能为空',
        lock26:'发送数量大于余额',
        lock27:'Lock 详情',
        lock28:'锁定记录',
        lock29:'倒计时',
        lock30:'添加',
        lock31:'续锁',
        lock32:'解锁',
        lock33:'添加锁定代币',
        lock34:'当前锁定数量',
        lock35:'可用添加余额',
        lock36:'添加数量',
        lock37:'延长解锁日期',
        lock38:'当前解锁日期',
        lock39:'新的解锁日期'
    }
}