import { render, staticRenderFns } from "./X314Detail.vue?vue&type=template&id=86e9f7b2&scoped=true&"
import script from "./X314Detail.vue?vue&type=script&lang=js&"
export * from "./X314Detail.vue?vue&type=script&lang=js&"
import style0 from "./X314Detail.vue?vue&type=style&index=0&id=86e9f7b2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "86e9f7b2",
  null
  
)

export default component.exports