<template>
	<div class="stardand">
		<div class="mainSender">
			<div class="container">

        <div class="senderMain">
          <div class="title">{{$t('tokenlock.lock17')}} <el-link href="https://t.me/wuhaxi" type="danger" target="_blank">{{$t('tokenPublic.token0')}}</el-link></div>
          <div class="introduction">{{$t('tokenlock.lock1')}}</div>
          <el-form label-width ="auto">
            <el-form-item :label="$t('tokenPublic.token11')" required>
              <el-select v-model="chainNumTow" class="chainNumBox" @change="setChainId">
                <el-option v-for="(item, index) in chainlist" :label="item.chainName"  :key="'chainNumTow' + index" :value="item.id">
                  <span class="itemChainLogo" style="float: left; color: #8492a6; font-size: 13px; margin-right: 5px;">
                    <img :src="item.chainLogo" style="width: 21px;margin-top: 6px;border-radius: 50%;">
                  </span>
                  <span class="itemChainName" style="float: left;">{{ item.chainName }}</span>
                </el-option>
              </el-select>
              <img class="chainLogo" :src="chainLogo">
            </el-form-item>
          </el-form>

          <div>
            <template>
            <el-tabs v-model="activeTokenList" >
              <el-tab-pane :label="$t('tokenlock.lock18')" name="first">
                <template>
                  <el-table 
                    v-loading="isLoading"
                    :data="sortAllTokensList"
                    border
                    class="tableMain">
                    <el-table-column label="ID" type="index" width="50"> </el-table-column>
                    <el-table-column prop="token" :label="$t('tokenlock.lock19')" width="160">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.token) }} <i class="el-icon-copy-document" @click="copy(scope.row.token)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="amount" :label="$t('tokenlock.lock20')" width="200">
                      <template slot-scope="scope"> 
                        <div>{{ scope.row.amount }} {{ scope.row.Symbol }}</div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="owner" :label="$t('tokenlock.lock16')" width="160">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.owner) }} <i class="el-icon-copy-document" @click="copy(scope.row.owner)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="unlockDate" :label="$t('tokenlock.lock22')" width="200"></el-table-column>
                    <el-table-column :label="$t('tokenlock.lock23')">
                      <template slot-scope="scope">  
                        <el-button size="mini" type="info" round @click="handleView(scope.row)">{{$t('tokenPublic.token110')}}</el-button>  
                      </template> 
                    </el-table-column>
                    <div slot="empty"> <p> {{$t('pubilc.noData')}} </p> </div>
                  </el-table>
                </template>
              </el-tab-pane>
              <el-tab-pane :label="$t('tokenlock.lock24')" name="second">
                <template>
                  <el-table 
                    v-loading="isLoading"
                    :data="sortMyTokensList"
                    border
                    class="tableMain">
                    <el-table-column label="ID" type="index" width="50"> </el-table-column>
                    <el-table-column prop="token" :label="$t('tokenlock.lock19')" width="160">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.token) }} <i class="el-icon-copy-document" @click="copy(scope.row.token)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="amount" :label="$t('tokenlock.lock20')" width="200">
                      <template slot-scope="scope"> 
                        <div>{{ scope.row.amount }} {{ scope.row.Symbol }}</div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="owner" :label="$t('tokenlock.lock16')" width="160">
                      <template slot-scope="scope">  
                        <div class="copy">{{ showAccount(scope.row.owner) }} <i class="el-icon-copy-document" @click="copy(scope.row.owner)"></i></div>
                      </template> 
                    </el-table-column>
                    <el-table-column prop="unlockDate" :label="$t('tokenlock.lock22')" width="200"></el-table-column>
                    <el-table-column :label="$t('tokenlock.lock23')">
                      <template slot-scope="scope">  
                        <el-button size="mini" type="info" round @click="handleView(scope.row)">{{$t('tokenPublic.token110')}}</el-button>  
                      </template> 
                    </el-table-column>
                    <div slot="empty"> <p> {{$t('pubilc.noData')}} </p> </div>
                  </el-table>
                </template>
              </el-tab-pane>
            </el-tabs>
          </template>
          </div>
        </div>


			</div>
		</div>
	</div>

</template>

<script>
import { ethers } from 'ethers'
import Navigation from '../../components/Navigation'
import { BigNumber , formatUnitsFive, MAX_INT, parseUnits, decToHex, showAccount} from '../../utils/utils.js'
import lockABI from '../../abi/tokenLock/lockABI.json'
import ercABI from '../../abi/ERC20.json'
import chainlistData from '../../abi/tokenLock/lockChainList.json'
import Clipboard from "clipboard"
import { number } from 'bitcoinjs-lib/src/script'

export default {
	name: 'stardand',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '',
				},
			],
		}
	},

	data() {
		return {
      //链
      chainLogo:'',
      chainNumTow:JSON.parse(localStorage.getItem('chainNumTow')) || '0',
      chainlist: JSON.parse(localStorage.getItem('chainlist')) || chainlistData,
      //代币
      mainSymbol:'',
      tokenBalance:'',

      provider:{},
      signer:{},
      account:'',
      isLoading:false,
      
      lockFactory:'',

      allTokensList:[],
      myTokensList:[],

      activeTokenList: 'first',

      isHandlingChainChange: false,  
		}
	},

	async created() {
    if (window.ethereum) {
      window.ethereum.on('chainChanged', (chainId) => this.handleChainChanged(chainId))
      window.ethereum.on('accountsChanged', (accounts) => this.accountsChanged(accounts))
    }
    // console.log(decToHex(97))
	},
  beforeDestroy() {
    if (window.ethereum) {
      window.ethereum.off('chainChanged', this.handleChainChanged)
      window.ethereum.off('accountsChanged', this.accountsChanged)
    }
  },
	computed: {
		title() {
			return this.$t('title.lockList')
		},
    sortAllTokensList(){
      return this.allTokensList.slice().sort((a, b) => {
        const dateA = new Date(a.id);
        const dateB = new Date(b.id);
        return dateB - dateA;
      });
    },
    sortMyTokensList(){
      return this.myTokensList.slice().sort((a, b) => {
        const dateA = new Date(a.id);
        const dateB = new Date(b.id);
        return dateB - dateA;
      });
    },
	},
  async mounted() {
    await this.initAccount()
    if (this.chainNumTow === `${this.$t("tokenPublic.token134")}`) {
      this.chainLogo = './img/chainIcon/not.png'
      return
    }
    await this.initData()
  },
	methods: {
    formatUnitsFive,
    showAccount,
    async handleChainChanged(chainId) {
      // 当链变化时，更新组件的chainNumTow数据
      const chainIdB = Number(BigNumber(chainId).toString())
      const selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
      if (!selectedChain) {
        this.chainNumTow = `${this.$t("tokenPublic.token134")}`
        this.chainLogo = './img/chainIcon/not.png'
        return
      }
      this.chainNumTow = selectedChain.id;
      await this.initAccount()
      await this.initData()
    },
    async accountsChanged(accounts) {  
      await this.initAccount()
      await this.initData()
    },
    //初始化数据
    async initData() {
      this.chainLogo = this.chainlist[this.chainNumTow].chainLogo
      this.mainSymbol =this.chainlist[this.chainNumTow].nativeCurrency.symbol
      this.lockFactory = this.chainlist[this.chainNumTow].lockFactory
      this.allTokensList = []
      this.myTokensList = []
      await this.getAllLocks()
      await this.getMyLockList()
    },
    //获取所有 锁 列表
    async getAllLocks() {
      try {
        this.isLoading = true
        const lockContract = new ethers.Contract(this.lockFactory, lockABI, this.provider)
        let allLocks  = await lockContract.allLocks()
        for (let i = 0; i < allLocks.length; i++) {

          const tokenContract = new ethers.Contract(allLocks[i].token, ercABI, this.provider)
          const decimals  = await tokenContract.decimals()
          const tokenDecimals  = decimals.toString()
          const Symbol = await tokenContract.symbol()

          let id = allLocks[i].id
          let token = allLocks[i].token
          let owner = allLocks[i].owner
          let amount = formatUnitsFive(allLocks[i].amount, tokenDecimals)
          let lockDateStamp = Number(BigNumber(allLocks[i].lockDate).mul('1000'))
          let lockDate = this.formattedTime(lockDateStamp)
          let unlockDateStamp = Number(BigNumber(allLocks[i].unlockDate).mul('1000'))
          let unlockDate = this.formattedTime(unlockDateStamp)

          let allLocksInfo = {
            'id': id,
            'token': token,
            'owner': owner,
            'amount': amount,
            'lockDate': lockDate,
            'unlockDate': unlockDate,
            'Symbol': Symbol
          }
          
          if (amount !== 0) {
            this.allTokensList.push(allLocksInfo)
          }
        }
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.$message.error(error)
      }
    },

    //我的 锁 列表
    getMyLockList() {
      this.myTokensList = this.allTokensList.filter((item) => item.owner === this.account)
    },
    //带参数跳转
    handleView(row) {  
      const { token, owner, id} = row
      console.log(token);
      this.$router.push({ name: 'LockDetail', params: { token, owner, id}  })
    },
    //切换链
    async setChainId(){
      this.switchChain()
    },
    // ----------切换链-------------
		async switchChain() {
			if (window.ethereum) {
        console.log('----------- switchChain -------------')
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: decToHex(Number(this.chainlist[this.chainNumTow].chainId)) }],
          })
				} catch (switchError) {
          if (switchError.code === 4902) {
            this.$message.error(switchError.message)
            try {
              await window.ethereum.request({
                "method": 'wallet_addEthereumChain',
                "params": [
                  {
                    "chainId": decToHex(Number(this.chainlist[this.chainNumTow].chainId)),
                    "chainName": this.chainlist[this.chainNumTow].chainName,
                    "rpcUrls": this.chainlist[this.chainNumTow].rpcUrls,
                    "nativeCurrency": {
                      "name": this.chainlist[this.chainNumTow].nativeCurrency.name,
                      "symbol": this.chainlist[this.chainNumTow].nativeCurrency.symbol,
                      "decimals": this.chainlist[this.chainNumTow].nativeCurrency.decimals
                    },
				            "blockExplorerUrls":this.chainlist[this.chainNumTow].blockExplorerUrls
                  },
                ],
              });
            } catch (addError) {
              this.$message.error(addError.code)
            }
          }
					// User denied account access
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
      
			// Verify Accounts!
		},
    // ----------初始化账户-------------
		async initAccount() {
			if (window.ethereum) {
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          //获取链ID
          let chainId = await window.ethereum.request({ method: 'eth_chainId' })
          let chainIdB = Number(BigNumber(chainId).toString())
          let selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
          if (!selectedChain) {
            this.chainNumTow = `${this.$t("tokenPublic.token134")}`
            return;
          }
          this.chainNumTow = selectedChain.id;
          console.log('chainNumTow: ' + this.chainNumTow)
				} catch (switchError) {
          this.$message.error(switchError.code)
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
      
			// Verify Accounts!
		},
    //复制
    copy(text) {
      const clipboard = new Clipboard('.copy', {
          text: () => {
              return text
          },
      })
      clipboard.on('success', () => {
          this.$message.success(this.$t('pubilc.copySauccessfully'))
          clipboard.destroy()
      })
      clipboard.on('error', () => {
          this.$message.error(this.$t('pubilc.copyFailed'))
          clipboard.destroy()
      })
    },
    //时间戳转换
    formattedTime(timestamp) {
      // 将时间戳转换为日期时间
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      // 构建日期时间字符串
      const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return time;
    },

	},
  watch:{
    //监听 chainNumTow 本地持久化
    chainNumTow:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainNumTow',JSON.stringify(newVal))
      }
    },
    chainlist:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainlist',JSON.stringify(newVal))
      }
    }
  },
}
</script>

<style scoped>
.stardand {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 1000px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}

.senderMain {
  padding: 5px 30px 30px 30px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .title{
  font-size: 22px;
  margin: 20px;
  text-align: center;
}
.senderMain .introduction{
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
}
.senderMain .chainNumBox{
  width: 100%;
}
.senderMain .chainNumBox .itemChainName{
  float: left;
}
.senderMain .chainNumBox .itemChainLogo{
  float:right;
  color: #8492a6;
  font-size: 13px;
}
.senderMain .chainNumBox .itemChainLogo{
  width: 20px;
  height: 20px;
}
::v-deep .chainNumBox .el-input__inner{
  padding-left: 30px;
}
.senderMain .chainLogo{
  position: absolute;  
  width: 18px;  
  height: 18px;
  top: 11px;
  left: 2px;
  border-radius: 50%;  
  margin-left: 5px;
  z-index: 999;
}
.senderMain .fee{
  margin-left: 10px;
}
.senderMain .duanInput{
  margin: 5px 0;
  width: 200px;
}
.tishi{
  border: 1px solid #e96d6d;
  background: #ffebeb;
  padding: 0 15px;
}
.tishi span{
  color: #e96d6d;
}
.tishi i{
  color: #319920;
}
@media (max-width:768px){
  ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
  }
</style>