<template>
	<div class="faucet">
		<Navigation></Navigation>
		<div class="main">
			<h3 class="title">{{$t("title.faucet")}}</h3>
			<div class="tips">
				<span>{{$t("faucet.prompt[0]")}}<br>
					{{$t("faucet.prompt[1]")}}<br>
				</span>
			</div>
			<div class="faucetList">
				<template>
					<el-table :data="faucetData" border style="width: 100%">
						<el-table-column prop="network" label="链名称">
							<template slot-scope="scope">  
								<img :src="require('../assets/imgs/'+scope.row.img) " alt="">
								{{ scope.row.network }}
							</template> 
						</el-table-column>
						<el-table-column prop="chainID" label="链ID">
						</el-table-column>
						<el-table-column prop="currency" label="链代币">
						</el-table-column>
						<el-table-column prop="currency" label="查看">
							<template slot-scope="scope">  
								<el-button size="mini" type="info" @click="showAllFaucet(scope.$index)"  round>查看</el-button>  
							</template> 
						</el-table-column>
					</el-table>
				</template>
			</div>

			<el-dialog :title="$t('title.faucet')" :visible.sync="isShowFaucet" width="50%">
				<el-table :data="expandedIndices" border class="copyBn">
					<el-table-column type="index" label="序号" width="50">
    				</el-table-column>
					<el-table-column prop="url" label="网址">
					</el-table-column>
					<el-table-column prop="remark" label="备注">
					</el-table-column>
					<el-table-column prop="remark" label="操作"  width="110">
						<template slot-scope="scope">
							<el-button size="mini" @click="openUrl(scope.$index, scope.row)">{{ $t('faucet.receive')}}</el-button>
							<br/>
							<el-button size="mini" @click="copyUrl(scope.$index, scope.row)" class="copy">{{ $t('faucet.copyUrl')}}</el-button>
						</template>
					</el-table-column>
				</el-table>
				
			</el-dialog>
		</div>
	</div>
</template>

<script>
import Navigation from '../components/Navigation'
import { faucetData } from '../faucetData.js'
import Clipboard from 'clipboard'

export default {
	name: 'faucet',
	components: {
		Navigation,
	},

	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '测试网水龙头测试币获取',
				},
			],
		}
	},

	watch: {
		'$i18n.locale'() {
			this.init()
		},
	},

	data() {
		return {
			width: '1300px',
			// 水龙头数据
			faucetData: faucetData(this),
			
			// 打开列表的ChaintID
			ChainID: false,
			//记录上次点击的序号
			index: 0,
			expandedIndices: [], // 用于跟踪已展开的 faucetData 的索引  

			isShowFaucet: false,
		}
	},

	computed: {
		title() {
			return this.$t('title.faucet')
		},
	},

	methods: {
		showAllFaucet(index){
			this.expandedIndices = []
			this.isShowFaucet = true
			this.expandedIndices = this.faucetData[index].faucet
		},

		//初始化数据
		init() {
			this.faucetData = faucetData(this)
		},

		//打开网址
		openUrl(index, row) {
			window.open(row.url, '_blank')
		},

		//复制网址
		copyUrl(index, row) {
			this.copy(row.url, this.$t('faucet.copyURLSuccessfully'), '.copy')
		},

		//展开列表
		async expand(id) {
			this.isExpand = !this.isExpand ? true : false
			//修改图标
			let box = document.getElementById(value.chainID)
			box.style.transition = '0.3s'
			box.style.transform = 'rotate(180deg)'
		},

		//调用复制的方法
		async copy(text, output, className) {
			const clipboard = new Clipboard(className, {
				text: () => {
					return text
				},
			})
			clipboard.on('success', () => {
				this.$message.success(output)
				clipboard.destroy()
			})
			clipboard.on('error', () => {
				this.$message.error(this.$t('pubilc.copySauccessfully'))
				clipboard.destroy()
			})
		},
	},
}
</script>

<style scoped>
.faucet {
	width: 100%;
	height: auto;
	min-height: 92%;
	margin-bottom: 20px;
}

.main {
	max-width: 1000px;
	height: auto;
	background-color: #ffffff;
	border-radius: 15px;
	margin: 30px auto;
	padding: 40px;
}

.main .title {
	text-align: center;
}


.tips {
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
	color: #a5a5a5;
	font-size: 15px;
}

.tips span a {
	color: #409eff;
	text-decoration: none;
}

.tips span a:hover {
	color: #0080ff;
}

.faucetList img{
	width: 18px;
    height: 18px;
	border-radius: 50%;
    margin-left: 5px;
}
.copyBn button{
	margin-bottom: 10px;
}
@media (max-width:768px){
  	::v-deep .el-dialog{
		width: 96% !important;
	}
	::v-deep .el-dialog__wrapper{
		margin: 0 2px;
	}
}
</style>