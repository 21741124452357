<template>
	<div class="createLock">
		<Navigation></Navigation>
		<router-view></router-view>
	</div>
</template>

<script>
import Navigation from '../components/Navigation'

export default {
	name: 'createLock',
	components: {
		Navigation,
	},
	methods: {
	},
}
</script>

<style scoped>
.createLock {
	width: 100%;
	height: auto;
	min-height: 94%;
}
</style>