<template>
	<div class="footer">
		<div class="footerTwo">
			<a href="https://t.me/wuhaxi" target="_blank"> Telegram </a>
			<a href="https://twitter.com/wuhaxi" target="_blank"> Twitter </a>
			<a href="https://www.youtube.com/@wuhaxi" target="_blank"> Youtube </a>
		</div>
		<div>© 2023 tool.wuhaxi.com </div>
	</div>
</template>

<script>
export default {
	name: 'Footer',
}
</script>

<style scoped>
.footer {
	width: 100%;
	height: 80px;
	text-align: center;
	font-size: 13px;
	margin: 10px 0;
}
.footerTwo{
	margin-bottom: 10px;
}
.footerTwo a{
	padding: 0 10px;
}

a {
	text-decoration-line: none;
	color: rgb(161, 161, 161);
}

a:hover {
	cursor: pointer;
	text-decoration: underline;
}
</style>
