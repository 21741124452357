<template>
	<div class="stardand">
		<div class="mainSender">
			<div class="container">

        <div class="senderMain">
          <div class="title">{{$t('tokenlock.lock27')}} <el-link href="https://t.me/wuhaxi" type="danger" target="_blank">{{$t('tokenPublic.token0')}}</el-link></div>
          <div>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-descriptions class="bottomMargin" :column="2" size="medium" border>
                  <el-descriptions-item>
                    <template slot="label">Address</template>
                    <div class="copy">{{ showAccount(tokenAddress) }} <i class="el-icon-copy-document" @click="copy(tokenAddress,'.copy')"></i></div> 
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">Name</template>
                    {{ coinName }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">Symbol</template>
                    {{ coinSymbol }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">Total Supply</template>
                    {{ formatUnitsFive(coinTotalSupply,coinDecimals) }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="isPair">
                    <template slot="label">Dex</template>
                    {{ dex }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="isPair">
                    <template slot="label">Pair</template>
                    {{ token0Symbol }} / {{ token1Symbol }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">Owner</template>
                    <div class="copy">{{ showAccount(ownerWallet) }} <i class="el-icon-copy-document" @click="copy(ownerWallet,'.copy')"></i></div> 
                  </el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </div>
          <div class="title">{{$t('tokenlock.lock28')}}</div>
          <div>
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-descriptions class="bottomMargin" :column="2"  size="medium" border>
                  <el-descriptions-item>
                    <template slot="label">{{$t('tokenlock.lock20')}}</template>
                    {{ formatUnitsFive(lockAmount, coinDecimals) }} {{ coinSymbol }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">{{$t('tokenlock.lock29')}}</template>
                    <el-statistic format="DD : HH : mm : ss" :value="lockDeadline" time-indices></el-statistic>
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">{{$t('tokenlock.lock21')}}</template>
                    {{ formattedTime(lockDate) }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">{{$t('tokenlock.lock22')}}</template>
                    {{ formattedTime(unlockDate) }}
                  </el-descriptions-item>
                  <el-descriptions-item v-if="isOperation">
                    <template slot="label">{{$t('tokenlock.lock23')}}</template>
                    <el-button size="mini" type="success" @click="isAddLock = true">{{$t('tokenlock.lock30')}}</el-button>
                    <el-button size="mini" type="primary" @click="isEditLock = true">{{$t('tokenlock.lock31')}}</el-button>
                    <el-button size="mini" :disabled="isUnlockDate" type="danger" @click="setUnLock()" :loading="isLoading">{{$t('tokenlock.lock32')}}</el-button>
                  </el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </div>
        </div>

        <el-dialog :title="$t('tokenlock.lock33')" :visible.sync="isAddLock" width="30%">
          <div class="titleBottom">{{$t('tokenlock.lock34')}}: {{ formatUnitsFive(lockAmount, coinDecimals) }} {{ coinSymbol }}</div>
          <div class="titleBottom">{{$t('tokenlock.lock35')}}: {{ formatUnitsFive(balance, coinDecimals) }} {{ coinSymbol }} </div>
          <el-input v-model="newAmount" placeholder="0"><template slot="prepend">{{$t('tokenlock.lock36')}}</template><template slot="append"><span @click="setMaxAmount()">{{$t('tokenlock.lock9')}}</span></template></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isAddLock = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setAddLock()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>

        <el-dialog :title="$t('tokenlock.lock37')" :visible.sync="isEditLock" width="30%">
          <div class="titleBottom">{{$t('tokenlock.lock38')}}: {{ formattedTime(unlockDate) }}</div>
          <div class="titleBottomBe">{{$t('tokenlock.lock39')}}: </div>
          <template>
            <el-date-picker
              v-model="unlockTime"
              type="datetime"
              :placeholder="$t('tokenlock.lock11')"
              value-format="timestamp"
              :picker-options="newUnlockOptions"
              @change="getTimeStamp">
            </el-date-picker>
          </template>
          <span slot="footer" class="dialog-footer">
            <el-button @click="isEditLock = false">{{$t('pubilc.cancel')}}</el-button>
            <el-button type="primary" @click="setEditLock()" :loading="isLoading">{{$t('pubilc.sure')}}</el-button>
          </span>
        </el-dialog>


			</div>
		</div>
	</div>

</template>

<script>
import { ethers } from 'ethers'
import Navigation from '../../components/Navigation'
import { BigNumber , formatUnitsFive, MAX_INT, parseUnits, decToHex, showAccount} from '../../utils/utils.js'
import lockABI from '../../abi/tokenLock/lockABI.json'
import ercABI from '../../abi/ERC20.json'
import lpPairABI from '../../abi/lpPairABI.json'
import chainlistData from '../../abi/tokenLock/lockChainList.json'
import Clipboard from "clipboard";
import { number } from 'bitcoinjs-lib/src/script'

export default {
	name: 'stardand',
	components: {
		Navigation,
	},
	metaInfo() {
		return {
			title: "WuHaXi- " + this.title,

			meta: [
				{
					name: 'keyword',
					content: '',
				},
			],
		}
	},

	data() {
		return {
      //链
      chainLogo:'',
      chainNumTow:JSON.parse(localStorage.getItem('chainNumTow')) || '0',
      chainlist: JSON.parse(localStorage.getItem('chainlist')) || chainlistData,
      //代币
      mainSymbol:'',
      tokenBalance:'',

      provider:{},
      signer:{},
      account:'',
      isLoading:false,
      exchanges:'',
      
      lockFactory:'',
      tokenAddress:'',
      ownerWallet:'',
      lockId:'',
      lockAmount:'0',
      lockDate:0,
      unlockDate:0,
      lockDeadline: 0,

      coinName:'',
      coinSymbol:'',
      coinDecimals:'18',
      coinTotalSupply:'0',
      dex:'',
      
      isPair:false,
      token0Symbol:'0',
      token1Symbol:'0',

      balance:'0',
      newAmount:'',
      unlockTime:'',
      isAddLock:false,
      isEditLock:false,
      isUnLock:false,

      isUnlockDate:false,
      isOperation:false,

      newUnlockOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      }
		}
	},

	async created() {
    if (window.ethereum) {  
      window.ethereum.on('chainChanged', (chainId) => this.handleChainChanged(chainId))
      window.ethereum.on('accountsChanged', (accounts) => this.accountsChanged(accounts))
    }
    // console.log(decToHex(97))
	},
  beforeDestroy() {  
    if (window.ethereum) {  
      window.ethereum.off('chainChanged', this.handleChainChanged);  
      window.ethereum.off('accountsChanged', this.accountsChanged)
    }  
  },
	computed: {
		title() {
			return this.$t('tokenlock.lock27')
		},
	},
  async mounted() {
    await this.initAccount()
    if (this.chainNumTow === `${this.$t("tokenPublic.token134")}`) {
      this.chainLogo = './img/chainIcon/not.png'
      return
    }
    await this.initData()
  },
	methods: {
    formatUnitsFive,
    showAccount,
    async handleChainChanged(chainId) {  
      // 当链变化时，更新组件的chainNumTow数据
      const chainIdB = Number(BigNumber(chainId).toString())
      const selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
      if (!selectedChain) {
        this.chainNumTow = `${this.$t("tokenPublic.token134")}`
        this.chainLogo = './img/chainIcon/not.png'
        return;
      }
      this.chainNumTow = selectedChain.id;
      await this.initAccount()
      await this.initData()
    },
    async accountsChanged() {  
      await this.initAccount()
      await this.initData()
    },
    //初始化数据
    async initData() {
      this.tokenAddress = this.$route.params.token
      this.ownerWallet = this.$route.params.owner
      this.lockId = this.$route.params.id
      this.mainSymbol =this.chainlist[this.chainNumTow].nativeCurrency.symbol
      this.lockFactory = this.chainlist[this.chainNumTow].lockFactory
      this.exchanges = this.chainlist[this.chainNumTow].exchanges
      await this.getTokenDetail()
      await this.getLockInfo()
    },
    //获取代币信息
    async getTokenDetail() {
      if (!this.tokenAddress) {
        console.log("无法获取到代币地址");
        return
      }
      try {
        const tokenContract = new ethers.Contract(this.tokenAddress, ercABI, this.signer)
        this.coinName = await tokenContract.name()
        this.coinSymbol = await tokenContract.symbol()
        const decimals = await tokenContract.decimals()
        this.coinDecimals = decimals.toString()
        const totalSupply = await tokenContract.totalSupply()
        this.coinTotalSupply = totalSupply.toString()
        const balance = await tokenContract.balanceOf(this.account)
        this.balance = balance.toString()
        try {//判断是否是LP
          const pairContract = new ethers.Contract(this.tokenAddress, lpPairABI, this.signer)
          const token0 = await pairContract.token0()
          const token1 = await pairContract.token1()
          const token0Contract = new ethers.Contract(token0, ercABI, this.signer)
          const token1Contract = new ethers.Contract(token1, ercABI, this.signer)
          this.token0Symbol = await token0Contract.symbol()
          this.token1Symbol = await token1Contract.symbol()
          this.isPair = true
          console.log('是lp', this.isPair);
          if (this.isPair) {
            try {//获取工厂地址 从而获取交易所名称
              const lockContract = new ethers.Contract(this.lockFactory, lockABI, this.signer)
              const { factory } = await lockContract.cumulativeLockInfo(this.tokenAddress)
              const selectedFactory = this.exchanges.find(item => item.factoryAddr === factory)
              this.dex = selectedFactory.name
            } catch (error) {
              this.dex = 'Unknown'
            }
          }
        } catch (error) {
          this.isPair = false
          console.log('不是lp');
        }
      } catch (error) {
        console.log("TokenDetail"+error.code);
      }
    },
    async getLockInfo(){
      try {
        const lockContract = new ethers.Contract(this.lockFactory, lockABI, this.signer)
        const { owner, amount, lockDate, unlockDate } = await lockContract.getLock(this.lockId)
        if (owner === this.account) {
          this.isOperation = true
        }
        this.lockAmount = amount.toString()
        this.lockDate = Number(lockDate.mul('1000'))
        this.unlockDate = Number(unlockDate.mul('1000'))
        let lockDeadline = this.unlockDate - Date.now()
        if (lockDeadline > 0) {
          this.isUnlockDate = true
          this.lockDeadline = Date.now() + lockDeadline
        }else{
          this.isUnlockDate = false
          this.lockDeadline = 0
        }
      } catch (error) {
        console.log("LockInfo"+error.code);
      }
    },
    //添加锁定数量
    async setAddLock(){
      if (this.newAmount ==='' || this.newAmount === '0') {
        return
      }
      const newVal = parseUnits(this.newAmount, this.coinDecimals)
      const amount = BigNumber(newVal).add(BigNumber(this.lockAmount))
      const newAmount = amount.toString()
      let newUnlockDate = (this.unlockDate / 1000).toString()
      this.isLoading = true
      try {
        const lockContract = new ethers.Contract(this.lockFactory, lockABI, this.signer)
        const txParams = { value:BigNumber('1000')}
        const receipt  = await lockContract.editLock( this.lockId, newAmount, newUnlockDate, txParams)
        await receipt.wait()
        console.log(receipt.hash)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.$message.error(error.code)
      }
    },
    //修改锁定时间
    async setEditLock(){
      let newUnlockDate = (this.unlockTime / 1000).toString()
      this.isLoading = true
      try {
        const lockContract = new ethers.Contract(this.lockFactory, lockABI, this.signer)
        const txParams = { value:BigNumber('1000')}
        const receipt  = await lockContract.editLock( this.lockId, this.lockAmount, newUnlockDate, txParams)
        await receipt.wait()
        console.log(receipt.hash)
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.$message.error(error.code)
      }
    },
    //解锁
    async setUnLock(){
      this.isUnLock = true
      try {
        const lockContract = new ethers.Contract(this.lockFactory, lockABI, this.signer)
        const receipt  = await lockContract.unlock( this.lockId)
        await receipt.wait()
        console.log(receipt.hash)
        this.isUnLock = false
      } catch (error) {
        this.isUnLock = false
        this.$message.error(error.code)
      }
    },
    //设置最大数量 
    async setMaxAmount() {
      this.newAmount = formatUnitsFive(this.balance,this.coinDecimals)
    },
    //获取 TimeStamp 时间戳
    async getTimeStamp() {
      console.log(this.unlockTime)
    },
    // ----------初始化账户-------------
		async initAccount() {
			if (window.ethereum) {
				try {
          let accounts = await ethereum.request({ method: 'eth_requestAccounts' });
          this.account = ethers.utils.getAddress(accounts[0])
          this.provider = new ethers.providers.Web3Provider(window.ethereum)
          this.signer = await this.provider.getSigner(this.account)
          //获取链ID
          let chainId = await window.ethereum.request({ method: 'eth_chainId' })
          let chainIdB = Number(BigNumber(chainId).toString())
          let selectedChain = this.chainlist.find(item => item.chainId === chainIdB)
          if (!selectedChain) {
            this.chainNumTow = `${this.$t("tokenPublic.token134")}`
            return;
          }
          this.chainNumTow = selectedChain.id;
          console.log('chainNumTow: ' + this.chainNumTow)
				} catch (switchError) {
          this.$message.error(switchError.code)
				}
			} else {
				this.$message.error('Need install MetaMask')
			}
			// Verify Accounts!
		},
    //复制
    copy(text) {
      const clipboard = new Clipboard('.copy', {
          text: () => {
              return text
          },
      })
      clipboard.on('success', () => {
          this.$message.success(this.$t('pubilc.copySauccessfully'))
          clipboard.destroy()
      })
      clipboard.on('error', () => {
          this.$message.error(this.$t('pubilc.copyFailed'))
          clipboard.destroy()
      })
    },
    //时间戳转换
    formattedTime(timestamp) {
      // 将时间戳转换为日期时间
      const date = new Date(Number(timestamp));
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      // 构建日期时间字符串
      const time = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return time;
    },

	},
  watch:{
    //监听 chainNumTow 本地持久化
    chainNumTow:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainNumTow',JSON.stringify(newVal))
      }
    },
    chainlist:{
      deep:true,
      handler(newVal){
        localStorage.setItem('chainlist',JSON.stringify(newVal))
      }
    }
  },
}
</script>

<style scoped>
.stardand {
	width: 100%;
	height: auto;
	min-height: 94%;
}

.mainSender {
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	overflow: auto;
}
.container {
	max-width: 1000px;
	height: min-content;
	width: 100%;
  margin: 30px 0;
}

.senderMain {
  padding: 5px 30px 30px 30px;
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #ddd;
  margin: 0 10px;
  border-radius: 20px;
}
.senderMain .title{
  font-size: 22px;
  margin: 20px;
  text-align: center;
}
.senderMain .introduction{
  font-size: 14px;
  text-align: center;
  margin-bottom: 30px;
}

.senderMain .duanInput{
  margin: 5px 0;
  width: 200px;
}
.tishi{
  border: 1px solid #e96d6d;
  background: #ffebeb;
  padding: 0 15px;
}
.tishi span{
  color: #e96d6d;
}
.tishi i{
  color: #319920;
}
.titleBottom{
  margin-bottom: 25px;
}
.titleBottomBe{
  margin-bottom: 15px;
}
::v-deep .el-statistic .con{
  justify-content: left;
}
::v-deep .el-statistic .con .number{
  font-size: 14px;
  padding: 0px;
}
@media (max-width:768px){
  ::v-deep .el-dialog{
      width: 96% !important;
    }
    ::v-deep .el-dialog__wrapper{
      margin: 0 2px;
    }
  }
</style>